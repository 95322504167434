/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
import toast from 'components/Alerts';
import { createReferral } from 'actions/bounty';
import Button from 'components/Button';
import { useState } from 'react';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Input from 'components/Input';
import { ReferralsList } from './ReferralsList';

export default function ReferralsBlock({
  onSubmitNewReferral,
  referrals,
  job,
  organization,
}) {
  const dispatch = useDispatch();
  const params = useParams();
  const { ranking1Options, ranking2Options, ranking3Options } = useSelector((state) => state.main.referral.candidate);
  const [submitted, setSubmitted] = useState([]);
  const [isOpen, showReferralsModal] = useState(false);
  const [isQuestionModalOpen, showQuestionModal] = useState(false);
  const [customAnswer, setCustomAnswer] = useState('');
  const [referralData, setReferralData] = useState({});

  const onSubmitPastReferral = (data) => {
    if (job.bounty.question) {
      showReferralsModal(false);
      showQuestionModal(true);
      setReferralData(data);
    } else makeReferral(data);
  };

  const makeReferral = ({
    phone, email, firstName, lastName, linkedinProfile, ranking,
  }) => {
    dispatch(createReferral({
      slug: params.bountySlug,
      body: {
        countryCodeId: phone?.country?.code?.id,
        phoneNumber: phone?.number,
        email,
        firstName,
        lastName,
        linkedinProfile,
        ranking: [
          [1, parseInt(ranking1Options.filter((el) => el.name === ranking?.[0]?.option)[0]?.id)],
          [2, parseInt(ranking2Options.filter((el) => el.name === ranking?.[1]?.option)[0]?.id)],
          [3, parseInt(ranking3Options.filter((el) => el.name === ranking?.[2]?.option)[0]?.id)],
        ],
        response: customAnswer,
      },
    })).then((data) => {
      if (data.status === 201) {
        toast({ subtitle: 'Your referral has been submitted!' });
        setSubmitted((prevState) => [...prevState, email]);
      }
    });
  };

  return (
    <>
      <div className="suggested-referrals">
        <div className="flex-row justify-between mt-25">
          <p className="bold f14">Past talent you've referred</p>
          <div
            onClick={() => showReferralsModal(true)}
            className="green-text bold f14 pointer"
          >
            View all
          </div>
        </div>
        <ReferralsList
          referrals={referrals.slice(0, 4)}
          buttonVariant={submitted}
          onSubmitPastReferral={onSubmitPastReferral}
        />
      </div>
      <div className="flex-row justify-between general-cta mb-15">
        <div>
          <p className="bold f11">Know someone else that would be a good fit?</p>
          <p className="f11">Refer someone new from your network</p>
        </div>
        <Button size="small" text="Refer" onClick={onSubmitNewReferral} />
      </div>
      <ReactModal
        ariaHideApp={false}
        isOpen={isOpen}
        className="referrals-modal"
        shouldCloseOnOverlayClick
        onRequestClose={() => showReferralsModal(false)}
      >
        <h2 className="mb-35"> Past talent you’ve referred</h2>
        <ReferralsList
          referrals={referrals}
          buttonVariant={submitted}
          onSubmitPastReferral={onSubmitPastReferral}
        />
      </ReactModal>
      <ReactModal
        ariaHideApp={false}
        isOpen={isQuestionModalOpen}
        shouldCloseOnOverlayClick
        onRequestClose={() => {
          showQuestionModal(false);
          setCustomAnswer('');
        }}
      >
        <h2 className="mb-5">One last thing</h2>
        <p className="mb-15">
          {organization.name}
          {' '}
          has a question about the candidate
        </p>
        <Input
          id="Custom question"
          placeholder={job.bounty?.question}
          onChange={(el) => setCustomAnswer(el)}
          value={customAnswer}
        />
        <Button
          className="mt-10 mb-20"
          text="Refer"
          onClick={() => {
            makeReferral(referralData);
            showQuestionModal(false);
          }}
        />
      </ReactModal>
    </>
  );
}
