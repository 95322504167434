import { fetchData } from 'actions/fetchData';
import { generalRequest, generalSuccess, generalFailure } from 'store/reducers/user';

export const integrateAts = ({ atsId, adminId, endpoint }) => (dispatch, getState) => (
  fetchData({
    endpoint,
    actions: [generalRequest, generalSuccess, generalFailure],
    httpMethod: 'post',
    body: {
      credential: atsId,
      ...(adminId && { privileged_user_id: adminId }),
    },
  })
)(dispatch, getState);

export const editAtsAdminID = ({ adminId, endpoint }) => (dispatch, getState) => (
  fetchData({
    endpoint,
    actions: [generalRequest, generalSuccess, generalFailure],
    httpMethod: 'patch',
    body: {
      privileged_user_id: adminId,
    },
  })
)(dispatch, getState);
