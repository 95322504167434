function CheckmarkCircle({ width = 22, height = 22, fill = '#1ABD00' }) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <rect width="24" height="24" rx="12" fill={fill} />
      <path fillRule="evenodd" clipRule="evenodd" d="M17.2356 5.32881L20.2998 7.89996L13.8719 15.5604L11.3008 18.6246L8.2366 16.0534L3.64033 12.1967L6.21148 9.13253L10.8078 12.9893L17.2356 5.32881Z" fill="white" />
    </svg>
  );
}

export default CheckmarkCircle;
