/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import iconComponents from './Icons';
import './Icon.scss';

export function IconComponent({
  name,
  size = 14,
  className,
  alt,
  onClick,
  onKeyDown,
  tabIndex,
  ...props
}) {
  if (!name) return null;

  const SvgComponent = iconComponents[name];

  return (
    <div
      className={classNames(
        'icon-font',
        `icon-${name}`,
        className,
        onClick && 'icon-pointer',
      )}
      onClick={onClick}
      onKeyDown={onKeyDown}
      tabIndex={tabIndex}
    >
      {alt && <span className="accessible-hide">{alt}</span>}
      {SvgComponent && (
        <SvgComponent.default
          style={{
            width: `${size}px`,
            height: `${size}px`,
            fontSize: `${size}px`,
          }}
          {...props}
        />
      )}
      {!SvgComponent && (
        <span style={{ display: 'inline-block', width: size, height: size }} />
      )}
    </div>
  );
}

const Icon = React.memo(IconComponent);
export default Icon;

Icon.propTypes = {
  name: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  /** Alternative text for the icon. Use this if you're using the icon without text */
  alt: PropTypes.string,
};
