import classNames from 'classnames';
import React, { useState } from 'react';
import './CopyText.scss';

function CopyText({
  content, className, textDisplay = 'Click to copy', trackEvent,
}) {
  const [isCopied, setIsCopied] = useState(false);

  const onCopyLink = () => {
    navigator.clipboard.writeText(content);
    setIsCopied(true);
    if (trackEvent) {
      trackEvent();
    }
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  return (
    !isCopied
      ? <p onClick={onCopyLink} className={classNames('copy-green', className)}>{textDisplay}</p>
      : <p className={classNames('copy-default', className)}>Copied</p>
  );
}

export default CopyText;
