import LinkedinSignup from 'components/LinkedinSignup';
import ModalContent from 'components/ModalContent';
import ReactModal from 'react-modal';

export default function SignupModal({
  isModalOpen, openSignupModal, preLoginAction, subtitle = 'Please make an account or sign in using Linkedin',
}) {
  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isModalOpen}
      onRequestClose={() => openSignupModal(false)}
      shouldCloseOnOverlayClick
    >
      <ModalContent
        title="Sign up"
        subtitle={subtitle}
        noButtons
        onClose={() => openSignupModal(false)}
      >
        <LinkedinSignup className="linkedin-signup" preLoginAction={preLoginAction} />
      </ModalContent>
    </ReactModal>
  );
}
