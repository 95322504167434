import PropTypes from 'prop-types';
import classNames from 'classnames';
import ButtonGroup from 'components/ButtonGroup';
import ProgressBar from 'components/ProgressBar';
import FocusLock from 'react-focus-lock';
import Icon from 'components/Icon';
import { Title, Subtitle } from './util';
import './Card.scss';
import CardLoader from './CardLoader';

function Card({
  title,
  subtitle,
  children,
  buttons,
  landscapeImage,
  variant = 'large',
  noPadding = false,
  progressBar,
  className,
  headerIcon,
  style,
  isLoading,
  displayAvatar,
  editAction,
}) {
  // If any button has a type='submit', we'll hook it to the card form to make it accesible
  const onSubmitButton = buttons?.filter(Boolean).filter((button) => button.type === 'submit')[0];

  const onSubmit = (e) => {
    e.preventDefault();
    if (onSubmitButton) {
      onSubmitButton.onClick();
    }
  };

  if (isLoading) {
    return (
      <div className={classNames('card', `card--${variant}`)}>
        <div className="card__content" style={style}>
          <CardLoader />
        </div>
      </div>
    );
  }

  return (
    <form id="card-form" onSubmit={onSubmit} className={classNames('card', `card--${variant}`, className)}>
      { landscapeImage && (
        <div className="card__image" style={{ backgroundImage: `url(${landscapeImage})` }} />
      )}
      { headerIcon && <Icon name={headerIcon} /> }
      <div className={classNames('card__content', noPadding && 'card__content--no-padding')} style={style}>
        { editAction && (
          <div className="card__content-edit flex-row" onClick={editAction}>
            Edit
            <Icon name="pencil" className="ml-5" width={12} height={12} />
          </div>
        ) }
        { displayAvatar && (
          <div className="card__content-avatar" style={{ backgroundImage: `url(${displayAvatar})` }} />
        )}
        { title && <Title title={title} /> }
        { subtitle && <Subtitle subtitle={subtitle} /> }
        { children && (
          <div className="card__content-children">
            {children}
          </div>
        )}
      </div>
      <div className="card__content-footer">
        {
            progressBar && (
              <ProgressBar progressBar={progressBar} />
            )
          }

        {buttons && (
          <div className="card__content-footer__button-group">
            <ButtonGroup items={buttons} />
          </div>
        )}
      </div>
    </form>
  );
}

export default Card;

Card.propTypes = {
  title: PropTypes.any,
  subtitle: PropTypes.any,
  variant: PropTypes.oneOf(['large', 'small', 'dynamic']),
  children: PropTypes.any,
  buttons: PropTypes.array,
  landscapeImage: PropTypes.string,
  displayAvatar: PropTypes.string,
};
