/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
import Icon from 'components/Icon';
import { useSelector } from 'react-redux';
import './EmailShare.scss';
import { trackEvent } from 'util/tracking';

export default function EmailShare({
  bounty, job, bountyLink, organization,
}) {
  const user = useSelector((state) => state.user);

  return (
    <a
      href={`mailto:?subject=Referral&body=${encodeURIComponent(subject(bounty, job, bountyLink, user, organization))}`}
      target="_blank"
      className="email-share"
      rel="noreferrer"
    >
      <Icon name="email" onClick={() => trackEvent('Employer: Message bounty')} />
    </a>
  );
}

const subject = (bounty, job, bountyLink, user, organization) => {
  const { firstName, lastName } = user;
  return (
    `Hey, this is ${firstName} from ${organization?.name}! \n
    I'm reaching out because we're hiring for a great ${job?.title}. \n
    Do you know someone who might be a great fit? Send us your referrals and earn ${bounty?.referral?.localized} if we end up hiring one of them.
    Here's the bounty with the job description and referral link: ${bountyLink}\n
    Thanks,
    ${firstName} ${lastName}`
  );
};
