/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import ReactQuill from 'react-quill';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import {
  Link,
  useLocation, useNavigate, useParams, useSearchParams,
} from 'react-router-dom';
import toast from 'components/Alerts';
import { loadReferral, confirmReferral, rejectReferral } from 'actions/referrals';
import { prettifyLocations } from 'util';
import Card from 'components/Card';
import './CandidateLanding.scss';
import SignupModal from 'components/SignupModal';

function CandidateLanding() {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isModalOpen, openSignupModal] = useState(false);
  const location = useLocation();
  const { referral } = useSelector((state) => state.referral);
  const { token } = useSelector((state) => state.user);
  const { job = {}, organization = {} } = referral || {};

  useEffect(() => {
    dispatch(loadReferral(params.code));

    return () => localStorage.removeItem('confirmReferral');
  }, []);

  const companyCardSubtitle = () => (
    <div className="company-card__subtitle">
      { job.salary && (
      <p className="bold">
        {job.salary.localized?.min ? `${job.salary.localized?.min} - ` : ''}
        {job.salary.localized?.max || '' }
      </p>
      )}
      <p className="grey">
        {prettifyLocations(job.locations)}
      </p>
    </div>
  );

  const preLoginAction = () => {
    localStorage.setItem('confirmReferral', location.pathname);
  };

  const onConfirm = () => {
    if (!token) {
      openSignupModal(true);
    } else {
      dispatch(confirmReferral(params.code)).then((res) => {
        if (res.status === 201) {
          toast({ title: 'Referral submitted', subtitle: `Your information has been sent to ${organization.name}` });
          setSearchParams({ status: 'success' });
        }
      });
    }
  };

  const onReject = () => {
    dispatch(rejectReferral(params.code)).then((res) => {
      if (res.status === 200) {
        toast({ title: 'Referral rejected', subtitle: `Your information won't be sent to ${organization.name}` });
        setSearchParams({ status: 'success' });
      }
    });
  };

  return (
    <>
      <Helmet title="Referral confirmation | Refer" />
      <div className="referral">
        <div className="left-column">
          <Card
            title={job.title}
            className="mb-0"
            subtitle={companyCardSubtitle}
          >
            <ReactQuill
              readOnly
              modules={{ toolbar: null }}
              value={job.description}
            />
          </Card>
        </div>
        { !searchParams.get('status') && (
          <div className="right-column">
            <Card
              variant="small"
              title="Confirm referral"
              className="confirm-referral-action"
              buttons={[
                {
                  id: 'confirm',
                  text: 'Confirm',
                  onClick: onConfirm,
                },
                {
                  id: 'reject',
                  text: 'Reject',
                  variant: 'reverse-secondary',
                  onClick: onReject,
                },
              ]}
            >
              <p>
                Hi, you’ve been referred to
                {' '}
                { organization.name }
                {' '}
                for their
                {' '}
                { job.title }
                {' '}
                role by your friend or colleague.
              </p>
              <p className="mt-20">
                Once you confirm below we’ll let the company know you’re interested in the role. If you do not confirm,
                {' '}
                { organization.name }
                {' '}
                will not receive your information.
              </p>
            </Card>
            <Card
              variant="small"
              title={organization.name}
              className="make-referral-organization-card"
            >
              <p>{organization.description}</p>
              <p className="mt-8 grey">{ `${organization.funding?.value} • ${organization.category}` }</p>
              <Link className="green-text pointer" to={`/c/${organization.slug}`}>
                View all jobs
              </Link>
            </Card>
          </div>
        )}
      </div>
      <SignupModal isModalOpen={isModalOpen} openSignupModal={openSignupModal} preLoginAction={preLoginAction} />
    </>
  );
}

export default CandidateLanding;
