/* eslint-disable react/no-unescaped-entities */
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { faqsEmployer } from 'util/constants';
import LinkedinSignup from 'components/LinkedinSignup';
import Accordion from 'components/Accordion';
import Home1 from 'images/home-employer-1.webp';
import Home2 from 'images/home-employer-2.webp';
import Home3 from 'images/home-employer-3.webp';
import Home4 from 'images/home-employer-4.webp';
import Home5 from 'images/home-employer-5.webp';
import Footer from 'pages/LandingPages/Footer';
import '../Home.scss';
import ContactForm from 'components/ContactForm';

function Employer() {
  const { token } = useSelector((state) => state.user);

  return (
    <div className="employer-home">
      <Helmet title="Refer" />
      <div className="section-1 home-block">
        <div className="section-1__left-screen home-block__left-screen">
          <div className="container">
            <h2 className="home-title section-1-title">
              Hire faster.
              <br />
              {' '}
              Reward your network.
            </h2>
            <div className="flex-column">
              <p className="home-description section-1-description">Create and share referral bonuses with the people you trust most.</p>
              { !token && <LinkedinSignup className="mt-20" />}
            </div>
          </div>
        </div>
        <div className="section-1__right-screen">
          <div className="section-1__right-screen-image employer-1">
            <img src={Home1} alt="decoration" />
          </div>
        </div>
      </div>
      <div className="section-2 home-block">
        <div className="section-2__left-screen home-block__left-screen">
          <div className="container">
            <img src={Home2} alt="decoration" />
          </div>
        </div>
        <div className="section-2__right-screen">
          <div className="container">
            <h2 className="home-title">Import jobs</h2>
            <div className="flex-column">
              <p className="home-description">Get started in minutes by syncing your ATS. Use your existing jobs or easily create new ones.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="section-3 home-block">
        <div className="section-3__left-screen home-block__left-screen">
          <div className="container">
            <div>
              <h2 className="home-title">Create bounty</h2>
              <div className="flex-column">
                <p className="home-description">Set custom referral bonuses for open roles across your organization.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="section-3__right-screen" style={{ background: `url(${Home3})` }} />
      </div>
      <div className="section-4 home-block">
        <div className="section-4__left-screen home-block__left-screen">
          <div className="container">
            <img src={Home4} alt="decoration" loading="lazy" />
          </div>
        </div>
        <div className="section-4__right-screen">
          <div className="container">
            <h2 className="home-title">Leverage your network</h2>
            <div className="flex-column">
              <p className="home-description">Share your bounty with those you trust most: your friends, colleagues, and company alumni.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="section-5 home-block">
        <div className="section-5__left-screen home-block__left-screen">
          <div className="container">
            <div>
              <h2 className="home-title">High quality connections</h2>
              <div className="flex-column">
                <p className="home-description">We analyze each referral’s work history and require them to confirm interest in your role and company.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="section-5__right-screen employer-5">
          <img src={Home5} alt="decoration" loading="lazy" />
        </div>
      </div>
      <div className="section-3 home-block">
        <div className="section-3__left-screen home-block__left-screen">
          <div className="container">
            <div>
              <h2 className="home-title">Contact us</h2>
              <div className="flex-column">
                <p className="home-description">Not ready to take the plunge? Send us an email to get started.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-column justify-center home-contact-form">
          <ContactForm hideTitle />
        </div>
      </div>
      { !token && (
      <div className="home-cta flex-row justify-between">
        <h2>Sign up today to get started</h2>
        <LinkedinSignup />
      </div>
      )}
      <div className="faqs">
        <h2 className="home-title">FAQs</h2>
        <Accordion items={faqsEmployer} />
      </div>
      <Footer />
    </div>
  );
}

export default Employer;
