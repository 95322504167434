import classNames from 'classnames';
import Icon from 'components/Icon';

export default function Navigation({
  setCurrentIndex, isBack, isNext, currentIndex,
}) {
  const onKeyDown = (e) => {
    if (e.keyCode === 37 && isBack) setCurrentIndex(currentIndex - 1);
    if (e.keyCode === 39 && isNext) setCurrentIndex(currentIndex + 1);
  };
  return (
    <div className="referral-sidebar__navigation">
      <Icon
        className={classNames(!isBack && '--hide')}
        name="arrow-left"
        onClick={() => setCurrentIndex(currentIndex - 1)}
        tabIndex="0"
        onKeyDown={(e) => onKeyDown(e)}
      />
      <Icon
        className={classNames(!isNext && '--hide')}
        name="arrow-right"
        onClick={() => setCurrentIndex(currentIndex + 1)}
        tabIndex="0"
        onKeyDown={(e) => onKeyDown(e)}
      />
    </div>
  );
}
