function BriefCase({ width = 24, height = 24 }) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <mask id="mask0_1002_1218" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect y="24" width="24" height="24" transform="rotate(-90 0 24)" fill="#C4C4C4" />
      </mask>
      <path d="M9.01722 3.00001C8.8515 2.99928 8.69233 3.06698 8.57472 3.18804C8.45698 3.30911 8.39058 3.47358 8.38988 3.64543V5.92245H2.62386C2.60391 5.92144 2.58396 5.92144 2.56401 5.92245C2.40945 5.93807 2.26618 6.013 2.16197 6.13219C2.05762 6.25152 2.00001 6.40687 2.00015 6.56787V9.82821L9.81567 12.0076V11.9836C9.81442 11.6257 10.093 11.3343 10.4381 11.333H13.5617C13.9068 11.3344 14.1855 11.6256 14.1841 11.9834V12.0087L22 9.83702V6.56788C21.9994 6.39604 21.9329 6.23143 21.8153 6.11038C21.6976 5.98946 21.5384 5.92176 21.3727 5.92249H15.6102V3.64547C15.6095 3.47363 15.5431 3.30917 15.4254 3.18808C15.3078 3.06702 15.1486 2.99932 14.9829 3.00004L9.01722 3.00001ZM9.63982 4.29604H14.3598V5.92244H9.63982V4.29604ZM2 11.1735V20.3546C2.00056 20.5257 2.06654 20.6897 2.18344 20.8107C2.30034 20.9316 2.45853 20.9997 2.6237 21H21.3724C21.5381 21.0007 21.6973 20.933 21.815 20.812C21.9326 20.6909 21.9992 20.5264 21.9997 20.3546V11.1798L14.1842 13.3479V14.4996C14.1855 14.8574 13.9069 15.1489 13.5617 15.1502H10.4381C10.093 15.1487 9.81422 14.8574 9.81562 14.4996V13.3479L2 11.1735ZM11.0654 12.6291C11.1214 12.7794 11.1214 12.9459 11.0654 13.0962V13.853H12.9342V13.0962C12.8782 12.9459 12.8782 12.7794 12.9342 12.6291H11.0654Z" fill="#000000" />
    </svg>
  );
}

export default BriefCase;
