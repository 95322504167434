import Icon from 'components/Icon';
import './Accordion.scss';

function AccordionItem({ item, id }) {
  return (
    <div className="accordion__item">
      <input type="checkbox" id={`check-${id}`} />
      <label className="accordion__item-label" htmlFor={`check-${id}`}>
        <h2>{ item.title }</h2>
        <Icon style={{ width: 18, height: 18 }} name="close" className="icon-plus" />
      </label>
      <div className="accordion__item-content">
        { item.content }
      </div>
    </div>
  );
}

export default AccordionItem;
