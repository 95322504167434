/* eslint-disable max-len */

// Remove referrals whose role category doesn't match the current job role category.
// Also remove those already referred to this job.
export function filterRelevantReferrals(referrals, job, bootstrapRoles) {
  if (!referrals.length || !bootstrapRoles.length) return [];

  const referralsWithCategory = referrals.map((el) => ({
    ...el,
    job: {
      ...el.job,
      roleCategory: getRoleCategory(bootstrapRoles, el.job.role?.value),
    },
  }));

  const jobWithCategory = { ...job, roleCategory: getRoleCategory(bootstrapRoles, job.role?.value) };

  const emailIdMap = new Map();

  referralsWithCategory.forEach((referral) => {
    if ((referral.job?.roleCategory === jobWithCategory.roleCategory) && (referral?.job?.bounty?.slug !== job?.bounty?.slug)) {
      emailIdMap.set(referral.referral.email, referral);
    }
  });
  return Array.from(emailIdMap, ([email, referral]) => (referral));
}

// Referrals coming from user/referrals aren't unique. If you referred
// Mark to a FE job and a BE job, you will receive both instances.
// This function will remove both instances of a referral if at least one of them
// belongs to the job you're looking at.
// Note: we don't have a unique identifier for referrals so we use their email address.
export const removeDuplicatesReferrals = (relevantReferrals, allReferrals, job) => relevantReferrals.map((referral) => {
  if (allReferrals.length === 0) return referral;
  if (allReferrals.filter((el) => (el.referral.email === referral.referral.email) && (el.job?.bounty?.slug === job.bounty?.slug)).length) {
    return null;
  }
  return referral;
}).filter(Boolean);

const getRoleCategory = (bootstrapRoles, roleValue) => (
  bootstrapRoles.filter((role) => role.value === roleValue)[0]?.categoryId
);
