function NoAts({ width = 24, height = 24 }) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_1441_4705)">
        <g clipPath="url(#clip1_1441_4705)">
          <rect x="2.5" y="2.5" width="19" height="19" rx="1.5" stroke="#150828" />
          <path d="M2 4C2 2.89543 2.89543 2 4 2H20C21.1046 2 22 2.89543 22 4V6H2V4Z" fill="#150828" />
          <rect x="18.8207" y="1.95793" width="4.76735" height="12.302" rx="0.8" transform="rotate(45 18.8207 1.95793)" fill="#292936" stroke="white" />
          <path d="M13.2531 13.0322L12.1812 11.9603L11.1093 10.8884C10.6998 10.4789 9.99966 10.7689 9.99966 11.348V13.4918C9.99966 13.8508 10.2907 14.1418 10.6497 14.1418H12.7935C13.3726 14.1418 13.6626 13.4417 13.2531 13.0322Z" fill="#292936" stroke="white" />
          <rect x="20.4854" y="0.292893" width="4.76735" height="2.88367" rx="0.8" transform="rotate(45 20.4854 0.292893)" fill="#292936" stroke="white" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1441_4705">
          <rect width="24" height="24" fill="white" />
        </clipPath>
        <clipPath id="clip1_1441_4705">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default NoAts;
