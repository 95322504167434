/* eslint-disable max-len */
import { useDispatch, useSelector } from 'react-redux';
import { editFormCandidate, setError } from 'store/reducers/candidateReferralForm';
import Input from 'components/Input';
import Dropdown from 'components/Dropdown';
import '../ReferrerReferral.scss';
import { Helmet } from 'react-helmet-async';
import { trackEvent } from 'util/tracking';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';

function Step1Card() {
  const dispatch = useDispatch();
  const candidateForm = useSelector((state) => state.candidateReferralForm.candidate);
  const {
    firstName,
    lastName,
    email,
    phoneNumber,
    linkedinProfile,
    countryCode,
  } = candidateForm;
  const { countryCodes } = useSelector((state) => state.main);
  const { errors = {} } = useSelector((state) => state.candidateReferralForm);

  const onChange = (key, value) => {
    if (!candidateForm[key]) {
      trackEvent(`Bounty: input ${key}`);
    }
    dispatch(editFormCandidate({ key, value }));

    // If user is typing, remove error
    if (errors[key]) {
      dispatch(setError({ key, msg: null }));
    }
  };

  const onInput = (key, value) => {
    if (!candidateForm[key]) {
      trackEvent(`Bounty: input ${key}`);
    }
    dispatch(editFormCandidate({ key, value }));
  };

  return (
    <div>
      <Helmet prioritizeSeoTags title="Candidate info | Refer" />
      <div className="flex-row mobile-container">
        <Input
          className="w-100 mr-10"
          id="name"
          placeholder="First name"
          onChange={(value) => onInput('firstName', value)}
          value={firstName}
        />
        <Input
          className="w-100"
          id="lastName"
          placeholder="Last name"
          onChange={(value) => onInput('lastName', value)}
          value={lastName}
        />
      </div>
      <Input
        id="linkedin"
        placeholder={errors.linkedinProfile || 'What is their Linkedin URL?'}
        onChange={(value) => onChange('linkedinProfile', value)}
        value={linkedinProfile}
        error={!!errors.linkedinProfile}
      />
      <Input
        id="email"
        placeholder={errors.email || 'What is their email?'}
        onChange={(value) => onChange('email', value)}
        value={email}
        error={!!errors.email}
      />
      <div className="flex-row phone-number-container mobile-container">
        <Dropdown
          className="area-code-dropdown"
          placeholder={countryCode?.code ? `+${countryCode?.code}` : 'Country code'}
          items={countryCodes}
          onClick={(value) => onInput('countryCode', value)}
          value={countryCode?.country}
        />
        <Input
          id="phoneNumber"
          placeholder={errors.phoneNumber || 'What is their phone number?'}
          onChange={(value) => onChange('phoneNumber', value)}
          value={phoneNumber}
          type="number"
          error={!!errors.phoneNumber}
        />
      </div>
    </div>
  );
}

export default Step1Card;
