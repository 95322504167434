import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Card from 'components/Card';
import Input from 'components/Input';
import { integrateAts } from 'actions/ats';
import LeverAtsSteps from './LeverAtsSteps';

function LeverSetup() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [atsId, setAtsId] = useState(null);

  const inImport = () => {
    dispatch(integrateAts({ atsId, endpoint: 'lever/register/v1/api' }))
      .then(({ status }) => {
        if (status === 201) navigate('/signup/jobs');
      });
  };

  return (
    <>
      <Helmet title="Lever setup | Refer" />
      <Card
        title="Lever setup"
        subtitle="Follow the steps below to sync with your ATS and import your open roles."
        style={{ overflow: 'scroll' }}
        buttons={[
          {
            id: 'import',
            text: 'Import',
            variant: !atsId ? 'disabled' : 'default',
            onClick: inImport,
          },
        ]}
      >
        <Input
          id="ats-id"
          placeholder="API KEY"
          onChange={(id) => setAtsId(id)}
          value={atsId}
        />
        <LeverAtsSteps />
      </Card>
    </>
  );
}

export default LeverSetup;
