import { useState } from 'react';
import { Combobox } from '@headlessui/react';
import 'components/Dropdown/Dropdown.scss';

const people = [
  'Durward Reynolds',
  'Kenton Towne',
  'Therese Wunsch',
  'Benedict Kessler',
  'Katelyn Rohan',
];

export default function SearchDropdown({
  items, placeholder, value, onClick,
}) {
  const [selectedItem, setSelectedItem] = useState(value || null);
  const [query, setQuery] = useState('');

  const filtereditems = query === ''
    ? items
    : items.filter((item) => item.value.toLowerCase().includes(query.toLowerCase()));

  const onChange = (item) => {
    const selectedItem = item.value || item.name || item;
    setSelectedItem(selectedItem);
    onClick(item);
  };

  const onSearch = (item) => {
    if (item === '') {
      onClick(null);
      setSelectedItem(null);
    }
    setQuery(item);
  };

  return (
    <div className="pos-relative dropdown">
      <Combobox value={selectedItem} onChange={onChange}>
        <Combobox.Input
          placeholder={placeholder}
          className="dropdown-toggle search-dropdown"
          onChange={(e) => onSearch(e.target.value)}
        />
        <Combobox.Options className="dropdown-menu">
          {filtereditems.map((item) => (
            <Combobox.Option className="dropdown-item" key={item?.id || item} value={item}>
              {item?.value || item?.name || item}
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </Combobox>
    </div>
  );
}
