import { useState } from 'react';
import PropTypes from 'prop-types';
import Input from 'components/Input';
import Facet from 'components/Facet';
import './FacetsSelector.scss';
import 'components/Dropdown/Dropdown.scss';
import { loadGeoLocations } from 'actions';
import { useDispatch, useSelector } from 'react-redux';
import DropdownMenuGeo from 'components/DropdownMenuGeo';
import Checkbox from 'components/Checkbox';
import classNames from 'classnames';
import { trackEvent } from 'util/tracking';

function FacetsSelector({
  onSelectFacet, value, placeholder,
}) {
  const dispatch = useDispatch();
  const { suggestions } = useSelector((state) => state.ui);
  const [inputValue, setInputValue] = useState('');
  const [checkboxValue, setCheckboxValue] = useState(value.some((el) => el.remote));
  const [selectedFacets, setSelectedFacets] = useState(value || []);

  const onChange = (el, remote) => {
    if (remote) {
      const newFacets = selectedFacets.map((el) => el);
      if (checkboxValue === false) {
        newFacets.push({
          dataType: null,
          id: null,
          remote: true,
          value: null,
        });
        trackEvent('Employer: remote');
      } else {
        const remoteObjIndex = selectedFacets.findIndex((obj) => obj.remote);
        if (remoteObjIndex > -1) {
          newFacets.splice(remoteObjIndex, 1);
        }
      }
      setCheckboxValue(!checkboxValue);
      setSelectedFacets(newFacets);
      onSelectFacet(newFacets);
    }
    if (el) {
      setInputValue(el);
      if (el.length > 3) {
        dispatch(loadGeoLocations(el));
      }
    }
    if (!el) {
      setInputValue('');
      dispatch(loadGeoLocations(''));
    }
  };

  const onPressEnter = (facet) => {
    const location = { ...facet, remote: null };
    const newFacets = [...selectedFacets, location];
    if (!selectedFacets.includes(location)) {
      if (newFacets.length === 1) trackEvent('Employer: add locations');
      setSelectedFacets(newFacets);
    }
    onSelectFacet(newFacets);
    onChange(false);
  };

  const onCloseFacet = (facet) => {
    const newFacets = selectedFacets.filter((el) => el !== facet) || [];
    setSelectedFacets(newFacets);
    onSelectFacet(newFacets);
  };

  const facets = selectedFacets.map(
    (facet) => {
      if (facet.value) {
        return (
          <Facet
            key={facet.id}
            name={facet.value || facet.name}
            onClose={() => onCloseFacet(facet)}
          />
        );
      } else return null;
    },
  ).filter(Boolean);

  return (
    <>
      <div className="facet-selector">
        <Input
          id={placeholder}
          placeholder={placeholder}
          onChange={(el) => onChange(el)}
          value={inputValue}
        />
        {suggestions.length > 0 && (
          <div className="dropdown-menu">
            <DropdownMenuGeo
              items={suggestions.slice(0, 10)}
              onClickitem={(el) => onPressEnter(el)}
            />
          </div>
        )}
      </div>
      <div className="facets-container">
        <div className={classNames('remote-container', checkboxValue && 'remote-container--selected')}>
          <Checkbox
            key="remote"
            variant="square"
            text="This job is remote friendly"
            isSelected={checkboxValue}
            onClick={() => onChange(null, true)}
            isGreyVariant
          />
        </div>
        { facets }
      </div>
    </>
  );
}

export default FacetsSelector;

FacetsSelector.propTypes = {
  onSelectFacet: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.array,
};
