/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadUserReferrals } from 'actions/user';
import Button from 'components/Button';
import Card from 'components/Card';
import './BountyCTA.scss';
import ReactModal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import FullScreenLoader from 'components/FullScreenLoader';
import ReferralsBlock from './ReferralsBlock';
import { filterRelevantReferrals, removeDuplicatesReferrals } from './util';
import RequestReferralForm from './RequestReferralForm';

export default function BountyCTA({
  job, onReferFriend, organization, requestedReferral,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { referrals, token } = useSelector((state) => state.user);
  const { roles } = useSelector((state) => state.main.job);
  const [isOpen, openRequestReferralModal] = useState(false);
  const { referral, referrer } = requestedReferral || {};

  useEffect(() => {
    if (token) dispatch(loadUserReferrals());
    // We were requesting a referral before loging in
    if (sessionStorage.getItem('requestReferral')) {
      openRequestReferralModal(true);
    }
  }, [token]);

  // 1. Get referrals relevant to this job
  const relevantReferrals = filterRelevantReferrals(referrals.data, job, roles);

  // 2. Remove duplicates
  const cleanReferrals = removeDuplicatesReferrals(relevantReferrals, referrals.data, job);

  const cardTitle = requestedReferral ? 'Referral request' : `${job.bounty?.referral?.localized} referral bonus`;
  const cardSubtitle = requestedReferral
    ? `Hi  ${referrer.firstName}, ${referral.firstName} has requested a referral to ${organization.name} for their ${job.title} role.`
    : 'Know someone that would be a good fit for this role? Refer them, and if they get hired you’ll get paid.';
  const cardButton = requestedReferral ? `Refer ${referral.firstName}` : 'Refer a friend';

  const onRejectRequestedReferral = () => {
    navigate('/u/profile');
  };

  return (
    <Card
      variant="small"
      className="make-referral-action"
    >
      <h2 className="job-cta-card-title">{cardTitle}</h2>
      <p className="job-cta-card-subtitle">{cardSubtitle}</p>
      { cleanReferrals.length && !requestedReferral
        ? (
          <ReferralsBlock
            onSubmitNewReferral={onReferFriend}
            referrals={cleanReferrals}
            job={job}
            organization={organization}
          />
        )
        : <Button size="medium" className="cta-no-referrals mr-15" text={cardButton} onClick={onReferFriend} />}
      { requestedReferral && <Button size="medium" variant="reverse-secondary" text="Not now" onClick={onRejectRequestedReferral} />}
      { !requestedReferral && (
      <div className="mb-20 ml-25">
        <p className="grey-text">
          Are you a job seeker?
          {'  '}
          <span className="green-text pointer" onClick={() => openRequestReferralModal(true)}>Request a referral</span>
        </p>
      </div>
      )}
      <ReactModal
        ariaHideApp={false}
        isOpen={isOpen}
        className="referrals-modal"
        shouldCloseOnOverlayClick
        onRequestClose={() => openRequestReferralModal(false)}
      >
        <RequestReferralForm openModal={openRequestReferralModal} job={job} />
      </ReactModal>
    </Card>
  );
}
