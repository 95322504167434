import { createSlice } from '@reduxjs/toolkit';
import { saveToken } from 'util/authorization';

const main = createSlice({
  name: 'main',
  initialState: {
    ats: {},
    job: {
      employment: {},
      work: {},
    },
    bounty: {
      referral: {},
    },
    organization: {},
    referral: {
      candidate: {},
    },
    interview: {},
    isFetching: false,
  },
  reducers: {
    bootstrapRequest(state) {
      state.isFetching = true;
    },
    bootstrapSuccess(state, { payload }) {
      const {
        ats, job, user = {}, bounty, organization, referral, geo, interview,
      } = payload.data;

      // Set token
      const token = user.credentials?.token;
      if (token) {
        saveToken(token);
      }

      const referralCandidateRanking = {};
      Object.entries(referral.candidate.ranking).forEach(([id, data]) => {
        referralCandidateRanking[`ranking${id}Options`] = Object.entries(data.options).map(
          ([id, name]) => ({ id, name }),
        );
      });

      const countryCodes = geo.countryCode.map((el) => ({ ...el, value: el.country }));

      const unitedStates = countryCodes.find((el) => el.country === 'United States');
      const fromIndex = countryCodes.indexOf(unitedStates);
      const toIndex = 0;
      const element = countryCodes.splice(fromIndex, 1)[0];
      countryCodes.splice(toIndex, 0, element);

      state.ats = ats;
      state.countryCodes = countryCodes;
      state.job.roles = job.role;
      state.job.employment.type = job.employment.type;
      state.job.work.experience = job.work.experience;
      state.bounty.referral.experience = bounty.referral.inRole;
      state.organization.funding = organization.funding;
      state.referral.candidate = referralCandidateRanking;
      state.interview = interview;
      state.isFetching = false;
    },
    bootstrapFailure(state) {
      state.isFetching = false;
    },
  },
});

const { actions, reducer } = main;

export const {
  bootstrapRequest,
  bootstrapSuccess,
  bootstrapFailure,
} = actions;

export default reducer;
