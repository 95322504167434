import { createSlice } from '@reduxjs/toolkit';
import { shuffleArray } from 'util';

const openJobs = createSlice({
  name: 'openJobs',
  initialState: {
    boardData: [],
    shuffledOpenJobs: [],
    isFetching: false,
  },
  reducers: {
    openJobsRequest(state) {
      state.isFetching = true;
    },
    openJobsSuccess(state, { payload }) {
      // Create randomized jobs array
      const jobs = [];
      payload.data.forEach((company) => {
        company.departments.forEach(
          (el) => el?.jobs.forEach((job) => {
            if (job.bounty) jobs.push({ ...job, organization: company });
          }));
      });

      state.boardData = payload.data;
      state.shuffledOpenJobs = shuffleArray(jobs);
      state.isFetching = false;
    },
    openJobsFailure(state) {
      state.isFetching = false;
    },
  },
});

const { actions, reducer } = openJobs;

export const {
  openJobsRequest,
  openJobsSuccess,
  openJobsFailure,
} = actions;

export default reducer;
