function Link() {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14">
      <path d="M7.43106 1.40204L4.99269 3.84015L5.93231 4.7794L8.37081 2.34156C9.27714 1.43483 10.7518 1.43483 11.6586 2.34156C12.5654 3.24802 12.5654 4.72291 11.6586 5.62938L9.22026 8.06774L10.16 9.00736L12.5985 6.56925C14.0227 5.14424 14.0229 2.82653 12.5984 1.40207C11.1736 -0.0229427 8.85533 -0.022812 7.4312 1.40182L7.43106 1.40204ZM2.34258 11.6577C1.90326 11.2186 1.66115 10.6345 1.66115 10.014C1.66115 9.3927 1.90275 8.80897 2.34196 8.37001L2.34235 8.36988L4.78058 5.93138L3.84097 4.99213L1.40299 7.42997V7.43035C0.713002 8.12035 0.332852 9.03801 0.333375 10.0135C0.332864 10.9894 0.713394 11.9069 1.40325 12.5969C2.82749 14.0209 5.14565 14.0213 6.57043 12.5969L9.00828 10.1588L8.06853 9.21942L5.6303 11.6575C4.72357 12.5639 3.24868 12.5641 2.34248 11.6577L2.34258 11.6577ZM4.80838 10.131L10.132 4.80709L9.19237 3.86748L3.86877 9.19173L4.80838 10.131Z" fill="black" />
    </svg>

  );
}

export default Link;
