import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Modal from 'react-modal';
import classNames from 'classnames';
import { submitAtsSuggestion } from 'actions/ui';
import Card from 'components/Card';
import List from 'components/List';
import Alert from 'components/Alert';
import { listItems, atsSuggestions } from './util';

function EmployerSetup() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [selectedIntegration, setIntegration] = useState(null);
  const [selectedAtsSuggestion, setAtsSuggestion] = useState(null);
  const [otherValue, setOtherValue] = useState(null);
  const [isModalOpen, openModal] = useState(false);
  const [isAtsSuggestionModalOpen, openAtsSuggestionModal] = useState(false);
  const showModal = isModalOpen || isAtsSuggestionModalOpen;

  const onConnect = () => {
    if (selectedIntegration.id === 'no-ats') {
      openModal(true);
    } else {
      navigate(`/c/${params.companySlug}/${selectedIntegration.id}`);
    }
  };

  const onSubmitAtsSuggestion = () => {
    dispatch(submitAtsSuggestion({ name: selectedAtsSuggestion?.id || otherValue }));
  };

  const onCloseModal = () => {
    setAtsSuggestion(null);
    setOtherValue(null);
    openAtsSuggestionModal(false);
    openModal(false);
  };

  const modalContent = () => {
    if (isModalOpen) {
      return (
        <Alert
          alert={{ title: 'Are you sure?', subtitle: 'Connecting your ATS allows you to import all of your jobs so you don’t have to recreate them.' }}
          buttonPrimary="I'm sure"
          buttonSecondary="Go back"
          buttonPrimaryAction={() => navigate(`/c/${params.companySlug}/create`)}
          onClose={onCloseModal}
        />
      );
    }
    if (isAtsSuggestionModalOpen) {
      return (
        <Alert
          alert={{ title: 'Which ATS do you use?', subtitle: 'Let us know which you use, and we’ll keep you posted when the integration is live.' }}
          buttonPrimary="Submit"
          buttonSecondary="Cancel"
          primaryVariant={(selectedAtsSuggestion || otherValue) ? 'default' : 'disabled'}
          className="ats-suggestion-alert"
          buttonPrimaryAction={onSubmitAtsSuggestion}
          onClose={onCloseModal}
        >
          <List
            items={atsSuggestions}
            onChangeInput={(value) => setOtherValue(value)}
            onSelectItem={setAtsSuggestion}
            showCheckbox
            className="ats-list"
          />
        </Alert>
      );
    }
    return null;
  };

  return (
    <>
      <Helmet title="Set up | Refer" />
      <Card
        title="Set up your jobs"
        subtitle="Select an option below to get started"
        buttons={[
          {
            id: 'card-form',
            type: 'submit',
            text: selectedIntegration?.id === 'no-ats' ? 'Next' : 'Connect',
            variant: !selectedIntegration ? 'disabled' : 'default',
            onClick: onConnect,
          },
        ]}
      >
        <List items={listItems} onSelectItem={setIntegration} showCheckbox className="ats-list" />
        <p className="grey-text">
          We’re still in beta and working on additional integrations.
          {' '}
          <span className="green-text pointer" onClick={() => openAtsSuggestionModal(true)}>Click here </span>
          to let us know what ATS you use, and we’ll follow up when it’s available.
        </p>
      </Card>
      <Modal
        ariaHideApp={false}
        isOpen={showModal}
        onRequestClose={onCloseModal}
        shouldCloseOnOverlayClick
        className={classNames(isAtsSuggestionModalOpen && 'ats-suggestion')}
      >
        {modalContent()}
      </Modal>
    </>
  );
}

export default EmployerSetup;
