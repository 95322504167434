/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import {
  useNavigate, useLocation, Link,
} from 'react-router-dom';
import { logOut } from 'util/authorization';
import Balloon from 'components/Balloon';
import './Navbar.scss';
import Avatar from 'components/Avatar';
import { setMobileMenuStatus } from 'store/reducers/ui';

export default function EmployerSection({ user = {}, isMobile }) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const btnRef = useRef();
  const path = location.pathname;
  const [isOpen, openDropdown] = useState(false);
  const { organizations } = useSelector((state) => state.user);
  const firstOrgSlug = organizations[0]?.slug;

  const avatar = user.links?.image.user || user.links?.linkedin.image;
  const employerNavbarItems = [
    {
      name: 'Profile',
      path: '/u/profile',
      id: 1,

    },
    {
      name: 'Company profile',
      path: `/c/${firstOrgSlug}`,
      id: 2,

    },
    {
      name: 'Log out',
      id: 3,
    },
  ];

  const onClickDropdownItem = (item) => {
    openDropdown(false);
    closeMobileMenu();
    if (item.name === 'Log out') {
      dispatch(logOut());
    } else navigate(item.path);
  };

  const closeMobileMenu = () => {
    dispatch(setMobileMenuStatus({ status: false }));
  };

  return (
    <div className="navbar-section">
      <Link
        className={classNames('logged-in-item', (path === `/c/${firstOrgSlug}/jobs`) && 'active', 'bounty')}
        to={`/c/${firstOrgSlug}/jobs`}
        onClick={closeMobileMenu}
      >
        Create bounty
      </Link>
      <Link
        className={classNames('logged-in-item', path.includes('referrals') && 'active', 'referrals')}
        to={`/c/${firstOrgSlug}/referrals`}
        onClick={closeMobileMenu}
      >
        Referrals
      </Link>
      {
        !isMobile && (
          <div className="navbar-section__avatar" ref={btnRef} onClick={() => openDropdown(!isOpen)}>
            <Avatar
              src={avatar}
              alt="user-avatar"
              size="medium"
              className="pointer"
              fallbackContent={user?.firstName?.charAt(0)}
            />
          </div>
        )
      }
      {
        isMobile && (
          <ul>
            { employerNavbarItems.map((item) => <li key={item.id} onClick={() => onClickDropdownItem(item)}>{item.name}</li>,
            )}
          </ul>
        )
      }
      { isOpen && (
        <Balloon
          openDropdown={(status) => openDropdown(status)}
          ref={btnRef}
          className="navbar-dropdown"
          items={employerNavbarItems}
          onClick={onClickDropdownItem}
        />
      )}
    </div>
  );
}
