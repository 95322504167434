/* eslint-disable max-len */
import ExperienceSectionRow from './ExperienceSectionRow';
import './ReferralBody.scss';

function ExperienceSection({ profiles, referral }) {
  const { job, education = {} } = profiles?.referral?.highlights || {};

  const jobExperience = job ? job?.map((job) => (
    <ExperienceSectionRow key={job.title} item={job} />
  )) : [];

  return (
    <div className="experience-section">
      <h3>Experience</h3>
      { !jobExperience.length && (
        <p className="mt-10">
          We were unable to pull their work history and education.
          <br />
          Their account may be private.
        </p>
      )}
      <div>
        {jobExperience}
        { education?.degree && <ExperienceSectionRow item={education} /> }
        <a href={referral.linkedinProfile} target="_blank" className="referral-linkedin" rel="noreferrer">View on Linkedin</a>
      </div>
    </div>
  );
}

export default ExperienceSection;
