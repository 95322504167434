function MoneyBag({ width = 19, height = 19, fill = '#5b5b65' }) {
  return (
    <svg width={width} height={height} viewBox="0 0 19 20" fill="none">
      <path d="M13.0799 5.38297H5.38621C5.38621 5.38297 0 9.99837 0 15.3846C0 18.4609 2.31624 20 3.84686 20H14.6189C16.156 20 18.4658 18.4607 18.4658 15.3846C18.4658 9.99837 13.0796 5.38297 13.0796 5.38297H13.0799ZM10.0016 16.0753V17.3071H8.46453L8.46438 16.0794C7.33153 15.8688 6.61991 15.1698 6.56941 14.1549H7.9023C7.96751 14.6876 8.5045 15.0309 9.28147 15.0309C9.99733 15.0309 10.5048 14.6835 10.5048 14.1887C10.5048 13.7676 10.1764 13.5275 9.36783 13.3465L8.51082 13.1634C7.31055 12.9128 6.72098 12.2874 6.72098 11.2831C6.72098 10.2788 7.3906 9.55435 8.45603 9.31642V8.07607H9.99308V9.31833C11.0333 9.54996 11.7261 10.2595 11.7597 11.2133L10.4732 11.2134C10.408 10.6934 9.9236 10.346 9.25827 10.346C8.59293 10.346 8.11278 10.6661 8.11278 11.1672C8.11278 11.5736 8.42651 11.7989 9.19923 11.9715L9.99308 12.14C11.3175 12.4179 11.8881 12.9821 11.8881 14.0119C11.8882 15.113 11.1807 15.85 10.0016 16.0753L10.0016 16.0753Z" fill="black" />
      <path d="M13.0799 0.765349H10.9533C10.514 0.278165 9.88888 0 9.23295 0C8.57701 0 7.95191 0.27818 7.51259 0.765349H5.38597C5.13158 0.766377 4.89424 0.892983 4.75147 1.10346C4.6087 1.31393 4.57904 1.58139 4.67216 1.81816L5.38596 3.84164H13.0797L13.7935 1.81816C13.8866 1.58139 13.8569 1.31394 13.7142 1.10346C13.5714 0.892971 13.3341 0.76638 13.0797 0.765349H13.0799Z" fill="black" />
    </svg>
  );
}

export default MoneyBag;
