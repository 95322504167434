function MoneyBalloons() {
  return (
    <svg width="280" height="150" viewBox="0 0 280 150" fill="none">
      <g filter="url(#filter0_dd_1472_375)">
        <g opacity="0.75">
          <path d="M64 108C88.3006 108 108 88.3006 108 64C108 39.6994 88.3006 20 64 20C39.6994 20 20 39.6994 20 64C20 88.3006 39.6994 108 64 108Z" fill="#13AC83" />
          <path d="M94.6472 52.4418C97.5027 60.7188 96.2128 71.4852 91.6917 79.9364C87.9756 86.8843 82.4628 91.6576 75.8169 93.6179C74.5399 93.9948 73.8093 95.3375 74.1853 96.6175C74.561 97.8977 75.9009 98.6298 77.1779 98.2532C85.1151 95.9117 91.6374 90.2645 95.9403 82.2191C101.067 72.6356 102.523 60.4855 99.2033 50.8628C98.7683 49.6018 97.3959 48.9332 96.1376 49.3691C94.8796 49.805 94.2125 51.1808 94.6472 52.4418Z" fill="white" />
          <path d="M65.0094 96.0616L64.3815 96.19C63.0465 96.4628 62.1768 97.8114 62.4384 99.2024C62.7001 100.593 63.9944 101.5 65.3293 101.227L65.9573 101.099C67.2922 100.826 68.162 99.4773 67.9003 98.0862C67.6386 96.6955 66.3444 95.7889 65.0094 96.0616Z" fill="white" />
          <path d="M63.5968 30.6621C64.9247 30.6051 65.955 29.4816 65.898 28.1528C65.841 26.8238 64.7183 25.7929 63.3904 25.8499C57.5707 26.0999 51.8712 27.5868 46.6701 30.2119L46.533 30.287C41.0548 33.5147 36.3391 37.8904 32.71 43.114C31.9513 44.206 32.2209 45.7069 33.312 46.4662C34.4034 47.2254 35.9032 46.9559 36.6618 45.8636C39.8798 41.2324 44.0565 37.349 48.9076 34.4777C53.4798 32.1821 58.4858 30.8817 63.5968 30.6621Z" fill="white" />
          <path d="M52.5042 56.1595C54.6831 46.7131 62.0821 44.3468 73.2663 48.9763C74.3106 49.4087 74.8067 50.6056 74.3746 51.6499C73.9422 52.6942 72.7453 53.1903 71.701 52.7582C67.0373 50.8278 63.4609 50.3226 61.0074 51.1073C58.7606 51.8259 57.2676 53.7186 56.4922 57.0793L56.4408 57.3103C56.0098 59.3059 56.2335 60.1523 56.8632 60.5791L56.8995 60.6028C57.6409 61.0758 58.8016 61.2679 61.6646 61.4363L62.8309 61.505C63.1957 61.5275 63.4606 61.5462 63.7594 61.5702L63.881 61.5802C66.3111 61.779 68.1202 62.1479 69.7574 62.9197C71.9051 63.9319 73.485 65.5755 74.3931 67.9296C76.5545 73.533 74.5986 79.0103 69.9109 81.7102C65.2507 84.3941 59.186 83.612 54.4054 79.2997C53.566 78.5425 53.4993 77.2485 54.2566 76.4092C54.9962 75.5891 56.249 75.5067 57.0889 76.2101L57.1468 76.2604C64.3353 82.7446 73.6513 77.3792 70.5743 69.4025C69.6311 66.9575 67.6902 66.0179 63.6977 65.672L63.108 65.6255C62.9063 65.6109 62.6923 65.5969 62.4148 65.5802L61.0647 65.5007C57.6701 65.2908 56.2195 65.0238 54.6983 64.0538C52.3437 62.552 51.644 59.888 52.5042 56.1595Z" fill="white" />
          <path d="M64 40.0269C65.1066 40.0269 66.0082 40.9054 66.0453 42.0029L66.0465 42.0734V86.9557C66.0465 88.086 65.1303 89.0022 64 89.0022C62.8934 89.0022 61.9918 88.1237 61.9547 87.0259L61.9535 86.9557V42.0734C61.9535 40.9431 62.8697 40.0269 64 40.0269Z" fill="white" />
        </g>
        <g opacity="0.75">
          <path d="M216 108C240.301 108 260 88.3006 260 64C260 39.6994 240.301 20 216 20C191.699 20 172 39.6994 172 64C172 88.3006 191.699 108 216 108Z" fill="#13AC83" />
          <path d="M246.647 52.4418C249.503 60.7188 248.213 71.4852 243.692 79.9364C239.976 86.8843 234.463 91.6576 227.817 93.6179C226.54 93.9948 225.809 95.3375 226.185 96.6175C226.561 97.8977 227.901 98.6298 229.178 98.2532C237.115 95.9117 243.637 90.2645 247.94 82.2191C253.067 72.6356 254.523 60.4855 251.203 50.8628C250.768 49.6018 249.396 48.9332 248.138 49.3691C246.88 49.805 246.212 51.1808 246.647 52.4418Z" fill="white" />
          <path d="M217.009 96.0616L216.381 96.19C215.047 96.4628 214.177 97.8114 214.438 99.2024C214.7 100.593 215.994 101.5 217.329 101.227L217.957 101.099C219.292 100.826 220.162 99.4773 219.9 98.0862C219.639 96.6955 218.344 95.7889 217.009 96.0616Z" fill="white" />
          <path d="M215.597 30.6621C216.925 30.6051 217.955 29.4816 217.898 28.1528C217.841 26.8238 216.718 25.7929 215.39 25.8499C209.571 26.0999 203.871 27.5868 198.67 30.2119L198.533 30.287C193.055 33.5147 188.339 37.8904 184.71 43.114C183.951 44.206 184.221 45.7069 185.312 46.4662C186.403 47.2254 187.903 46.9559 188.662 45.8636C191.88 41.2324 196.056 37.349 200.908 34.4777C205.48 32.1821 210.486 30.8817 215.597 30.6621Z" fill="white" />
          <path d="M204.504 56.1595C206.683 46.7131 214.082 44.3468 225.266 48.9763C226.311 49.4087 226.807 50.6056 226.375 51.6499C225.942 52.6942 224.745 53.1903 223.701 52.7582C219.037 50.8278 215.461 50.3226 213.007 51.1073C210.761 51.8259 209.268 53.7186 208.492 57.0793L208.441 57.3103C208.01 59.3059 208.233 60.1523 208.863 60.5791L208.899 60.6028C209.641 61.0758 210.802 61.2679 213.665 61.4363L214.831 61.505C215.196 61.5275 215.461 61.5462 215.759 61.5702L215.881 61.5802C218.311 61.779 220.12 62.1479 221.757 62.9197C223.905 63.9319 225.485 65.5755 226.393 67.9296C228.554 73.533 226.599 79.0103 221.911 81.7102C217.251 84.3941 211.186 83.612 206.405 79.2997C205.566 78.5425 205.499 77.2485 206.257 76.4092C206.996 75.5891 208.249 75.5067 209.089 76.2101L209.147 76.2604C216.335 82.7446 225.651 77.3792 222.574 69.4025C221.631 66.9575 219.69 66.0179 215.698 65.672L215.108 65.6255C214.906 65.6109 214.692 65.5969 214.415 65.5802L213.065 65.5007C209.67 65.2908 208.219 65.0238 206.698 64.0538C204.344 62.552 203.644 59.888 204.504 56.1595Z" fill="white" />
          <path d="M216 40.0269C217.107 40.0269 218.008 40.9054 218.045 42.0029L218.047 42.0734V86.9557C218.047 88.086 217.13 89.0022 216 89.0022C214.893 89.0022 213.992 88.1237 213.955 87.0259L213.953 86.9557V42.0734C213.953 40.9431 214.87 40.0269 216 40.0269Z" fill="white" />
        </g>
        <path d="M140 116C168.719 116 192 92.7189 192 64C192 35.2811 168.719 12 140 12C111.281 12 88 35.2811 88 64C88 92.7189 111.281 116 140 116Z" fill="#13AC83" />
        <path d="M176.219 50.3401C179.594 60.122 178.07 72.8459 172.727 82.8337C168.335 91.0449 161.82 96.6861 153.965 99.0028C152.456 99.4482 151.593 101.035 152.037 102.548C152.481 104.061 154.065 104.926 155.574 104.481C164.954 101.714 172.662 95.0397 177.748 85.5315C183.807 74.2055 185.527 59.8463 181.604 48.474C181.09 46.9838 179.468 46.1936 177.981 46.7088C176.494 47.2239 175.706 48.8499 176.219 50.3401Z" fill="white" />
        <path d="M141.193 101.891L140.451 102.043C138.873 102.365 137.845 103.959 138.155 105.603C138.464 107.247 139.993 108.318 141.571 107.996L142.313 107.844C143.891 107.522 144.919 105.928 144.609 104.284C144.3 102.64 142.771 101.569 141.193 101.891Z" fill="white" />
        <path d="M139.524 24.6005C141.093 24.5331 142.31 23.2053 142.243 21.6349C142.176 20.0642 140.849 18.8459 139.28 18.9133C132.402 19.2087 125.666 20.966 119.519 24.0684L119.357 24.1572C112.883 27.9717 107.31 33.143 103.021 39.3163C102.124 40.6068 102.443 42.3807 103.732 43.278C105.022 44.1753 106.795 43.8567 107.691 42.5659C111.494 37.0926 116.43 32.5031 122.163 29.1098C127.567 26.3968 133.483 24.86 139.524 24.6005Z" fill="white" />
        <path d="M126.414 54.7339C128.989 43.5699 137.733 40.7733 150.951 46.2446C152.185 46.7556 152.772 48.1701 152.261 49.4043C151.75 50.6385 150.335 51.2248 149.101 50.7141C143.59 48.4327 139.363 47.8357 136.463 48.763C133.808 49.6123 132.044 51.8492 131.127 55.8208L131.066 56.0938C130.557 58.4523 130.821 59.4526 131.566 59.957L131.609 59.985C132.485 60.544 133.856 60.771 137.24 60.9701L138.618 61.0513C139.05 61.0779 139.363 61.1 139.716 61.1283L139.859 61.14C142.731 61.375 144.869 61.811 146.804 62.7232C149.342 63.9194 151.21 65.8619 152.283 68.6439C154.837 75.2661 152.526 81.7393 146.986 84.9301C141.478 88.102 134.311 87.1777 128.661 82.0814C127.669 81.1865 127.59 79.6572 128.485 78.6653C129.359 77.6961 130.84 77.5987 131.832 78.43L131.901 78.4894C140.396 86.1526 151.406 79.8117 147.77 70.3846C146.655 67.4951 144.361 66.3846 139.643 65.9759L138.946 65.9209C138.707 65.9037 138.455 65.8871 138.127 65.8674L136.531 65.7734C132.519 65.5253 130.805 65.2099 129.007 64.0634C126.224 62.2885 125.398 59.1402 126.414 54.7339Z" fill="white" />
        <path d="M140 35.668C141.308 35.668 142.373 36.7062 142.417 38.0033L142.419 38.0866V91.1293C142.419 92.4651 141.336 93.5479 140 93.5479C138.692 93.5479 137.627 92.5097 137.583 91.2123L137.581 91.1293V38.0866C137.581 36.7508 138.664 35.668 140 35.668Z" fill="white" />
      </g>
      <defs>
        <filter id="filter0_dd_1472_375" x="0" y="0" width="280" height="150" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="14" />
          <feGaussianBlur stdDeviation="10" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1472_375" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="6" />
          <feGaussianBlur stdDeviation="9" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_1472_375" result="effect2_dropShadow_1472_375" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1472_375" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

export default MoneyBalloons;
