import * as RadixAvatar from '@radix-ui/react-avatar';
import classNames from 'classnames';
import './Avatar.scss';

export default function Avatar({
  src, alt, className, fallbackContent = '', size = 'small',
}) {
  return (
    <RadixAvatar.Root className={classNames(`avatar avatar--${size} ${className}`)}>
      <RadixAvatar.Image
        className="avatar-image"
        src={src}
        alt={alt || 'decorative'}
      />
      <RadixAvatar.Fallback className={`avatar--${size}-fallback avatar-fallback`}>
        {fallbackContent.toUpperCase()}
      </RadixAvatar.Fallback>
    </RadixAvatar.Root>
  );
}
