/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-useless-escape */
import DefaultCompanyBanner from 'images/default-company-banner.webp';
import DefaultUserAvatar from 'images/default-user-avatar.webp';

export const userAvatarPlaceholder = DefaultUserAvatar;
export const userLandscapePlaceholder = 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Empire_State_Building_Aerial.JPG/640px-Empire_State_Building_Aerial.JPG';
export const employerLandscapePlaceholder = DefaultCompanyBanner;

export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// export const phoneNumberRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
export const phoneNumberRegex = /^\d{5,}/;
export const linkedinRegex = /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile|company)/gm;

export const referralsTableColumns = ['candidate', 'company', 'referredBy', 'dateReferred', 'status', 'action'];

export const statusOptions = [
  {
    value: 'Accept',
    id: 1,
  },
  {
    value: 'Pass',
    id: 2,
  },
];

export const stateDropdownItems = [
  {
    name: 'Alabama',
    id: 'AL',
  },
  {
    name: 'Alaska',
    id: 'AK',
  },
  {
    name: 'American Samoa',
    id: 'AS',
  },
  {
    name: 'Arizona',
    id: 'AZ',
  },
  {
    name: 'Arkansas',
    id: 'AR',
  },
  {
    name: 'California',
    id: 'CA',
  },
  {
    name: 'Colorado',
    id: 'CO',
  },
  {
    name: 'Connecticut',
    id: 'CT',
  },
  {
    name: 'Delaware',
    id: 'DE',
  },
  {
    name: 'District Of Columbia',
    id: 'DC',
  },
  {
    name: 'Federated States Of Micronesia',
    id: 'FM',
  },
  {
    name: 'Florida',
    id: 'FL',
  },
  {
    name: 'Georgia',
    id: 'GA',
  },
  {
    name: 'Guam',
    id: 'GU',
  },
  {
    name: 'Hawaii',
    id: 'HI',
  },
  {
    name: 'Idaho',
    id: 'ID',
  },
  {
    name: 'Illinois',
    id: 'IL',
  },
  {
    name: 'Indiana',
    id: 'IN',
  },
  {
    name: 'Iowa',
    id: 'IA',
  },
  {
    name: 'Kansas',
    id: 'KS',
  },
  {
    name: 'Kentucky',
    id: 'KY',
  },
  {
    name: 'Louisiana',
    id: 'LA',
  },
  {
    name: 'Maine',
    id: 'ME',
  },
  {
    name: 'Marshall Islands',
    id: 'MH',
  },
  {
    name: 'Maryland',
    id: 'MD',
  },
  {
    name: 'Massachusetts',
    id: 'MA',
  },
  {
    name: 'Michigan',
    id: 'MI',
  },
  {
    name: 'Minnesota',
    id: 'MN',
  },
  {
    name: 'Mississippi',
    id: 'MS',
  },
  {
    name: 'Missouri',
    id: 'MO',
  },
  {
    name: 'Montana',
    id: 'MT',
  },
  {
    name: 'Nebraska',
    id: 'NE',
  },
  {
    name: 'Nevada',
    id: 'NV',
  },
  {
    name: 'New Hampshire',
    id: 'NH',
  },
  {
    name: 'New Jersey',
    id: 'NJ',
  },
  {
    name: 'New Mexico',
    id: 'NM',
  },
  {
    name: 'New York',
    id: 'NY',
  },
  {
    name: 'North Carolina',
    id: 'NC',
  },
  {
    name: 'North Dakota',
    id: 'ND',
  },
  {
    name: 'Northern Mariana Islands',
    id: 'MP',
  },
  {
    name: 'Ohio',
    id: 'OH',
  },
  {
    name: 'Oklahoma',
    id: 'OK',
  },
  {
    name: 'Oregon',
    id: 'OR',
  },
  {
    name: 'Palau',
    id: 'PW',
  },
  {
    name: 'Pennsylvania',
    id: 'PA',
  },
  {
    name: 'Puerto Rico',
    id: 'PR',
  },
  {
    name: 'Rhode Island',
    id: 'RI',
  },
  {
    name: 'South Carolina',
    id: 'SC',
  },
  {
    name: 'South Dakota',
    id: 'SD',
  },
  {
    name: 'Tennessee',
    id: 'TN',
  },
  {
    name: 'Texas',
    id: 'TX',
  },
  {
    name: 'Utah',
    id: 'UT',
  },
  {
    name: 'Vermont',
    id: 'VT',
  },
  {
    name: 'Virgin Islands',
    id: 'VI',
  },
  {
    name: 'Virginia',
    id: 'VA',
  },
  {
    name: 'Washington',
    id: 'WA',
  },
  {
    name: 'West Virginia',
    id: 'WV',
  },
  {
    name: 'Wisconsin',
    id: 'WI',
  },
  {
    name: 'Wyoming',
    id: 'WY',
  },
];

export const DATE_FORMAT = 'YYYY-MM-DD';

export const faqsReferrer = [
  {
    id: 'how',
    title: 'How does Refer work?',
    content: <p>Refer is a platform for companies to create incentives for their trusted networks (you) to refer top talent to fill open roles. Referrals will only be shared with the employer after the candidate confirms their&nbsp;interest&nbsp;in&nbsp;the&nbsp;position.</p>,
  },
  {
    id: 'when',
    title: 'How and when do I get paid?',
    content: <p>Payment disbursement is based on the specific company. They have the option to choose between&nbsp;30, 60, and 90 days after hire.</p>,
  },
  {
    id: 'good',
    title: 'What makes a good referrer?',
    content: <p>Someone who understands the requirements and qualifications of the open role, the cultural fit of the company, and of course knows the person&nbsp;they&nbsp;are&nbsp;referring&nbsp;😀</p>,
  },
  {
    id: 'myself',
    title: 'Can I refer myself for a job?',
    content: <p>No, you can not refer&nbsp;yourself to a job.</p>,
  },
  {
    id: 'multiple',
    title: 'Am I able to submit multiple people for the same job?',
    content: <p>Yes, we welcome multiple submissions for any open role. Be mindful you should keep your referrals high quality to have the best chance of helping&nbsp;the company fill the position.</p>,
  },
  {
    id: 'same',
    title: 'Can I submit the same person to multiple jobs?',
    content: <p>Yes, but we encourage you to only submit people that will be a good fit&nbsp;for the open role.</p>,
  },
  {
    id: 'status',
    title: 'How do I track the status of my referrals?',
    content: <p>In your Refer profile you’ll be able to see how much money you have earned, how much money you are owed for successful referrals, as well as the status of each referral you have made. The status will be updated as employers advance,&nbsp;reject or hire referrals.</p>,
  },
];

export const faqsEmployer = [
  {
    id: 'how',
    title: 'How does Refer work?',
    content: <p>Refer is a platform for companies to hire faster by creating incentives for their external networks. Referrals can come from anyone, but customers often prioritize their existing employees, company alumni,&nbsp;partners,&nbsp;customers,&nbsp;and&nbsp;friends.</p>,
  },
  {
    id: 'external',
    title: 'Why should my company accept external referrals?',
    content: <p>Referrals are usually a company’s best source of talent – helping you attract & onboard higher quality candidates, more efficiently and with better long-term retention. But most referral programs today are only accessible to current employees when quality referrals can (and do!) come from many places. In today’s increasingly competitive talent market, Refer helps hiring managers and talent professionals activate their trusted broader networks to connect with top-tier candidates and reduce time-to-fill for a fraction of the cost of traditional recruiting firms.</p>,
  },
  {
    id: 'type',
    title: 'What type of companies do you work with?',
    content: <p>While our initial focus is on growth stage startups, any company is welcome&nbsp;to&nbsp;sign&nbsp;up&nbsp;for&nbsp;our&nbsp;platform.</p>,
  },
  {
    id: 'bonus',
    title: 'How much should a bonus be?',
    content: <p>We generally recommend matching the existing bonuses you have for your internal employee referrals. If you don’t yet have an internal referral program, amounts can typically range from $1,500-$15,000, depending on priority and seniority of the role. Feel free to contact us for additional guidance or comps.</p>,
  },
  {
    id: 'cost',
    title: 'How much does it cost?',
    content: <p>It’s always free and no-risk to get started on our platform. Once you successfully hire and onboard a candidate via one of our referrals, we automate bounty payment directly to the referrer as well as a 20% platform & service fee.</p>,
  },
  {
    id: 'start',
    title: 'How do I get started?',
    content: <p>Creating your external referral bonus program only takes a few minutes. We have you connect with LinkedIn to verify your company, and then sync your ATS to import all of your existing jobs. You may also manually create&nbsp;a new job with an attached bounty.</p>,
  },
];
