import './Card.scss';

function CardLoader() {
  return (
    <div className="card-loader">
      <span className="title" />
      <span className="description" />
      <span className="description description-2" />
      <div className="row flex-row">
        <span className="row-avatar" />
        <span className="row-description description" />
      </div>
      <div className="row flex-row">
        <span className="row-avatar" />
        <span className="row-description-2 description" />
      </div>
    </div>
  );
}

export default CardLoader;
