function User() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M11.9999 3.16749C13.4564 3.16749 14.7751 3.75803 15.7292 4.71215L15.774 4.76067C16.7014 5.71203 17.2735 7.01163 17.2735 8.44113C17.2735 9.89766 16.6833 11.216 15.7292 12.1701C14.7751 13.1245 13.4564 13.7145 11.9999 13.7145C10.5471 13.7145 9.2278 13.1233 8.27245 12.1683C7.31648 11.216 6.72626 9.89766 6.72626 8.44113C6.72626 6.98891 7.3174 5.67088 8.27061 4.71553L8.27644 4.71031C9.23118 3.75772 10.5489 3.16749 11.9999 3.16749V3.16749ZM11.9999 0.299896C15.2302 0.299896 18.1564 1.60932 20.2732 3.7267C22.3906 5.84347 23.7 8.76972 23.7 12C23.7 15.2303 22.3906 18.1565 20.2732 20.2733C18.1564 22.3907 15.2302 23.7001 11.9999 23.7001C8.76963 23.7001 5.84338 22.3907 3.72661 20.2733C1.60923 18.1565 0.299805 15.2303 0.299805 12C0.299805 8.76972 1.60923 5.84347 3.72661 3.7267C5.84338 1.60932 8.76963 0.299896 11.9999 0.299896V0.299896ZM19.4582 4.54172C17.5496 2.63285 14.9123 1.45209 11.9999 1.45209C9.08746 1.45209 6.45018 2.63285 4.54163 4.54172C2.63276 6.45028 1.452 9.08756 1.452 12C1.452 14.2012 2.12698 16.2452 3.28041 17.9363C3.55833 17.3922 3.89459 16.8824 4.2806 16.4153C5.48685 14.9563 7.18413 13.9119 9.11418 13.5419C9.2711 13.513 9.43816 13.5302 9.59293 13.6027C9.96359 13.7753 10.3573 13.9104 10.7654 14.001C11.1526 14.087 11.5669 14.1321 11.9999 14.1321C12.4329 14.1321 12.8472 14.087 13.2344 14.001C13.6305 13.9132 14.0126 13.7836 14.3734 13.6183C14.5251 13.5385 14.7044 13.5072 14.8856 13.5419C16.8157 13.9119 18.5129 14.9563 19.7192 16.4153C20.1052 16.8824 20.4415 17.3922 20.7194 17.9363C21.8725 16.2455 22.5478 14.2018 22.5478 12C22.5478 9.08756 21.367 6.45028 19.4582 4.54172V4.54172Z" fill="#150828" />
    </svg>

  );
}

export default User;
