import { createSlice } from '@reduxjs/toolkit';

// Reducer for all organizations (ie; non user)
const organizations = createSlice({
  name: 'organizations',
  initialState: {
    isLoading: null,
    data: {},
  },
  reducers: {
    organizationRequest(state) {
      state.isLoading = true;
    },
    organizationSuccess(state, { payload }) {
      state.isLoading = false;
      state.data[payload.data.slug] = payload.data;
    },
    organizationFailure(state) {
      state.isLoading = false;
    },
    createOrganizationRequest(state) {
      state.isLoading = true;
    },
    createOrganizationSuccess(state) {
      state.isLoading = false;
    },
    createOrganizationFailure(state) {
      state.isLoading = false;
    },
  },
});

const { actions, reducer } = organizations;

export const {
  organizationRequest,
  organizationSuccess,
  organizationFailure,
  createOrganizationRequest,
  createOrganizationSuccess,
  createOrganizationFailure,
} = actions;

export default reducer;
