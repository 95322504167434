import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Card from 'components/Card';
import Input from 'components/Input';
import { integrateAts } from 'actions/ats';
import WorkableAtsSteps from './WorkableAtsSteps';

function WorkableSetup() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const [atsId, setAtsId] = useState(null);

  const inImport = () => {
    dispatch(integrateAts({ atsId, endpoint: 'workable/register/v3/api' }))
      .then(({ status }) => {
        if (status === 201) navigate(`/c/${params.companySlug}/jobs`);
      });
  };

  return (
    <>
      <Helmet title="Workable setup | Refer" />
      <Card
        title="Workable setup"
        subtitle="Follow the steps below to sync with your ATS and import your open roles."
        style={{ overflow: 'scroll' }}
        buttons={[
          {
            id: 'import',
            text: 'Import',
            variant: !atsId ? 'disabled' : 'default',
            onClick: inImport,
          },
        ]}
      >
        <Input
          id="ats-id"
          placeholder="API KEY"
          onChange={(id) => setAtsId(id)}
          value={atsId}
        />
        <WorkableAtsSteps />
      </Card>
    </>
  );
}

export default WorkableSetup;
