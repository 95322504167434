import PropTypes from 'prop-types';
import Button from 'components/Button';
import Icon from 'components/Icon';
import './ModalContent.scss';

function ModalContent({
  children,
  title,
  subtitle,
  primaryButton = 'Close',
  secondaryButton,
  secondaryButtonAction,
  primaryButtonAction,
  onClose,
  canClose = true,
  validationError,
  noButtons,
  wrappedButtons = true,
}) {
  const onPrimaryClick = () => {
    if (canClose) {
      if (primaryButtonAction)primaryButtonAction();

      onClose();
    }
    if (!canClose && validationError) {
      validationError();
    }
  };
  const onSecondaryClick = () => {
    if (secondaryButtonAction) {
      secondaryButtonAction();
    } else {
      onClose();
    }
  };
  return (
    <form onSubmit={onPrimaryClick} className="modal-content">
      <div className="modal-content__header">
        <div className="flex-row justify-between">
          {title && <h2 className="modal-content__header-title">{title}</h2>}
          <span>
            <Icon name="close" onClick={onClose} />
          </span>
        </div>
        { subtitle && <p className="subtitle">{ subtitle }</p> }
      </div>
      <div className="modal-content__content">
        {children}
      </div>
      {
        !wrappedButtons && (
          <div className="flex-row mt-20 unwrapped-buttons">
            { secondaryButton && (
            <Button
              variant="reverse-secondary"
              onClick={onSecondaryClick}
              text={secondaryButton}
            />
            )}
            <Button onClick={onPrimaryClick} text={primaryButton} />
          </div>
        )
      }
      { !noButtons && wrappedButtons && (
        <div className="modal-content__actions">
          { secondaryButton && (
            <Button
              variant="reverse-secondary"
              onClick={onSecondaryClick}
              text={secondaryButton}
            />
          )}
          <Button type="submit" text={primaryButton} />
        </div>
      )}
    </form>
  );
}

export default ModalContent;

ModalContent.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  primaryButton: PropTypes.string,
  secondaryButton: PropTypes.string,
  secondaryButtonAction: PropTypes.func,
  primaryButtonAction: PropTypes.func,
  onClose: PropTypes.func,
};
