import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Card from 'components/Card';
import List from 'components/List';
import { paymentOpions } from './util';

function EmployerPaymentInfo() {
  const navigate = useNavigate();
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [searchParams] = useSearchParams();
  const bountyId = searchParams.get('bountyId');

  const onConnect = () => {
  // 1.Send payment method
  // 2.Redirect to share page (with bounty id)
    // if (bountyId) {
    //   navigate(`/signup/bounty-success?bountyId=${bountyId}`);
    // } else {
    //   navigate(`/c/<slug>/${bountyId}`);
    // }
  };

  const onBack = () => {
    navigate('');
  };

  return (
    <>
      <Helmet title="Payment method | Refer" />
      <Card
        title="Payment details"
        subtitle="In order to create a bounty we need to have a form of payment on file. We will only deduct funds once a candidate has been hired."
        buttons={[
          bountyId && {
            id: 'back',
            text: 'Back',
            variant: 'reverse-secondary',
            onClick: onBack,
          },
          {
            id: 'payment-method',
            text: 'Connect',
            variant: !paymentMethod ? 'disabled' : 'default',
            onClick: onConnect,
          },
        ]}
      >
        <List items={paymentOpions} onSelectItem={setPaymentMethod} />
      </Card>
    </>
  );
}

export default EmployerPaymentInfo;
