/* eslint-disable no-unused-vars */
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import LinkedinSignup from 'components/LinkedinSignup';
import { Helmet } from 'react-helmet-async';
import { trackEvent } from 'util/tracking';

function Step3Card() {
  const params = useParams();
  const candidateReferralForm = useSelector((state) => state.candidateReferralForm);
  const { requestedReferralUuid, requestedReferral } = useSelector((state) => state.referral);

  // Save referral details, user will need them when after login.
  const preLoginAction = () => {
    trackEvent('Bounty: Connect Linkedin');

    localStorage.setItem('referralInfo', JSON.stringify(candidateReferralForm.candidate));
    localStorage.setItem('referralSlug', params.slug || params.bountySlug);
    localStorage.setItem('companySlug', params.companySlug);
    if (requestedReferralUuid) {
      sessionStorage.setItem('requestedReferralUuid', requestedReferralUuid);
      sessionStorage.setItem('requestedReferral', JSON.stringify(requestedReferral));
    }
  };

  return (
    <>
      <Helmet prioritizeSeoTags title="Continue with Linkedin | Refer" />
      <LinkedinSignup variant="black" preLoginAction={preLoginAction} text="Continue with Linkedin" />
    </>
  );
}

export default Step3Card;
