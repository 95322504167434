import React, { useEffect } from 'react';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import { Toaster } from 'react-hot-toast';
import { isEmpty } from 'util';
import getRoutes from 'config/routes';

import toast from 'components/Alerts';

import Navbar from 'components/Navbar';
import ScrollToTop from 'components/ScrollToTop';
import Alert from 'components/Alert';
import classNames from 'classnames';

function AppContainer() {
  const { alert, errors } = useSelector((state) => state.alerts);
  const { isMobileMenuOpen } = useSelector((state) => state.ui);

  useEffect(() => {
    if (errors) {
      toast({ subtitle: errors });
    }
  }, [errors]);

  return (
    <>
      <ScrollToTop />
      <div id="app">
        <Navbar />
        <div className={classNames(isMobileMenuOpen && 'app-wrapper--disable-scroll', 'app-wrapper')}>
          <div className="app-wrapper-container">
            <Modal
              ariaHideApp={false}
              isOpen={alert && !isEmpty(alert)}
              className="modal-small"
            >
              <Alert alert={alert} />
            </Modal>
            <Toaster
              toastOptions={{
                className: 'toast',
                duration: 5000,
                position: 'bottom-left',
              }}
            />
            { getRoutes() }
          </div>
        </div>
      </div>
    </>

  );
}

export default AppContainer;
