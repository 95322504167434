/* eslint-disable react/jsx-props-no-spreading */

import classNames from 'classnames';
import TableHeader from './TableHeader';
import TableList from './TableList';
import './Table.scss';

function Table(props) {
  const emptyTable = !props.items?.length;
  return (
    <div className={classNames('table table-container', emptyTable && 'table--empty')}>
      <TableHeader />
      <TableList emptyTable={emptyTable} {...props} />
    </div>
  );
}

export default Table;
