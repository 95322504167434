import React, { useRef, useEffect, useState } from 'react';
import classNames from 'classnames';
import FocusLock from 'react-focus-lock';
import Portal from 'components/Portal';
import Icon from 'components/Icon';
import './SideBar.scss';

function SideBar({ children, openSidebar }) {
  const wrapperRef = useRef(null);
  const [closeSidebarAnimation, setCloseSidebarAnimation] = useState(false);

  function setEventListener(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (event.target.parentElement.className === 'balloon-dropdown') return;
        if (ref.current && !ref.current.contains(event.target)) {
          closeSidebar();
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  // Set event listener for outside click
  setEventListener(wrapperRef);

  const closeSidebar = () => {
    setCloseSidebarAnimation(true);
    // We wait for the dissmis animation to be done (.3s) before closing sidebar
    setTimeout(() => {
      openSidebar(false);
    }, 300);
  };

  return (
    <Portal>
      <FocusLock>
        <div className={classNames('sidebar-overlay', closeSidebarAnimation && 'sidebar-overlay--close')}>
          <div className={classNames('sidebar', closeSidebarAnimation && 'sidebar--close')} ref={wrapperRef}>
            <Icon name="close" styleProps={{ width: 18, height: 18 }} onClick={closeSidebar} />
            { children }
          </div>
        </div>
      </FocusLock>
    </Portal>
  );
}

export default SideBar;
