import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'react-modal';
import { Helmet } from 'react-helmet-async';
import { editForm, initializeForm } from 'store/reducers/createBountyForm';
import { parseMoneyInt, prettifyLocations } from 'util';
import Card from 'components/Card';
import Input from 'components/Input';
import ListItem from 'components/ListItem';
import ModalContent from 'components/ModalContent';
import Dropdown from 'components/Dropdown';
import { trackEvent } from 'util/tracking';
import { bountySubtitle } from './util';
import JobsList from './JobsList';

function EmployerBounty() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const { jobId, companySlug } = params;
  const { activeJobs } = useSelector((state) => state.jobs);
  const bountyForm = useSelector((state) => state.createBountyForm);
  const { experience } = useSelector((state) => state.main.bounty.referral);
  const [isModalOpen, openModal] = useState(false);
  const [bountyJob, setBountyJob] = useState(null);
  const {
    job,
    referralAmount,
    inRole,
  } = bountyForm || {};
  const canContinue = job && referralAmount?.toString().length > 0 && inRole;

  useEffect(() => {
    const existingJob = activeJobs.filter((el) => el.id === jobId)[0];
    if (existingJob && !inRole) {
      dispatch(initializeForm({ job: existingJob }));
    }
  }, [activeJobs]);

  const onChangeBountyJob = () => {
    navigate(`/c/${companySlug}/bounty-details/${bountyJob.id}`);
    dispatch(initializeForm({ job: bountyJob }));
    trackEvent('Employer: candidate ranking');
  };

  return (
    <>
      <Helmet title="The bounty | Refer" />
      <Card
        title="The bounty"
        subtitle={bountySubtitle}
        buttons={[
          {
            id: 'cancel',
            text: 'Cancel',
            variant: 'reverse-secondary',
            to: `/c/${companySlug}`,
          },
          {
            id: 'next',
            text: 'Next',
            variant: !canContinue ? 'disabled' : 'default',
            to: 'ranking',
          },
        ]}
      >
        <div>
          <Modal
            ariaHideApp={false}
            className="modal-big"
            isOpen={isModalOpen}
            onRequestClose={() => openModal(false)}
            shouldCloseOnOverlayClick
          >
            <ModalContent
              title="Your jobs"
              primaryButton="Save"
              secondaryButton="Cancel"
              onClose={() => openModal(false)}
              primaryButtonAction={onChangeBountyJob}
            >
              <JobsList
                items={
                  activeJobs.map((job) => ({ ...job, subtitle: prettifyLocations(job.locations) }))
                  }
                onSelectJob={(job) => setBountyJob(job)}
              />
            </ModalContent>
          </Modal>
          <ListItem
            variation="medium"
            item={{ ...job, subtitle: prettifyLocations(job?.locations) }}
            showEditIcon
            onClick={() => openModal(true)}
          />
          <Input
            id="bounty-amount"
            placeholder="Referral amount"
            isMoney
            onChange={(value) => {
              if (!referralAmount) trackEvent('Employer: referral amount');
              dispatch(editForm({ key: 'referralAmount', value }));
            }}
            value={parseMoneyInt(referralAmount)}
            allowEmptyValue
          />
          <Dropdown
            placeholder="How long does the referral need to be in role?"
            items={experience}
            onClick={(value) => {
              if (!inRole) trackEvent('Employer: role duration');
              dispatch(editForm({ key: 'inRole', value }));
            }}
            value={inRole?.value || inRole?.name}
          />
        </div>
      </Card>
    </>
  );
}

export default EmployerBounty;
