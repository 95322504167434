/* eslint-disable no-restricted-globals */
import { contact } from 'actions';
import toast from 'components/Alerts';
import Button from 'components/Button';
import Input from 'components/Input';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { isValidEmail } from 'util';

export default function ContactForm({ openModal, hideTitle }) {
  const dispatch = useDispatch();
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [state, setState] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    message: '',
  });
  const canSubmit = state.firstName && state.lastName && state.email;

  const onSubmit = (e) => {
    e.preventDefault();

    if (!isValidEmail(state.email)) {
      setEmailError('Please input a valid email');
    }
    if (state.phoneNumber && isNaN(state.phoneNumber)) {
      setPhoneError('Please input a valid phoneNumber');
      return;
    }

    if (isValidEmail(state.email)) {
      dispatch(contact(state)).then((res) => {
        if (res.status === 201) {
          toast({ subtitle: 'Your message has been sent' });
          setState({
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            message: '',
          });
          openModal(false);
        }
      });
    }
  };

  return (
    <>
      { !hideTitle && (
        <>
          <h2>Contact us</h2>
          <p className="f14 mt-5">Interested in learning more or have any questions? Send us a brief note and we&apos;ll get back to you within ~24 hours.</p>
        </>
      )}
      <form className="mb-15" onSubmit={onSubmit}>
        <div className="flex-row mobile-container mt-20">
          <Input
            className="w-100 mr-10"
            id="name"
            placeholder="First name"
            onChange={(value) => setState({ ...state, firstName: value })}
            value={state.firstName}
          />
          <Input
            className="w-100"
            id="lastName"
            placeholder="Last name"
            onChange={(value) => setState({ ...state, lastName: value })}
            value={state.lastName}
          />
        </div>
        <Input
          id="email"
          placeholder={emailError || 'What is your email?'}
          onChange={(value) => {
            setEmailError('');
            setState({ ...state, email: value });
          }}
          error={!!emailError}
          value={state.email}
        />
        <Input
          id="phoneNumber"
          placeholder="What is your phone number? (optional)"
          onChange={(value) => {
            setState({ ...state, phoneNumber: value });
            setPhoneError('');
          }}
          error={!!phoneError}
          value={state.phoneNumber}
        />
        <Input
          id="message"
          inputType="textarea"
          className="message-textarea"
          placeholder="What can we help you with? (optional)"
          onChange={(value) => setState({ ...state, message: value })}
          value={state.message}
        />
        <Button type="submit" text="Submit" className="mb-5" variant={canSubmit ? 'default' : 'disabled'} />
      </form>
    </>
  );
}
