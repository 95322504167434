function ReferCheckmark() {
  return (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none">
      <rect x="0.059082" y="33.9411" width="16" height="36" rx="8" transform="rotate(-45 0.059082 33.9411)" fill="#1BA00B" />
      <rect x="48.1421" y="14.1422" width="16" height="48" rx="8" transform="rotate(45 48.1421 14.1422)" fill="#B4F0AD" />
    </svg>

  );
}

export default ReferCheckmark;
