import { prettifyLocations } from 'util';

const makeList = (jobs) => {
  if (!jobs || !jobs.length) return [];

  return jobs.map((job) => {
    const {
      locations = [], id, uuid, postId,
    } = job;
    return {
      ...job,
      id: postId || id || uuid,
      subtitle: prettifyLocations(locations),
    };
  });
};

export default makeList;
