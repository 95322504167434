import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import LogoReverse from 'images/logo-refer-reverse.png';
import Logo from 'images/logo-refer.png';
import Icon from 'components/Icon';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { setMobileMenuStatus } from 'store/reducers/ui';
import { useEffect } from 'react';
import { trackEvent } from 'util/tracking';
import ReferrerSection from './ReferrerSection';
import LoggedOutSection from './LoggedOutSection';
import EmployerSection from './EmployerSection';
import './Navbar.scss';

export default function Navbar() {
  const location = useLocation();
  const dispatch = useDispatch();
  const { isMobileMenuOpen } = useSelector((state) => state.ui);
  const path = location.pathname;
  const { width } = useWindowDimensions();
  const isMobile = width < 450;
  const isHomePage = path === '/' || path === '/employer';
  const isReferHomePage = path === '/refer';
  const user = useSelector((state) => state.user);
  const isLoggedIn = user.token;

  useEffect(() => {
    if (isMobileMenuOpen) document.body.style.overflow = 'hidden';
    if (!isMobileMenuOpen)document.body.style.overflow = 'unset';
  }, [isMobileMenuOpen]);

  useEffect(() => {
    if (!isMobile) {
      dispatch(setMobileMenuStatus({ status: false }));
    }
  }, [isMobile]);

  const navbarItems = () => {
    if (isLoggedIn) {
      return user.isEmployer
        ? <EmployerSection user={user} isMobile={isMobile} />
        : <ReferrerSection user={user} isMobile={isMobile} />;
    }
    return <LoggedOutSection />;
  };

  const displayLogo = () => {
    if (isHomePage) return LogoReverse;
    return Logo;
  };

  const closeMobileMenu = () => {
    dispatch(setMobileMenuStatus({ status: !isMobileMenuOpen }));
  };

  const onClickLogo = () => {
    trackEvent('Nav: Referrer landing page');
    dispatch(setMobileMenuStatus({ status: false }));
  };

  return (
    <div>
      <div
        id="navbar"
        className={classNames(
          'navbar',
          isHomePage ? !isMobileMenuOpen ? 'navbar--homepage' : 'navbar--menuOpen' : '',
          isReferHomePage && 'navbar--homepage-refer',
        )}
      >
        <Link className="logo" to="/" onClick={onClickLogo}>
          <img src={displayLogo()} alt="refer-logo" />
        </Link>
        <div className="desktop-items">
          { navbarItems() }
        </div>
        <div className="mobile-items">
          <Icon
            name="burger"
            fill={isHomePage ? 'white' : 'black'}
            onClick={closeMobileMenu}
          />
        </div>
      </div>
      {
        isMobile && (
          <div className={classNames(
            'mobile-items__container',
            isMobileMenuOpen ? 'mobile-items__container--open' : '',
            isHomePage && 'mobile-items__container--home',
          )}
          >
            { navbarItems() }
          </div>
        )
      }
    </div>
  );
}
