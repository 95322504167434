import Alert from 'components/Alert';
import ReactModal from 'react-modal';
import { useNavigate, useParams } from 'react-router-dom';

export default function CompleteIntegrationModal({
  isOpen, onClose,
}) {
  const navigate = useNavigate();
  const params = useParams();
  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={() => onClose(false)}
      shouldCloseOnOverlayClick
    >
      <Alert
        alert={{
          title: 'Complete integration',
          subtitle: 'You must add a Site Admin User ID to complete the Refer integration, this will allow us to send candidate information to Greenhouse.',
        }}
        buttonPrimary="Finish setup"
        buttonPrimaryAction={() => {
          onClose(false);
          // handleAction(syncApplication);
          navigate(`/c/${params.companySlug}/greenhouse`);
        }}
        buttonSecondary="Not now"
        onClose={() => onClose(false)}
      />
    </ReactModal>
  );
}
