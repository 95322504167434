/* eslint-disable max-len */
import Workable1 from 'images/workable/workable-1.webp';
import Workable2 from 'images/workable/workable-2.webp';
import Workable3 from 'images/workable/workable-3.webp';

import 'pages/EmployerSetup/EmployerSetup.scss';

function WorkableAtsSteps() {
  return (
    <div className="setup-steps">
      <h3 className="mt-35">Go to Settings</h3>
      <p>
        Open the drop-down menu on the top right of your screen and
        select
        {' '}
        <span className="bold italic">“Integrations”</span>
        .
      </p>
      <img className="mt-15 workable-1" src={Workable1} alt="workable step 1" />
      <h3 className="mt-20">Generate token</h3>
      <p>
        Now click on the
        {' '}
        <span className="bold italic">“Generate new token”</span>
        {' '}
        button and then copy the new API Key into the form field at the top of the screen.
      </p>
      <img className="mt-15 mb-10 setup-intructions-img" src={Workable2} alt="workable step 2" />
      <h3 className="mt-20">Subdomain</h3>
      <p>
        You can find your subdomain in the company profile settings page:
      </p>
      <img className="mt-15 mb-10 setup-intructions-img" src={Workable3} alt="workable step 3" />
    </div>

  );
}

export default WorkableAtsSteps;
