import { syncApplication } from 'actions/referrals';
import Alert from 'components/Alert';
import ReactModal from 'react-modal';

export default function AcceptCandidateModal({
  isOpen, onClose, handleAction,
}) {
  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={() => onClose(false)}
      shouldCloseOnOverlayClick
    >
      <Alert
        alert={{
          title: 'Accept candidate',
          subtitle: 'When you accept a candidate, we send their details to your Greenhouse account.',
        }}
        buttonPrimary="Accept"
        buttonPrimaryAction={() => {
          onClose(false);
          localStorage.setItem('educationCounter', 1);
          handleAction(syncApplication, true);
        }}
        hideSecondaryButton
        onClose={() => onClose(false)}
      />
    </ReactModal>
  );
}
