/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import {
  editUser, loadUser, loadUserReferrals, loadUserRewards,
} from 'actions/user';
import { trackEvent } from 'util/tracking';
import { loadOpenJobs } from 'actions/jobs';
import Card from 'components/Card';
import SideBar from 'components/SideBar';
import ReferralSidebarContent from 'pages/EmployerReferrals/ReferralSidebarContent';
import ProfileMenu from 'pages/EmployerProfile/components/ProfileMenu';
import './ReferrerProfile.scss';
import ReactModal from 'react-modal';
import ModalContent from 'components/ModalContent';
import EditProfileDetails from 'pages/EmployerProfile/components/EditProfileDetails';
import { userAvatarPlaceholder, userLandscapePlaceholder } from 'util/constants';
import { initializeForm } from 'store/reducers/editUserForm';
import { formatWebsite } from 'util';
import {
  companyCardSubtitle, getOpenJobsList, referralsList, referralsCardSubtitle,
} from './util';
import ReferralContent from './ReferralContent';
import Rewards from './Rewards';

function ReferrerProfile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, openModal] = useState(false);
  const [jobsExpanded, expandOpenJobs] = useState(false);
  const [isSideBarOpen, openSideBar] = useState(false);
  const user = useSelector((state) => state.user);
  const {
    firstName, lastName, referrals: userReferrals, organizations, links, headline, rewards,
  } = user;
  const editUserForm = useSelector((state) => state.editUserForm);
  const { data: referrals = [], isLoading } = userReferrals;
  const { shuffledOpenJobs, isLoading: isLoadingOpenJobs } = useSelector((state) => state.openJobs);
  const openJobsList = getOpenJobsList(shuffledOpenJobs, navigate);
  const showOpenJobs = openJobsList.length > 0;
  let amountMade = 0;
  referrals.forEach((el) => amountMade += (el.job.bounty?.referral?.amount || 0));
  const referralsWithBounty = referrals.filter((el) => el.job.bounty);

  const [itemIndex, setItemIndex] = useState();

  useEffect(() => {
    dispatch(loadUserReferrals());
    dispatch(loadOpenJobs());
    dispatch(loadUserRewards(user.uuid));
  }, []);

  const onClickUser = (itemIndex) => {
    trackEvent('Profile: referred candidate');
    setItemIndex(itemIndex);
    openSideBar(true);
  };

  const onViewMore = () => {
    trackEvent('Profile: View more jobs');
    expandOpenJobs(true);
  };

  const editUserProfile = () => {
    dispatch(editUser({
      linkedin: formatWebsite(editUserForm.linkedinProfile),
      website: editUserForm.website ? formatWebsite(editUserForm.website) : null,
      headline: editUserForm.headline,
    }, user.uuid))
      .then((res) => {
        if (res.status === 200) dispatch(loadUser());
      });
  };

  const onOpenEditModal = () => {
    dispatch(initializeForm({
      linkedinProfile: links?.linkedin?.profile,
      website: links?.website,
      headline,
    }));
    openModal(true);
  };

  let title = '';
  const getTitle = () => {
    const { count } = rewards.data.referrals || {};
    if (count > 4) return title = 'All set!';
    if (count > 0 && count < 5) return title = 'Keep it going';
    return title = 'Get started now';
  };

  getTitle();

  const getDescription = () => {
    const { count } = rewards.data?.referrals || {};
    const { localized } = rewards.data?.reward?.config || {};
    if (count === 1) return `Four more confirmed referrals to earn ${localized}`;
    if (count === 2) return `Three more confirmed referrals to earn ${localized}`;
    if (count === 3) return `Two more confirmed referrals to earn ${localized}`;
    if (count === 4) return `One more confirmed referrals to earn ${localized}`;
    if (count > 4) return 'Thanks for making 5 referrals - claim your reward now.';
    return `Earn ${localized} when your first 5 referrals confirm interest`;
  };

  return (
    <>
      <Helmet title="Your profile | Refer" />
      <div className="employer-profile-container">
        { organizations.length > 0 && <ProfileMenu /> }
        <Card
          title={`${firstName} ${lastName}`}
          subtitle={() => companyCardSubtitle({ linkedin: links?.linkedin?.profile, headline: headline?.current, website: links?.website })}
          landscapeImage={user.links?.image?.header || userLandscapePlaceholder}
          displayAvatar={user.links?.image?.user || user.links?.linkedin?.image || userAvatarPlaceholder}
          variant="small"
          editAction={onOpenEditModal}
        />
        { (rewards.data.punchcard?.active || rewards.data.reward) && (
          <Card
            title={title}
            subtitle={getDescription}
            variant="small"
            isLoading={isLoading}
          >
            <Rewards data={rewards.data} />
          </Card>
        )}
        <Card
          title="Your referrals"
          subtitle={() => referralsCardSubtitle(amountMade)}
          variant="small"
          isLoading={isLoading}
        >
          { referralsList(referralsWithBounty, onClickUser) }
        </Card>
        { showOpenJobs && (
        <Card
          title="Open roles"
          subtitle="A selection of roles across Refer"
          variant="dynamic"
          isLoading={isLoadingOpenJobs}
        >
          { !jobsExpanded ? openJobsList.slice(0, 10) : openJobsList }
          { openJobsList.length > 10
          && !jobsExpanded
          && <p onClick={onViewMore} className="green-text pointer">View more</p>}
        </Card>
        )}
        { isSideBarOpen && (
        <SideBar openSidebar={(status) => openSideBar(status)}>
          <ReferralSidebarContent items={referralsWithBounty} itemIndex={itemIndex} component={ReferralContent} />
        </SideBar>
        )}
        <ReactModal
          className="profile-modal"
          ariaHideApp={false}
          isOpen={isModalOpen}
          onRequestClose={() => openModal(false)}
          shouldCloseOnOverlayClick
        >
          <ModalContent
            title="Edit"
            secondaryButton="Cancel"
            primaryButton="Save"
            onClose={() => openModal(false)}
            primaryButtonAction={editUserProfile}
          >
            <EditProfileDetails user={user} editUserForm={editUserForm} />
          </ModalContent>
        </ReactModal>
      </div>
    </>
  );
}

export default ReferrerProfile;
