function ExperienceSectionRow({ item }) {
  const avatar = item.company?.logo || item.institution?.logo;
  return (
    <div className="flex-row experience-row">
      <span className="company-image">
        { avatar && <img src={avatar} alt="company-logo" /> }
      </span>
      <div className="experience-row__content">
        <p className="bold">{item.title || item.degree }</p>
        <p>{item.company?.name || item.institution?.name}</p>
        { item.when && (
        <p>{`${item.when?.started} ${item.when?.ended ? `- ${item.when?.ended}` : '- Present'}`}</p>
        )}
      </div>
    </div>
  );
}

export default ExperienceSectionRow;
