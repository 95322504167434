/* eslint-disable max-len */
import Lever1 from 'images/lever/lever-1.webp';
import Lever2 from 'images/lever/lever-2.webp';
import Lever3 from 'images/lever/lever-3.webp';

import 'pages/EmployerSetup/EmployerSetup.scss';

function LeverAtsSteps() {
  return (
    <div className="setup-steps">
      <h3 className="mt-35">Go to Settings</h3>
      <p>
        Navigate to Settings
        {' '}
        {'>'}
        {' '}
        Integrations and API
        {' '}
        {'>'}
        {' '}
        API Credentials. Click the
        {' '}
        <span className="bold italic">Generate New Key button</span>
      </p>
      <img className="mt-15 mb-10 setup-intructions-img" src={Lever1} alt="lever step 1" />
      <h3 className="mt-20">Name your API Key</h3>
      <p>
        This name will appear on any objects in Lever created by Refer
      </p>
      <h3 className="mt-20">Permissions</h3>
      <p>
        Choose
        {' '}
        <span className="bold italic">“Select all”</span>
        {' '}
        from the presets to grant the access needed by the API key to fully integrate with refer.
      </p>
      <img className="mt-15 mb-10 lever-2" src={Lever2} alt="lever step 2" />
      <h3 className="mt-20">Copy your API Key</h3>

      <p className="mt-20">
        Click the
        {' '}
        <span className="bold italic">Copy Key</span>
        {' '}
        button next to the API key (which can be found next to the &apos;Key name&apos; field).
      </p>
      <img className="mt-15 setup-intructions-img" src={Lever3} alt="lever step 3" />
    </div>

  );
}

export default LeverAtsSteps;
