/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { editForm } from 'store/reducers/createBountyForm';
import Card from 'components/Card';
import Dropdown from 'components/Dropdown';
import Input from 'components/Input';
import Icon from 'components/Icon';
import { trackEvent } from 'util/tracking';

function EmployerBountyQuestions() {
  const location = useLocation();
  const dispatch = useDispatch();
  const { customQuestion } = useSelector((state) => state.createBountyForm);
  const [addCustomQuestion, setCustomQuestion] = useState(customQuestion || false);

  const removeCustomQuestion = () => (
    <Icon
      name="close"
      styleProps={{ fill: '#9191a8' }}
      className="mr-2"
      onClick={() => {
        setCustomQuestion(false);
        dispatch(editForm({ key: 'customQuestion', value: '' }));
      }}
    />
  );

  return (
    <>
      <Helmet title="Candidate ranking | Refer" />
      <Card
        title="Candidate ranking"
        subtitle="Every referrer is required to answer the following questions. The answers to these questions help us better understand the candidate."
        buttons={[
          {
            id: 'back',
            text: 'Back',
            variant: 'reverse-secondary',
            to: location.pathname.replace('/ranking', ''),
          },
          {
            id: 'next',
            text: 'Next',
            variant: 'default',
            to: location.pathname.replace('ranking', 'review'),
            onClick: () => trackEvent('Employer: bounty review'),
          },
        ]}
      >
        <Dropdown
          placeholder="How do you know the candidate?"
          disabled
        />
        <Dropdown
          placeholder="How does this candidate compare to others you’ve worked with?"
          disabled
        />
        <Dropdown
          placeholder="Would you work with them again?"
          disabled
        />
        {addCustomQuestion && (
        <Input
          id="custom"
          placeholder="Custom free form question"
          showCustom={removeCustomQuestion}
          onChange={(value) => dispatch(editForm({ key: 'customQuestion', value }))}
          value={customQuestion}
        />
        )}
        { !addCustomQuestion
        && (
        <p
          className="green-text pointer"
          onClick={() => {
            setCustomQuestion(true);
            trackEvent('Employer: custom question');
          }}
        >
          Add custom question
        </p>
        )}
      </Card>
    </>
  );
}

export default EmployerBountyQuestions;
