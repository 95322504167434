import { referralsTableColumns } from 'util/constants';
import TableListColumn from '../TableListColumn';
import tableColumns from '../../TableColumns';
import './TableRow.scss';

function TableRow({ item, isLoading, onClick }) {
  const content = referralsTableColumns.map(
    (name) => (
      <TableListColumn
        key={name}
        isLoading={isLoading}
        id={name}
        item={item}
        component={tableColumns[name]}
      />
    ),
  );

  return (
    <div className="table-list-item" onClick={onClick}>
      { content }
    </div>
  );
}

export default TableRow;
