import { useEffect, useState } from 'react';
import Icon from 'components/Icon';
import classNames from 'classnames';
import './ImageUploader.scss';
import { userAvatarPlaceholder } from 'util/constants';

function ImageUploader({
  saveImage, variant = 'default', showEditIcon, iconSize = 'medium', avatar, className, id, placeholder, fileName = 'file',
}) {
  const [image, setImage] = useState(null);
  const [imageURL, setImageURL] = useState(null);

  const editIconSize = iconSize === 'medium' ? { width: 48, height: 38 } : { width: 36, height: 28.5 };

  useEffect(() => {
    if (!image) return;
    const newImage = URL.createObjectURL(image);
    setImageURL(newImage);

    const data = new FormData();

    data.append(
      fileName,
      image,
    );

    saveImage(data);
  }, [image]);

  const onImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  return (
    <div className={classNames('flex-row', className)}>
      <label htmlFor={id} className={classNames('image-uploader', `image-uploader--${variant}`)}>
        { showEditIcon && <div className={classNames('overlay', `overlay--${variant}`)} />}
        { showEditIcon && <Icon name="camera" width={editIconSize.width} height={editIconSize.height} />}
      </label>
      <input id={id} type="file" accept="image/*" onChange={onImageChange} />
      <div
        className={classNames('image-uploaded', `image-uploaded--${variant}`)}
        style={{ backgroundImage: `url(${imageURL || avatar || placeholder || userAvatarPlaceholder})` }}
      />
    </div>
  );
}

export default ImageUploader;
