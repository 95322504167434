import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Input from 'components/Input';
import './EditProfileDetails.scss';
import ImageUploader from 'components/ImageUploader';
import { userLandscapePlaceholder } from 'util/constants';
import { loadUser, uploadUserImages } from 'actions/user';
import { editForm } from 'store/reducers/editUserForm';

function EditProfileDetails({ user }) {
  const dispatch = useDispatch();
  const [errors, setError] = useState({});
  const { linkedinProfile, website, headline } = useSelector((state) => state.editUserForm);

  const editImage = (img) => {
    dispatch(uploadUserImages(img, user.uuid)).then((res) => {
      if (res.status === 200) {
        dispatch(loadUser());
      }
    });
  };

  return (
    <>
      <div>
        <ImageUploader
          id="landscape"
          saveImage={editImage}
          fileName="header"
          showEditIcon
          placeholder={userLandscapePlaceholder}
          avatar={user.links?.image?.header}
          variant="card"
        />
        <ImageUploader
          id="avatar"
          saveImage={editImage}
          fileName="photo"
          showEditIcon
          avatar={user.links?.image?.user || user.links?.linkedin?.image}
          variant="profile"
          className="edit-info-user-avatar"
          iconSize="small"
        />
      </div>
      <Input
        id="headline"
        onChange={(value) => dispatch(editForm({ key: 'headline', value }))}
        value={headline}
        placeholder="Current role"
      />
      <Input
        id="linkedin"
        placeholder={errors.linkedinProfile || 'Linkedin URL'}
        onChange={(value) => {
          dispatch(editForm({ key: 'linkedinProfile', value }));
          setError({});
        }}
        value={linkedinProfile}
        error={!!errors.linkedinProfile}
      />
      <Input
        id="website"
        onChange={(value) => dispatch(editForm({ key: 'website', value }))}
        value={website}
        placeholder="Website"
      />
    </>
  );
}

export default EditProfileDetails;
