function TableCandidateLoadingBar() {
  return (
    <svg width="204" height="36" viewBox="0 0 204 36" fill="none">
      <rect x="44" y="5" width="96" height="10" rx="2" fill="url(#paint0_linear_1200_3003)" />
      <rect x="44" y="21" width="160" height="10" rx="2" fill="url(#paint1_linear_1200_3003)" />
      <circle cx="18" cy="18" r="18" fill="url(#paint2_linear_1200_3003)" />
      <defs>
        <linearGradient id="paint0_linear_1200_3003" x1="140" y1="10" x2="44" y2="10" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D8D8E4" />
          <stop offset="1" stopColor="#F4F4FA" />
        </linearGradient>
        <linearGradient id="paint1_linear_1200_3003" x1="204" y1="26" x2="44" y2="26" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D8D8E4" />
          <stop offset="1" stopColor="#F4F4FA" />
        </linearGradient>
        <linearGradient id="paint2_linear_1200_3003" x1="36" y1="18" x2="0" y2="18" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D9D9E5" />
          <stop offset="1" stopColor="#F4F4FA" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default TableCandidateLoadingBar;
