import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Card from 'components/Card';
import Input from 'components/Input';
import { integrateAts, editAtsAdminID } from 'actions/ats';
import { loadUserOrganizations } from 'actions/user';
import { loadJobs } from 'actions/jobs';
import ReactModal from 'react-modal';
import Alert from 'components/Alert';
import GreenhouseAtsSteps from './GreenhouseAtsSteps';

function GreenhouseSetup() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const [isOpen, openModal] = useState(false);
  const [atsId, setAtsId] = useState(null);
  const [adminId, setAdminId] = useState(null);
  const { organizations } = useSelector((state) => state.user);
  const currentOrganization = organizations.filter((org) => org.slug === params.companySlug)[0];
  const organizationUuid = currentOrganization.uuid;
  const isConnectedToGH = currentOrganization.departments[0].configuration?.ats?.greenhouse;

  const onConnect = () => {
    if (!adminId) {
      openModal(true);
    } else {
      connectATS();
    }
  };

  const connectATS = () => {
    let action = integrateAts({ atsId, adminId, endpoint: `organization/${organizationUuid}/greenhouse/register/v1/harvest-api` });

    if (isConnectedToGH) {
      action = editAtsAdminID({ adminId, endpoint: `organization/${organizationUuid}/greenhouse/register/v1/privileged-user` });
    }
    dispatch(action)
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          dispatch(loadUserOrganizations());
          dispatch(loadJobs(organizationUuid));
          if (isConnectedToGH) {
            navigate(`/c/${params.companySlug}/referrals`);
          } else {
            navigate(`/c/${params.companySlug}/jobs`);
          }
        }
      });
  };

  return (
    <>
      <Helmet title="Greenhouse setup | Refer" />
      <Card
        title="Greenhouse setup"
        subtitle="Follow the steps below to sync with your ATS and import your open roles."
        style={{ overflow: 'scroll' }}
        buttons={[
          {
            id: 'connect',
            text: 'Connect',
            variant: isConnectedToGH ? !adminId ? 'disabled' : 'default' : !atsId ? 'disabled' : 'default',
            onClick: onConnect,
          },
        ]}
      >
        <div className="flex-row">
          { !isConnectedToGH && (
            <Input
              className="w-100 mr-10"
              id="ats-id"
              placeholder="API KEY"
              onChange={(id) => setAtsId(id)}
              value={atsId}
            />
          )}
          <Input
            className="w-100"
            id="admin-id"
            placeholder="Site Admin User ID"
            onChange={(id) => setAdminId(id)}
            value={adminId}
          />

        </div>
        <GreenhouseAtsSteps />
      </Card>
      <ReactModal
        ariaHideApp={false}
        isOpen={isOpen}
        onRequestClose={() => openModal(false)}
        shouldCloseOnOverlayClick
      >
        <Alert
          alert={{
            title: 'Site Admin User ID',
            subtitle: 'It looks like we’re missing a Site Admin User ID. Without this we won’t be able to sync your referred candidates to Greenhouse.',
          }}
          buttonPrimary="Continue"
          buttonSecondary="Go back"
          buttonPrimaryAction={connectATS}
          onClose={() => openModal(false)}
        />
      </ReactModal>
    </>
  );
}

export default GreenhouseSetup;
