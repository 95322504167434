import { createSlice } from '@reduxjs/toolkit';

const jobs = createSlice({
  name: 'jobs',
  initialState: {
    jobs: [],
    activeJobs: [],
    filters: null,
    referrals: [],
    isFetching: false,
  },
  reducers: {
    jobsRequest(state) {
      state.isFetching = true;
    },
    jobsSuccess(state, { payload }) {
      const jobs = [];
      const referJobId = 3;
      const referJobs = payload.data[referJobId];

      // Push jobs created with refer first
      if (referJobs) {
        Object.values(referJobs).forEach((el) => {
          jobs.push({ ...el, id: el.uuid });
        });
      }

      // Push jobs from ATS, only if user hasn't created one for it from refer
      // by comparing names
      Object.entries(payload.data).forEach(([id, el]) => {
        if (parseInt(id) !== referJobId) {
          el.forEach((job) => {
            if (!jobs.some((el) => el?.ats?.post?.id === job?.ats?.post?.id)) {
              jobs.push({ ...job, id: job.ats.job.id, postId: job.ats.post.id });
            }
          });
        }
      });
      state.jobs = jobs;
      state.activeJobs = jobs.filter((job) => job.status !== 'closed');
      state.isFetching = false;
    },
    jobsFailure(state) {
      state.isFetching = false;
    },
    generalRequest() {},
    generalSuccess() {},
    generalFailure() {},
  },
});

const { actions, reducer } = jobs;

export const {
  jobsRequest,
  jobsSuccess,
  jobsFailure,
  generalRequest,
  generalSuccess,
  generalFailure,
} = actions;

export default reducer;
