import toast from 'components/Alerts';
import { requestReferral } from 'actions/referrals';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import Input from 'components/Input';
import SignupModal from 'components/SignupModal';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

/* eslint-disable max-len */
export default function RequestReferralForm({ openModal, job }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const params = useParams();
  const { countryCodes } = useSelector((state) => state.main);
  const { token } = useSelector((state) => state.user);
  const [isOpen, openSignupModal] = useState();
  const [state, setState] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    message: '',
    countryCode: { id: 232, country: 'United States', code: 1 },
  });
  const canSubmit = state.firstName && state.lastName && state.email;

  useEffect(() => {
    if (sessionStorage.getItem('requestReferral')) {
      setState(JSON.parse(sessionStorage.getItem('requestReferral')));
      sessionStorage.removeItem('requestReferral');
      sessionStorage.removeItem('requestReferralPath');
    }
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();

    if (!token) {
      openSignupModal(true);
    } else {
      dispatch(requestReferral(job.uuid, {
        firstName: state.firstName,
        lastName: state.lastName,
        email: state.email,
        phoneNumber: state.phoneNumber || null,
        message: state.message || null,
        countryCodeId: state.phoneNumber ? state.countryCode?.id : null,
      })).then((res) => {
        if (res.status === 201) {
          openModal(false);
          toast({ subtitle: 'Your referral was requested successfully' });
        }
      });
    }
  };

  const preLoginAction = () => {
    sessionStorage.setItem('requestReferral', JSON.stringify(state));
    sessionStorage.setItem('requestReferralPath', location.pathname);
  };

  return (
    <>
      <h2>Request a referral</h2>
      <p className="f14 mt-5">Send a message to a friend or colleague to request a referral. We’ll contact them with all the details.</p>
      <form className="mb-15" onSubmit={onSubmit}>
        <div className="flex-row mobile-container mt-20">
          <Input
            className="w-100 mr-10"
            id="name"
            placeholder="First name"
            onChange={(value) => setState({ ...state, firstName: value })}
            value={state.firstName}
          />
          <Input
            className="w-100"
            id="lastName"
            placeholder="Last name"
            onChange={(value) => setState({ ...state, lastName: value })}
            value={state.lastName}
          />
        </div>
        <Input
          id="email"
          placeholder="What is their email?"
          onChange={(value) => setState({ ...state, email: value })}
          value={state.email}
        />
        <div className="flex-row">
          <Dropdown
            className="country-code-dropdown mr-10"
            placeholder={state.countryCode?.code ? `+${state.countryCode?.code}` : 'Country code'}
            items={countryCodes}
            onClick={(value) => setState({ ...state, countryCode: value })}
            value={state.countryCode?.country}
          />
          <Input
            id="phoneNumber"
            className="w-100"
            placeholder="What is their phone number? (optional)"
            onChange={(value) => setState({ ...state, phoneNumber: value })}
            value={state.phoneNumber}
          />
        </div>
        <Input
          id="message"
          inputType="textarea"
          className="message-textarea"
          placeholder="Personal message (optional)"
          onChange={(value) => setState({ ...state, message: value })}
          value={state.message}
        />
        <Button type="submit" text="Request" className="mb-20" variant={canSubmit ? 'default' : 'disabled'} />
      </form>
      <SignupModal preLoginAction={preLoginAction} isModalOpen={isOpen} openSignupModal={openSignupModal} />
    </>
  );
}
