import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import Card from 'components/Card';
import List from 'components/List';
import { paymentOpions } from 'pages/EmployerPaymentInfo/util';

function ReferrerPaymentInfo() {
  const navigate = useNavigate();
  const params = useParams();
  const [paymentMethod, setPaymentMethod] = useState();

  const dummyLengthMin = '30 days';

  useEffect(() => {
    // Fetch bounty data with params.id
  }, []);

  const onConnect = () => {
    // 1. Connect paymentMethod
    navigate(`/transfer/${params.id}`);
  };

  return (
    <>
      <Helmet title="Payment method | Refer" />
      <Card
        title="Time to get paid"
        subtitle={`Your referral was successfully hired and stayed in role for ${dummyLengthMin}!`}
        headerIcon="money-balloons"
        buttons={[
          {
            id: 'next',
            text: 'Next',
            variant: !paymentMethod ? 'disabled' : 'default',
            onClick: onConnect,
          },
        ]}
      >
        <List items={paymentOpions} onSelectItem={setPaymentMethod} />
      </Card>
    </>
  );
}

export default ReferrerPaymentInfo;
