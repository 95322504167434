import Icon from 'components/Icon';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { referralEmailSubject } from 'util';
import { trackEvent } from 'util/tracking';

export default function ({ item }) {
  const params = useParams();
  const { organizations, firstName, lastName } = useSelector(
    (state) => state.user,
  );
  const currentOrganization = organizations.filter(
    (org) => org.slug === params.companySlug,
  )[0];

  const onClick = (e) => {
    e.preventDefault();

    trackEvent('Employer: Message applicant');
    window.location = `mailto:${item?.referral?.email}?subject=Your ${
      currentOrganization?.name
    } referral&body=${encodeURIComponent(
      referralEmailSubject(
        item.referral,
        item?.job,
        currentOrganization,
        firstName,
        lastName,
      ),
    )}`;
  };

  return (
    <div>
      <Icon
        className="message-button"
        onClick={onClick}
        name="email"
        styleProps={{ fill: 'black', width: 16, height: 16 }}
      />
    </div>
  );
}
