/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
import React, { Fragment, useState, useEffect } from 'react';
import classNames from 'classnames';
import { Listbox, Transition } from '@headlessui/react';
import Icon from 'components/Icon';
import './Dropdown.scss';

export default function Dropdown({
  items, placeholder, onClick, value, className, disabled, generateKey,
}) {
  const [selectedItem, setSelectedItem] = useState(null);
  const [animation, setFireAnimation] = useState(false);

  useEffect(() => {
    setSelectedItem(value || '');
  }, [value]);

  const onItemClick = (item) => {
    if (!selectedItem) setFireAnimation(true);
    setSelectedItem(item.value || item.name || item);
    onClick(item);
  };

  const placeholderAnimation = () => (selectedItem && animation
    ? 'fieldName'
    : selectedItem
      ? 'static-fieldName'
      : 'placeholder');

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className={`dropdown ${className} ${disabled && 'dropdown--disabled'}`}
    >
      <Listbox value={selectedItem} onChange={(item) => !disabled && onItemClick(item)}>
        <div>
          <Listbox.Button className="dropdown-toggle">
            <div className="flex-row justify-between w-100">
              <div className="flex-column content">
                <p className={classNames(placeholderAnimation(), 'placeholder')}>{ placeholder }</p>
                { selectedItem ? <p className="input">{ selectedItem }</p> : '' }
              </div>
              <Icon name="arrow-down" />
            </div>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="dropdown-menu">
              {items?.map((item) => (
                <Listbox.Option
                  key={generateKey ? Math.random() * 10000 : (item.id || item)}
                  onClick={() => onItemClick(item)}
                  className={({ active }) => classNames('dropdown-item', active && 'dropdown-item--active')}
                  value={item}
                >
                  <p>{item.value || item.name || item}</p>
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}
