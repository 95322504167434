import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactModal from 'react-modal';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { initTracking, identifyUser } from 'util/tracking';
import AppContainer from 'config';
import { continueWithReferralFlow, logOut } from 'util/authorization';
import FullScreenLoader from 'components/FullScreenLoader';
import { loadBootstrap } from 'actions';
import { LinkedinAuth } from 'actions/linkedin';
import { loadJobs } from 'actions/jobs';
import { loadUser, loadUserOrganizations } from 'actions/user';
import Alert from 'components/Alert';

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { token, authError } = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, openModal] = useState(false);
  const authCode = searchParams.get('code');
  const authState = searchParams.get('state');
  const authInProgress = authCode && authState;

  useEffect(() => {
    initTracking();
    if (authInProgress) {
      const forceAuth = !!localStorage.getItem('confirmReferral') || !!localStorage.getItem('referralInfo');
      dispatch(LinkedinAuth({ code: authCode, state: authState, forceAuth }))
        .then((res) => {
          const firstTimeRegister = res.data?.data?.profile;

          // User is on waitlist
          if (res.response?.status === 401) {
            navigate('/');
            openModal(true);
          }
          if (res.status === 200) {
            // Education modal when accepting referrals. Will only show once per session.
            localStorage.setItem('educationCounter', 0);

            // User is registering for the first time.
            // We'll decide later if they get waitlisted
            if (firstTimeRegister) {
              dispatch(loadBootstrap());
              navigate('/signup/account-type');
            } else {
              // User has refistered before, continue using app.
              dispatch(loadBootstrap());
            }
          }
          setIsLoading(false);
        });
    } else {
      dispatch(loadBootstrap())
        .then(({ data }) => {
          if (!data.data.user) setIsLoading(false);
        })
        .catch((e) => setIsLoading(false));
    }
  }, []);

  useEffect(() => {
    if (token) {
      dispatch(loadUser()).then((res) => {
        if (res.name === 'AxiosError') {
          dispatch(logOut());
          navigate('/');
        } else {
          // Tracking
          identifyUser(res.data.data);
          dispatch(loadUserOrganizations())
            .then(({ data }) => {
              const hasOrganization = data.data.length > 0;
              if (hasOrganization) {
                dispatch(loadJobs(data.data[0].uuid));
              }
              shouldRedirectUser(hasOrganization, data.data);
            });
        }
      });
    }
  }, [token]);

  useEffect(() => {
    if (authError) {
      dispatch(logOut());
    }
  }, [authError]);

  // When user logs in from a page that isn't Home, redirect them back to appropriate page.
  const shouldRedirectUser = (hasOrganization, organization) => {
    if (authInProgress) {
      // User has registered before and were referring someone
      if (localStorage.getItem('referralInfo')) {
        continueWithReferralFlow(navigate);

      // User has registered before and were confirming referral
      } else if (localStorage.getItem('confirmReferral')) {
        navigate(localStorage.getItem('confirmReferral'));
        localStorage.removeItem('confirmReferral');

        // User was requesting a referral
      } else if (sessionStorage.getItem('requestReferral')) {
        navigate(sessionStorage.getItem('requestReferralPath'));

      // User was on homepage, just continue with normal flow.
      } else if (localStorage.getItem('userStatus') === 'existing') {
        if (hasOrganization) {
          const { slug } = organization[0];
          navigate(`/c/${slug}`);
        } else {
          navigate('/u/profile');
        }
      } else {
        navigate('/u/profile');
      }

      // If user has already logged in and open homepage, redirect them
    } else if (location.pathname === '/') {
      if (hasOrganization) {
        const { slug } = organization[0];
        navigate(`/c/${slug}`);
      } else {
        navigate('/u/profile');
      }
    }
    setIsLoading(false);
  };

  if (isLoading) {
    return <FullScreenLoader />;
  }

  return (
    <>
      <AppContainer />
      <ReactModal
        ariaHideApp={false}
        isOpen={isModalOpen}
        onRequestClose={() => openModal(false)}
        shouldCloseOnOverlayClick
      >
        <Alert
          alert={{
            title: 'You’re on the list',
            subtitle: 'We can’t wait to have you use Refer. We’ll be in touch via email soon.',
          }}
          buttonPrimary="Done"
          buttonPrimaryAction={() => openModal(false)}
          hideSecondaryButton
          onClose={() => openModal(false)}
        />
      </ReactModal>
    </>
  );
}

export default App;
