/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import {
  Link, useLocation, useNavigate, useParams, useSearchParams,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import ReactQuill from 'react-quill';
import Card from 'components/Card';
import '../CandidateLanding/CandidateLanding.scss';
import { loadBounty } from 'actions/bounty';
import { prettifyLocations } from 'util';
import { trackEvent } from 'util/tracking';
import { loadRequestedReferral } from 'actions/referrals';
import { setReferralRequestId } from 'store/reducers/referral';
import FullScreenLoader from 'components/FullScreenLoader';
import getMeta from './util';
import BountyCTA from './BountyCTA';

function BountyJob() {
  const [searchParams] = useSearchParams();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { requestedReferral, isLoading } = useSelector((state) => state.referral);
  const { isfetching } = useSelector((state) => state.candidateReferralForm);
  const [bounty, onSelectBounty] = useState();
  const { job = {}, organization = {} } = bounty || {};
  const requestedReferralUuid = searchParams.get('request');

  const employmentType = job.employment?.type?.value;
  const employmentExperience = job.work?.experience?.value;

  useEffect(() => {
    dispatch(loadBounty(params.bountySlug)).then((res) => {
      if (res.status === 200) {
        onSelectBounty(res.data.data);
      }
    });
  }, []);

  useEffect(() => {
    if (requestedReferralUuid) {
      dispatch(loadRequestedReferral(requestedReferralUuid)).then((res) => {
        if (res.status === 200) dispatch(setReferralRequestId({ requestedReferralUuid }));
      });
    }
  }, [requestedReferralUuid]);

  const companyCardSubtitle = () => (
    <div className="company-card__subtitle">
      { job.salary && (
      <p className="bold">
        {job.salary.localized?.min ? `${job.salary.localized?.min} - ` : ''}
        {job.salary.localized?.max || '' }
      </p>
      )}
      <p className="grey">
        {prettifyLocations(job.locations)}
        {employmentType && ` • ${employmentType}`}
        {employmentExperience && ` • ${employmentExperience}`}
      </p>
    </div>
  );

  const onReferFriend = () => {
    const { pathname } = location;
    trackEvent('Refer a friend');
    if (pathname.includes('bounties')) {
      navigate(pathname.replace('/job', ''));
      return;
    }

    if (requestedReferral) {
      navigate('bounty?step=2');
    } else {
      navigate('bounty');
    }
  };

  if (isLoading || isfetching) return <FullScreenLoader />;

  return (
    <>
      <Helmet prioritizeSeoTags title={`${job.title} | Refer`}>
        {getMeta(job, organization.name)}
      </Helmet>
      <div className="referral">
        <div className="left-column">
          <Card
            title={job.title}
            className="mb-0"
            subtitle={companyCardSubtitle}
          >
            <ReactQuill
              readOnly
              modules={{ toolbar: null }}
              value={job.description}
            />
          </Card>
        </div>
        <div className="right-column">
          <BountyCTA job={job} organization={organization} onReferFriend={onReferFriend} requestedReferral={requestedReferral} />
          <Card
            variant="small"
            title={organization.name}
            className="make-referral-organization-card"
          >
            <p>{organization.description}</p>
            <p className="mt-8 grey">{ `${organization.funding?.value} • ${organization.category}` }</p>
            <Link
              className="green-text pointer"
              to={`/c/${organization.slug}`}
              onClick={() => trackEvent('View company profile')}
            >
              View all jobs
            </Link>
          </Card>
        </div>
      </div>
    </>
  );
}

export default BountyJob;
