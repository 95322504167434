/* eslint-disable react/style-prop-object */
/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
import './Terms.scss';

function Terms() {
  return (
    <div className="terms">
      <div className="terms-container">
        <h1>Refer Terms of Service</h1>
        <p>
          <strong>Last Updated: </strong>
          December 14, 2022
        </p>
        <p>
          Welcome, and thank you for your interest in Refer by Struck Studio Inc. (
          <strong>&ldquo;Refer&rdquo;</strong>
          ,
          {' '}
          <strong>&ldquo;we&rdquo;</strong>
          , or
          {' '}
          <strong>&ldquo;us&rdquo;</strong>
          ) and our website at&nbsp;
          <a href="http://www.getreferred.co">www.getreferred.co</a>
          , along with our related websites, mobile or other downloadable applications, and other services provided by us (collectively, the
          <strong> &ldquo;Service&rdquo;</strong>
          ). These Terms of Service are a legally binding contract between you and Refer regarding your use of the Service.
        </p>
        <p className="bold">PLEASE READ THE FOLLOWING TERMS CAREFULLY:</p>
        <p>
          <strong>BY CLICKING&nbsp;&ldquo;I ACCEPT,&rdquo;&nbsp;OR BY DOWNLOADING, INSTALLING, OR OTHERWISE ACCESSING OR USING THE SERVICE</strong>
          , YOU AGREE THAT YOU HAVE READ AND UNDERSTOOD, AND, AS A CONDITION TO YOUR USE OF THE SERVICE, YOU AGREE TO BE BOUND BY, THE FOLLOWING TERMS AND CONDITIONS, INCLUDING REFER&rsquo;S
          {' '}
          <a href="/privacy">PRIVACY POLICY</a>
          {' '}
          (TOGETHER, THESE
          {' '}
          <strong>&ldquo;TERMS&rdquo;</strong>
          ).&nbsp;IF YOU ARE NOT ELIGIBLE, OR DO NOT AGREE TO THE TERMS, THEN YOU DO NOT HAVE OUR PERMISSION TO USE THE SERVICE.&nbsp;YOUR USE OF THE SERVICE, AND REFER&rsquo;S PROVISION OF THE SERVICE TO YOU, CONSTITUTES AN AGREEMENT BY REFER AND BY YOU TO BE BOUND BY THESE TERMS.&nbsp;
        </p>
        <p className="bold">YOU AGREE TO RECEIVE TEXTS/CALLS FROM OR ON BEHALF OF REFER AT THE PHONE NUMBER YOU PROVIDE TO US. THESE TEXTS/CALLS MAY INCLUDE ACCOUNT ALERTS AND RECRUITING CALLS. YOU UNDERSTAND AND AGREE THAT THESE TEXTS/CALLS MAY BE CONSIDERED TELEMARKETING UNDER APPLICABLE LAW, THEY MAY BE SENT USING AN AUTOMATIC TELEPHONE DIALING SYSTEM OR OTHER AUTOMATED TECHNOLOGY, AND YOUR CONSENT IS NOT A CONDITION OF ANY PURCHASE.</p>
        <p>
          <strong>ARBITRATION&nbsp;NOTICE.</strong>
          {' '}
          Except for certain kinds of disputes described in Section&nbsp;17 (Dispute Resolution and Arbitration), you agree that disputes arising under these Terms will be resolved by binding, individual arbitration, and BY ACCEPTING THESE TERMS, YOU AND REFER ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN ANY CLASS ACTION OR REPRESENTATIVE PROCEEDING.
        </p>
        <ol>
          <li>
            <p>
              <strong>Refer Service Overview.</strong>
              {' '}
              Refer is a software product and marketplace that allows companies to reward individuals within Refer&rsquo;s network for referring quality candidates to open job roles who are subsequently hired and onboarded according to employer terms.
            </p>
          </li>
          <li>
            <p>
              <strong>Eligibility.</strong>
              {' '}
              You must be at least 18 years old to use the Service. By agreeing to these Terms, you represent and warrant to us that: (a) you are at least 18 years old; (b) you have not previously been suspended or removed from the Service; and (c) your registration and your use of the Service is in compliance with any and all applicable laws and regulations. If you are an entity, organization, or company, the individual accepting these Terms on your behalf represents and warrants that they have authority to bind you to these Terms and you agree to be bound by these Terms.&nbsp;
            </p>
          </li>
          <li>
            <p>
              <strong>Accounts and Registration.</strong>
              {' '}
              To access most features of the Service, you must register for an account. When you register for an account, you may be required to provide us with some information about yourself, such as your name, email address, or other contact information. You agree that the information you provide to us is accurate, complete, and not misleading, and that you will keep it accurate and up to date at all times. When you register, you will be asked to create a password. You are solely responsible for maintaining the confidentiality of your account and password, and you accept responsibility for all activities that occur under your account. If you believe that your account is no longer secure, then you should immediately notify us at support@getreferred.co.
            </p>
          </li>
          <li>
            <p>
              <strong>General Payment Terms.</strong>
              {' '}
              Certain features of the Service may require you to pay fees. Before you pay any fees, you will have an opportunity to review and accept the fees that you will be charged. All fees are in U.S. Dollars and are non-refundable unless otherwise specifically provided for in these Terms.
            </p>
            <ol>
              <li>
                <p>
                  <strong>Price.</strong>
                  {' '}
                  Refer reserves the right to determine pricing for the Service. Refer will make reasonable efforts to keep pricing information published on the Service up to date. We encourage you to check our pricing details periodically for current pricing information. Refer may change the fees for any feature of the Service, including additional fees or charges, if Refer gives you advance notice of changes before they apply. Refer, at its sole discretion, may make promotional offers with different features and different pricing to any of Refer&rsquo;s customers. These promotional offers, unless made to you, will not apply to your offer or these Terms.&nbsp;
                </p>
              </li>
              <li>
                <p>
                  <strong>Authorization.</strong>
                  {' '}
                  You authorize Refer to charge all sums for the orders that you make and any level of Service you select as described in these Terms or published by Refer, including all applicable taxes, to the payment method specified in your account. If you pay any fees with a credit card, then Refer may seek pre-authorization of your credit card account prior to your purchase to verify that the credit card is valid and has the necessary funds or credit available to cover your purchase.&nbsp;
                </p>
              </li>
              <li>
                <p>
                  <strong>Delinquent Accounts.</strong>
                  {' '}
                  Refer may suspend or terminate access to the Service, including fee-based portions of the Service, for any account for which any amount is due but unpaid. In addition to the amount due for the Service, a delinquent account may be charged with fees or charges that are incidental to any chargeback or collection of any of the unpaid amount, including collection fees.&nbsp;
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p className="bold">Licenses</p>
            <ol>
              <li>
                <p>
                  <strong>Limited License.</strong>
                  {' '}
                  Subject to your complete and ongoing compliance with these Terms, Refer grants you, solely for your personal, non-commercial use, a limited, non-exclusive, non-transferable, non-sublicensable, revocable license to: (a) install and use one object code copy of any mobile or other downloadable application associated with the Service (whether installed by you or pre-installed on your mobile device by the device manufacturer or a wireless telephone provider) on a mobile device that you own or control; and (b) access and use the Service.&nbsp;
                </p>
              </li>
              <li>
                <p>
                  <strong>License Restrictions.</strong>
                  {' '}
                  Except and solely to the extent such a restriction is impermissible under applicable law, you may not: (a) reproduce, distribute, publicly display, publicly perform, or create derivative works of the Service; (b) make modifications to the Service; or (c) interfere with or circumvent any feature of the Service, including any security or access control mechanism. If you are prohibited under applicable law from using the Service, then you may not use it.
                </p>
              </li>
              <li>
                <p>
                  <strong>Feedback.</strong>
                  {' '}
                  We respect and appreciate the thoughts and comments from our users. &nbsp;If you choose to provide input and suggestions regarding existing functionalities, problems with or proposed modifications or improvements to the Service (&ldquo;Feedback&rdquo;), then you hereby grant Refer an unrestricted, perpetual, irrevocable, non-exclusive, fully-paid, royalty-free right and license to exploit the Feedback in any manner and for any purpose, including to improve the Service and create other products and services. We will have no obligation to provide you with attribution for any Feedback you provide to us.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p>
              <strong>Ownership; Proprietary Rights.</strong>
              {' '}
              The Service is owned and operated by Refer. The visual interfaces, graphics, design, compilation, information, data, computer code (including source code or object code), products, software, services, and all other elements of the Service provided by Refer (&ldquo;Materials&rdquo;) are protected by intellectual property and other laws. All Materials included in the Service are the property of Refer or its third-party licensors. Except as expressly authorized by Refer, you may not make use of the Materials. There are no implied licenses in these Terms and Refer reserves all rights to the Materials not granted expressly in these Terms.
            </p>
          </li>
          <li>
            <p className="bold">Third-Party Terms</p>
            <ol>
              <li>
                <p>
                  <strong>Third-Party Services and Linked Websites.</strong>
                  {' '}
                  Refer may provide tools through the Service that enable you to export information, including User Content, to third-party services, including through features that allow you to link your account on the Service with an account on the third-party service, such as LinkedIn, or through our implementation of third-party buttons (such as &ldquo;like&rdquo; or &ldquo;share&rdquo; buttons). By using one of these tools, you hereby authorize Refer to transfer that information to the applicable third-party service. Third-party services are not under Refer&rsquo;s control, and, to the fullest extent permitted by law, Refer is not responsible for any third-party service&rsquo;s use of your exported information. The Service may also contain links to third-party websites. Linked websites are not under Refer&rsquo;s control, and Refer is not responsible for their content. Please be sure to review the terms of use and privacy policy of any third-party services before you share any User Content or information with such third-party services. Once sharing occurs, Refer will have no control over the information that has been shared.&nbsp;
                </p>
              </li>
              <li>
                <p>
                  <strong>Third-Party Software.</strong>
                  {' '}
                  The Service may include or incorporate third-party software components that are generally available free of charge under licenses granting recipients broad rights to copy, modify, and distribute those components (&ldquo;Third-Party Components&rdquo;). Although the Service is provided to you subject to these Terms, nothing in these Terms prevents, restricts, or is intended to prevent or restrict you from obtaining Third-Party Components under the applicable third-party licenses or to limit your use of Third-Party Components under those third-party licenses.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p className="bold">User Content</p>
            <ol>
              <li>
                <p>
                  <strong>User Content Generally.</strong>
                  {' '}
                  Certain features of the Service may permit users to submit, upload, publish, broadcast, or otherwise transmit (&ldquo;Post&rdquo;) content to the Service, including candidate referrals, job openings &amp; bounty details, candidate progression, messages, reviews, photos, video or audio (including sound or voice recordings and musical recordings embodied in the video or audio), images, folders, data, text, and any other works of authorship or other works (&ldquo;User Content&rdquo;). You retain any copyright and other proprietary rights that you may hold in the User Content that you Post to the Service, subject to the licenses granted in these Terms.
                </p>
              </li>
              <li>
                <p>
                  <strong>Limited License Grant to Refer.</strong>
                  {' '}
                  By Posting User Content to or via the Service, you grant Refer a worldwide, non-exclusive, irrevocable, royalty-free, fully paid right and license (with the right to sublicense through multiple tiers) to host, store, transfer, publicly display, publicly perform (including by means of a digital audio transmission), communicate to the public, reproduce, modify for the purpose of formatting for display, create derivative works as authorized in these Terms, and distribute your User Content, in whole or in part, in any media formats and through any media channels, in each instance whether now known or hereafter developed. All of the rights you grant in these Terms are provided on a through-to-the-audience basis, meaning the owners or operators of external services will not have any separate liability to you or any other third party for User Content Posted or otherwise used on external services via the Service. You agree to pay all monies owing to any person or entity resulting from Posting your User Content and from Refer&rsquo;s exercise of the license set forth in this Section.
                </p>
              </li>
              <li>
                <p>
                  <strong>You Must Have Rights to the Content You Post;</strong>
                  {' '}
                  User Content Representations and Warranties. You must not Post User Content if you are not the owner of or are not fully authorized to grant rights in all of the elements of that User Content. Refer disclaims any and all liability in connection with User Content. You are solely responsible for your User Content and the consequences of providing User Content via the Service. By providing User Content via the Service, you affirm, represent, and warrant to us that:
                </p>
                <ol className="letter-list">
                  <li>
                    <p>you are the creator and owner of the User Content, or have the necessary licenses, rights, consents, and permissions to authorize Refer and users of the Service to use and distribute your User Content as necessary to exercise the licenses granted by you in this Section, in the manner contemplated by Refer, the Service, and these Terms;</p>
                  </li>
                  <li>
                    <p>your User Content, and the Posting or other use of your User Content as contemplated by these Terms, does not and will not: (i) infringe, violate, misappropriate, or otherwise breach any third-party right, including any copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property, contract, or proprietary right; (ii)&nbsp;slander, defame, libel, or invade the right of privacy, publicity or other property rights of any other person; or (iii) cause Refer to violate any law or regulation or require us to obtain any further licenses from or pay any royalties, fees, compensation or other amounts or provide any attribution to any third parties; and</p>
                  </li>
                  <li>
                    <p>your User Content could not be deemed by a reasonable person to be objectionable, profane, indecent, pornographic, harassing, threatening, embarrassing, hateful, or otherwise inappropriate.</p>
                  </li>
                </ol>
              </li>
              <li>
                <p>
                  <strong>User Content Disclaimer.</strong>
                  {' '}
                  We are under no obligation to edit or control User Content that you or other users Post and will not be in any way responsible or liable for User Content. Refer may, however, at any time and without prior notice, screen, remove, edit, or block any User Content that in our sole judgment violates these Terms, is alleged to violate the rights of third parties, or is otherwise objectionable. You understand that, when using the Service, you will be exposed to User Content from a variety of sources and acknowledge that User Content may be inaccurate, offensive, indecent, or objectionable. You agree to waive, and do waive, any legal or equitable right or remedy you have or may have against Refer with respect to User Content. If notified by a user or content owner that User Content allegedly does not conform to these Terms, we may investigate the allegation and determine in our sole discretion whether to remove the User Content, which we reserve the right to do at any time and without notice. For clarity, Refer does not permit infringing activities on the Service.
                </p>
              </li>
              <li>
                <p>
                  <strong>Monitoring Content.</strong>
                  {' '}
                  Refer does not control and does not have any obligation to monitor: (a) User Content; (b) any content made available by third parties; or (c) the use of the Service by its users. You acknowledge and agree that Refer reserves the right to, and may from time to time, monitor any and all information transmitted or received through the Service for operational and other purposes. If at any time Refer chooses to monitor the content, then Refer still assumes no responsibility or liability for content or any loss or damage incurred as a result of the use of content. During monitoring, information may be examined, recorded, copied, and used in accordance with our Privacy Policy (defined below). Refer may block, filter, mute, remove or disable access to any User Content uploaded to or transmitted through the Service without any liability to the user who Posted such User Content to the Service or to any other users of the Service.&nbsp;
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p className="bold">Communications</p>
            <ol>
              <li>
                <p>
                  <strong> Text Messaging &amp; Phone Calls.</strong>
                  {' '}
                  You agree that Refer and those acting on our behalf may call and send you text (SMS) messages at the phone number you provide us. These calls and messages may include operational calls or messages about your use of the Service, as well as marketing calls or messages. Calls and text messages may be made or sent using an automatic telephone dialing system. Standard data and message rates may apply whenever you send or receive such calls or messages, as specified by your carrier. IF YOU WISH TO OPT OUT OF MARKETING CALLS AND TEXT MESSAGES FROM REFER, YOU CAN EMAIL&nbsp;
                  <a href="mailto:hello@getreferred.co">SUPPORT@GETREFERRED.CO</a>
                  {' '}
                  OR TEXT THE WORD &ldquo;STOP&rdquo; TO THE NUMBER FROM WHICH YOU ARE RECEIVING THE MESSAGES. IF YOU WISH TO OPT OUT OF ALL CALLS AND TEXT MESSAGES FROM&nbsp;REFER, YOU CAN EMAIL&nbsp;
                  <a href="mailto:hello@getreferred.co">SUPPORT@GETREFERRED.CO</a>
                  {' '}
                  OR TEXT THE WORD &ldquo;STOPALL&rdquo; TO THE NUMBER FROM WHICH YOU ARE RECEIVING THE MESSAGES, HOWEVER YOU ACKNOWLEDGE THAT OPTING OUT OF RECEIVING ALL MESSAGES MAY IMPACT YOUR USE OF THE SERVICE. You may continue to receive calls and text messages for a short period while we process your request, including a message confirming the receipt of your opt-out request. Your agreement to receive marketing calls and texts is not a condition of any purchase on or use of the Service.
                </p>
              </li>
              <li>
                <p>
                  <strong>Push Notifications.</strong>
                  {' '}
                  When you install our app on your mobile device, you may agree to receive push notifications, which are messages an app sends you on your mobile device when you are not in the app. You can turn off notifications by visiting your mobile device&rsquo;s &ldquo;settings&rdquo; page.
                </p>
              </li>
              <li>
                <p>
                  <strong>Email.</strong>
                  {' '}
                  We may send you emails concerning our products and services, as well as those of third parties. You may opt out of promotional emails by following the unsubscribe instructions in the promotional email itself.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p>
              <strong>Prohibited Conduct.</strong>
&nbsp;BY USING THE SERVICE, YOU AGREE NOT TO:
            </p>
            <ol>
              <li>
                <p>use the Service for any illegal purpose or in violation of any local, state, national, or international law;</p>
              </li>
              <li>
                <p>harass, threaten, demean, embarrass, bully, or otherwise harm any other user of the Service;</p>
              </li>
              <li>
                <p>violate, encourage others to violate, or provide instructions on how to violate, any right of a third party, including by infringing or misappropriating any third-party intellectual property right;</p>
              </li>
              <li>
                <p>access, search, or otherwise use any portion of the Service through the use of any engine, software, tool, agent, device, or mechanism (including spiders, robots, crawlers, and data mining tools) other than the software or search agents provided by Refer;</p>
              </li>
              <li>
                <p>interfere with security-related features of the Service, including by: (i) disabling or circumventing features that prevent or limit use, printing or copying of any content or internal data repositories; or (ii)&nbsp;reverse engineering or otherwise attempting to discover the source code of any portion of the Service except to the extent that the activity is expressly permitted by applicable law;</p>
              </li>
              <li>
                <p>interfere with the operation of the Service or any user&rsquo;s enjoyment of the Service, including by: (i)&nbsp;uploading or otherwise disseminating any virus, adware, spyware, worm, or other malicious code; (ii)&nbsp;making any unsolicited offer or advertisement to another user of the Service; (iii)&nbsp;collecting personal information about another user or third party without consent; or (iv)&nbsp;interfering with or disrupting any network, equipment, or server connected to or used to provide the Service;</p>
              </li>
              <li>
                <p>perform any fraudulent activity including impersonating any person or entity, claiming a false affiliation or identity, accessing any other Service account without permission, or falsifying your age or date of birth;</p>
              </li>
              <li>
                <p>sell or otherwise transfer the access granted under these Terms or any Materials (as defined in Section&nbsp;6 (Ownership; Proprietary Rights)) or any right or ability to view, access, or use any Materials; or</p>
              </li>
              <li>
                <p>attempt to do any of the acts described in this Section&nbsp;10 (Prohibited Conduct) or assist or permit any person in engaging in any of the acts described in this Section&nbsp;10 (Prohibited Conduct).</p>
              </li>
            </ol>
          </li>
          <li>
            <p className="bold">Intellectual Property Rights Protection</p>
            <ol>
              <li>
                <p>
                  <strong>Respect of Third Party Rights.</strong>
                  {' '}
                  Refer respects the intellectual property rights of others, takes the protection of intellectual property rights very seriously, and asks users of the Service to do the same. Infringing activity will not be tolerated on or through the Service.
                </p>
              </li>
              <li>
                <p>
                  <strong>DMCA Notification.</strong>
                  {' '}
                  We comply with the provisions of the Digital Millennium Copyright Act applicable to Internet service providers (17 U.S.C. &sect; 512, as amended). If you have an intellectual property rights-related complaint about any material on the Service, you may contact our Designated Agent at the following address:
                  <span>
                    <p className="mt-10">Adam B. Struck</p>
                    <p>
                      Struck Studio
                      <br />
                      2908 Colorado Ave, Santa Monica, CA 90404
                      <br />
                      Email: adam@struckcapital.com
                    </p>
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <strong>Procedure for Reporting Claimed Infringement.</strong>
                  {' '}
                  If you believe that any content made available on or through the Service has been used or exploited in a manner that infringes an intellectual property right you own or control, then please promptly send a written &ldquo;Notification of Claimed Infringement&rdquo; to the Designated Agent identified above containing the following information:
                </p>
                <ol>
                  <li>
                    <p>an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright or other right being infringed;</p>
                  </li>
                  <li>
                    <p>a description of the copyrighted work or other intellectual property right that you claim has been infringed;</p>
                  </li>
                  <li>
                    <p>a description of the material that you claim is infringing and where it is located on the Service;</p>
                  </li>
                  <li>
                    <p>your address, telephone number, and email address;</p>
                  </li>
                  <li>
                    <p>a statement by you that you have a good faith belief that the use of the materials on the Service of which you are complaining is not authorized by the copyright or other intellectual property right owner, its agent, or the law; and</p>
                  </li>
                  <li>
                    <p>a statement by you that the above information in your notice is accurate and that, under penalty of perjury, you are the copyright or other intellectual property right owner or authorized to act on the copyright or intellectual property owner&rsquo;s behalf.</p>
                  </li>
                </ol>
              </li>
              <p>Your Notification of Claimed Infringement may be shared by Refer with the user alleged to have infringed a right you own or control as well as with the operators of publicly available databases that track notifications of claimed infringement, and you consent to Refer making such disclosures. You should consult with your own lawyer or see 17 U.S.C. &sect; 512 to confirm your obligations to provide a valid notice of claimed infringement.</p>
              <li>
                <p>
                  <strong>Repeat Infringers.</strong>
                  {' '}
                  Refer&rsquo;s policy is to: (a) remove or disable access to material that Refer believes in good faith, upon notice from an intellectual property rights owner or authorized agent, is infringing the intellectual property rights of a third party by being made available through the Service; and (b) in appropriate circumstances, to terminate the accounts of and block access to the Service by any user who repeatedly or egregiously infringes other people&rsquo;s copyright or other intellectual property rights. Refer will terminate the accounts of users that are determined by Refer to be repeat infringers. Refer reserves the right, however, to suspend or terminate accounts of users in our sole discretion. &nbsp;
                </p>
              </li>
              <li>
                <p>
                  <strong>Counter Notification.</strong>
                  {' '}
                  If you receive a notification from Refer that material made available by you on or through the Service has been the subject of a Notification of Claimed Infringement, then you will have the right to provide Refer with what is called a &ldquo;Counter Notification.&rdquo; To be effective, a Counter Notification must be in writing, provided to Refer&rsquo;s Designated Agent through one of the methods identified in Section 11.2 (DMCA Notification), and include substantially the following information:
                </p>
                <ol className="letter-list">
                  <li>
                    <p>your physical or electronic signature;</p>
                  </li>
                  <li>
                    <p>identification of the material that has been removed or to which access has been disabled and the location at which the material appeared before it was removed or access to it was disabled;</p>
                  </li>
                  <li>
                    <p>a statement under penalty of perjury that you have a good faith belief that the material was removed or disabled as a result of mistake or misidentification of the material to be removed or disabled; and</p>
                  </li>
                  <li>
                    <p>your name, address, and telephone number, and a statement that you consent to the jurisdiction of Federal District Court for the judicial district in which the address is located, or if you are residing outside of the United States, then for any judicial district in which Refer may be found, and that you will accept service of process from the person who provided notification under Section&nbsp;11.2 (DMCA Notification) above or an agent of that person.</p>
                  </li>
                </ol>
              </li>
              <p>A party submitting a Counter Notification should consult a lawyer or see 17 U.S.C. &sect; 512 to confirm the party&rsquo;s obligations to provide a valid counter notification under the Copyright Act.</p>
              <li>
                <p>
                  <strong>Reposting of Content Subject to a Counter Notification.</strong>
                  {' '}
                  If you submit a Counter Notification to Refer in response to a Notification of Claimed Infringement, then Refer will promptly provide the person who provided the Notification of Claimed Infringement with a copy of your Counter Notification and inform that person that Refer will replace the removed User Content or cease disabling access to it in 10 business days, and Refer will replace the removed User Content and cease disabling access to it not less than 10, nor more than 14, business days following receipt of the Counter Notification, unless Refer&rsquo;s Designated Agent receives notice from the party that submitted the Notification of Claimed Infringement that such person has filed an action seeking a court order to restrain the user from engaging in infringing activity relating to the material on Refer&rsquo;s system or network.
                </p>
              </li>
              <li>
                <p>
                  <strong>False Notifications of Claimed Infringement or Counter Notifications.</strong>
                  {' '}
                  The Copyright Act provides at 17 U.S.C. &sect; 512(f) that: &ldquo;[a]ny person who knowingly materially misrepresents under [Section 512 of the Copyright Act (17 U.S.C. &sect; 512)] (1) that material or activity is infringing, or (2) that material or activity was removed or disabled by mistake or misidentification, will be liable for any damages, including costs and attorneys&rsquo; fees, incurred by the alleged infringer, by any copyright owner or copyright owner&rsquo;s authorized licensee, or by a service provider, who is injured by such misrepresentation, as the result of Refer relying upon such misrepresentation in removing or disabling access to the material or activity claimed to be infringing, or in replacing the removed material or ceasing to disable access to it.&rdquo; Refer reserves the right to seek damages from any party that submits a Notification of Claimed Infringement or Counter Notification in violation of the law.
                </p>
              </li>
            </ol>
          </li>
        </ol>

        <ol start="12">
          <li>
            <p>
              <strong>Modification of Terms.</strong>
              {' '}
              We may, from time to time, change these Terms. Please check these Terms periodically for changes. Revisions will be effective immediately except that, for existing users, material revisions will be effective 30 days after posting or notice to you of the revisions unless otherwise stated. We may require that you accept modified Terms in order to continue to use the Service. &nbsp;If you do not agree to the modified Terms, then you should remove your User Content and discontinue your use of the Service. Except as expressly permitted in this Section 12&nbsp;(Modification of Terms), these Terms may be amended only by a written agreement signed by authorized representatives of the parties to these Terms.&nbsp;
            </p>
          </li>
          <li>
            <p className="bold">Term, Termination, and Modification of the Service</p>
            <ol>
              <li>
                <p>
                  <strong>Term.</strong>
                  {' '}
                  These Terms are effective beginning when you accept the Terms or first download, install, access, or use the Service, and ending when terminated as described in Section&nbsp;13.2 (Termination).
                </p>
              </li>
              <li>
                <p>
                  <strong>Termination.</strong>
                  {' '}
                  If you violate any provision of these Terms, then your authorization to access the Service and these Terms automatically terminate. In addition, Refer may, at its sole discretion, terminate these Terms or your account on the Service, or suspend or terminate your access to the Service, at any time for any reason or no reason, with or without notice, and without any liability to you arising from such termination. You may terminate your account and these Terms at any time by contacting customer service at&nbsp;
                  <a href="mailto:hello@getreferred.co">support@getreferred.co</a>
                  .
                </p>
              </li>
              <li>
                <p>
                  <strong>Effect of Termination.</strong>
                  {' '}
                  Upon termination of these Terms: (a) your license rights will terminate and you must immediately cease all use of the Service; (b) you will no longer be authorized to access your account or the Service; (c) you must pay Refer any unpaid amount that was due prior to termination; and (d) all payment obligations accrued prior to termination and Sections 5.3 (Feedback), 6 (Ownership; Proprietary Rights), 8 (User Content), 13.3 (Effect of Termination), 14 (Indemnity), 15 (Disclaimers; No Warranties by Refer), 16 (Limitation of Liability), 17 (Dispute Resolution and Arbitration), and 18 (Miscellaneous) will survive. You are solely responsible for retaining copies of any User Content you Post to the Service since upon termination of your account, you may lose access rights to any User Content you Posted to the Service. If your account has been terminated for a breach of these Terms, then you are prohibited from creating a new account on the Service using a different name, email address or other forms of account verification.
                </p>
              </li>
              <li>
                <p>
                  <strong>Modification of the Service.</strong>
                  {' '}
                  Refer reserves the right to modify or discontinue all or any portion of the Service at any time (including by limiting or discontinuing certain features of the Service), temporarily or permanently, without notice to you. Refer will have no liability for any change to the Service, including any paid-for functionalities of the Service, or any suspension or termination of your access to or use of the Service. You should retain copies of any User Content you Post to the Service so that you have permanent copies in the event the Service is modified in such a way that you lose access to User Content you Posted to the Service.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p>
              <strong>Indemnity.</strong>
&nbsp;To the fullest extent permitted by law, you are responsible for your use of the Service, and you will defend and indemnify Refer, its affiliates and their respective shareholders, directors, managers, members, officers, employees, consultants, and agents (together, the
              <strong>&nbsp;&ldquo;Refer Entities&rdquo;</strong>
              ) from and against every claim brought by a third party, and any related liability, damage, loss, and expense, including attorneys&rsquo; fees and costs, arising out of or connected with: (1) your unauthorized use of, or misuse of, the Service; (2) your violation of any portion of these Terms, any representation, warranty, or agreement referenced in these Terms, or any applicable law or regulation; (3) your violation of any third-party right, including any intellectual property right or publicity, confidentiality, other property, or privacy right; or (4) any dispute or issue between you and any third party. We reserve the right, at our own expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you (without limiting your indemnification obligations with respect to that matter), and in that case, you agree to cooperate with our defense of those claims.
            </p>
          </li>
        </ol>
        <ol start="15">
          <li>
            <p className="bold">Disclaimers; No Warranties by Refer</p>
          </li>
          <ol>
            <li>
              <p>THE SERVICE AND ALL MATERIALS AND CONTENT AVAILABLE THROUGH THE SERVICE ARE PROVIDED &ldquo;AS IS&rdquo; AND ON AN &ldquo;AS AVAILABLE&rdquo; BASIS. REFER DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, RELATING TO THE SERVICE AND ALL MATERIALS AND CONTENT AVAILABLE THROUGH THE SERVICE, INCLUDING: (a) ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, OR NON-INFRINGEMENT; AND (b) ANY WARRANTY ARISING OUT OF COURSE OF DEALING, USAGE, OR TRADE. REFER DOES NOT WARRANT THAT THE SERVICE OR ANY PORTION OF THE SERVICE, OR ANY MATERIALS OR CONTENT OFFERED THROUGH THE SERVICE, WILL BE UNINTERRUPTED, SECURE, OR FREE OF ERRORS, VIRUSES, OR OTHER HARMFUL COMPONENTS, AND REFER DOES NOT WARRANT THAT ANY OF THOSE ISSUES WILL BE CORRECTED.</p>
            </li>
            <li>
              <p>NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM THE SERVICE OR REFER ENTITIES OR ANY MATERIALS OR CONTENT AVAILABLE THROUGH THE SERVICE WILL CREATE ANY WARRANTY REGARDING ANY OF THE REFER ENTITIES OR THE SERVICE THAT IS NOT EXPRESSLY STATED IN THESE TERMS. WE ARE NOT RESPONSIBLE FOR ANY DAMAGE THAT MAY RESULT FROM THE SERVICE AND YOUR DEALING WITH ANY OTHER SERVICE USER. YOU UNDERSTAND AND AGREE THAT YOU USE ANY PORTION OF THE SERVICE AT YOUR OWN DISCRETION AND RISK, AND THAT WE ARE NOT RESPONSIBLE FOR ANY DAMAGE TO YOUR PROPERTY (INCLUDING YOUR COMPUTER SYSTEM OR MOBILE DEVICE USED IN CONNECTION WITH THE SERVICE) OR ANY LOSS OF DATA, INCLUDING USER CONTENT.</p>
            </li>
            <li>
              <p>THE LIMITATIONS, EXCLUSIONS AND DISCLAIMERS IN THIS SECTION 15&nbsp;(DISCLAIMERS; NO WARRANTIES BY REFER)&nbsp;APPLY TO THE FULLEST EXTENT PERMITTED BY LAW. Refer does not disclaim any warranty or other right that Refer is prohibited from disclaiming under applicable law.</p>
            </li>
          </ol>
        </ol>
        <ol start="16">
          <li>
            <p className="bold">Limitation of Liability</p>
            <ol>
              <li>
                <p>TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL THE REFER ENTITIES BE LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES (INCLUDING DAMAGES FOR LOSS OF PROFITS, GOODWILL, OR ANY OTHER INTANGIBLE LOSS) ARISING OUT OF OR RELATING TO YOUR ACCESS TO OR USE OF, OR YOUR INABILITY TO ACCESS OR USE, THE SERVICE OR ANY MATERIALS OR CONTENT ON THE SERVICE, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), STATUTE, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT ANY REFER ENTITY HAS BEEN INFORMED OF THE POSSIBILITY OF DAMAGE.</p>
              </li>
              <li>
                <p>EXCEPT AS PROVIDED IN SECTIONS 17.5 (COMMENCING ARBITRATION) AND&nbsp;17.7 (ARBITRATION RELIEF) AND TO THE FULLEST EXTENT PERMITTED BY LAW, THE AGGREGATE LIABILITY OF THE REFER ENTITIES TO YOU FOR ALL CLAIMS ARISING OUT OF OR RELATING TO THE USE OF OR ANY INABILITY TO USE ANY PORTION OF THE SERVICE OR OTHERWISE UNDER THESE TERMS, WHETHER IN CONTRACT, TORT, OR OTHERWISE, IS LIMITED TO THE GREATER OF: (a)&nbsp;THE AMOUNT YOU HAVE PAID TO REFER FOR ACCESS TO AND USE OF THE SERVICE IN THE 12 MONTHS PRIOR TO THE EVENT OR CIRCUMSTANCE GIVING RISE TO THE CLAIM AND (b)&nbsp;US$100.</p>
              </li>
              <li>
                <p>EACH PROVISION OF THESE TERMS THAT PROVIDES FOR A LIMITATION OF LIABILITY, DISCLAIMER OF WARRANTIES, OR EXCLUSION OF DAMAGES IS INTENDED TO AND DOES ALLOCATE THE RISKS BETWEEN THE PARTIES UNDER THESE TERMS. THIS ALLOCATION IS AN ESSENTIAL ELEMENT OF THE BASIS OF THE BARGAIN BETWEEN THE PARTIES. EACH OF THESE PROVISIONS IS SEVERABLE AND INDEPENDENT OF ALL OTHER PROVISIONS OF THESE TERMS. THE LIMITATIONS IN THIS SECTION&nbsp;16 (LIMITATION OF LIABILITY) WILL APPLY EVEN IF ANY LIMITED REMEDY FAILS OF ITS ESSENTIAL PURPOSE.</p>
              </li>
            </ol>
          </li>
          <li>
            <p className="bold">Dispute Resolution and Arbitration</p>
            <ol>
              <li>
                <p>
                  <strong>Generally.</strong>
                  {' '}
                  Except as described in Section&nbsp;17.2 (Exceptions) and 17.3 (Opt-Out), you and Refer agree that every dispute arising in connection with these Terms, the Service, or communications from us will be resolved through binding arbitration. Arbitration uses a neutral arbitrator instead of a judge or jury, is less formal than a court proceeding, may allow for more limited discovery than in court, and is subject to very limited review by courts. This agreement to arbitrate disputes includes all claims whether based in contract, tort, statute, fraud, misrepresentation, or any other legal theory, and regardless of whether a claim arises during or after the termination of these Terms. Any dispute relating to the interpretation, applicability, or enforceability of this binding arbitration agreement will be resolved by the arbitrator.
                </p>
                <p>YOU UNDERSTAND AND AGREE THAT, BY ENTERING INTO THESE TERMS, YOU AND REFER ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION.</p>
              </li>
              <li>
                <p>
                  <strong>Exceptions.</strong>
                  {' '}
                  Although we are agreeing to arbitrate most disputes between us, nothing in these Terms will be deemed to waive, preclude, or otherwise limit the right of either party to: (a) bring an individual action in small claims court; (b) pursue an enforcement action through the applicable federal, state, or local agency if that action is available; (c) seek injunctive relief in a court of law in aid of arbitration; or (d) to file suit in a court of law to address an intellectual property infringement claim.
                </p>
              </li>
              <li>
                <p>
                  <strong>Opt-Out.</strong>
                  {' '}
                  If you do not wish to resolve disputes by binding arbitration, you may opt out of the provisions of this Section&nbsp;17 (Dispute Resolution and Arbitration) within 30 days after the date that you agree to these Terms by sending a letter to Refer by Struck Studio Inc., Attention: Legal Department &ndash; Arbitration Opt-Out, 2908 Colorado Ave, Santa Monica, CA 90404 that specifies: your full legal name, the email address associated with your account on the Service, and a statement that you wish to opt out of arbitration (&ldquo;Opt-Out Notice&rdquo;). Once Refer receives your Opt-Out Notice, this Section&nbsp;17 (Dispute Resolution and Arbitration) will be void and any action arising out of these Terms will be resolved as set forth in Section&nbsp;18.2 (Governing Law). The remaining provisions of these Terms will not be affected by your Opt-Out Notice.
                </p>
              </li>
              <li>
                <p>
                  <strong>Arbitrator.</strong>
                  {' '}
                  This arbitration agreement, and any arbitration between us, is subject the Federal Arbitration Act and will be administered by the American Arbitration Association (
                  <strong>&ldquo;AAA&rdquo;</strong>
                  ) under its Consumer Arbitration Rules (collectively,
                  {' '}
                  <strong>&ldquo;AAA Rules&rdquo;</strong>
                  ) as modified by these Terms. The AAA Rules and filing forms are available online at www.adr.org, by calling the AAA at +1-800-778-7879, or by contacting Refer.&nbsp;
                </p>
              </li>
              <li>
                <p>
                  <strong>Commencing Arbitration.</strong>
                  {' '}
                  Before initiating arbitration, a party must first send a written notice of the dispute to the other party by certified U.S. Mail or by Federal Express (signature required) or, only if that other party has not provided a current physical address, then by electronic mail (
                  <strong>&ldquo;Notice of Arbitration&rdquo;</strong>
                  ). Refer&rsquo;s address for Notice is: Refer by Struck Studio Inc., 2908 Colorado Ave, Santa Monica, CA 90404. The Notice of Arbitration must: (a)&nbsp;identify the name or account number of the party making the claim; (b) describe the nature and basis of the claim or dispute; and (c) set forth the specific relief sought (
                  <strong>&ldquo;Demand&rdquo;</strong>
                  ). The parties will make good faith efforts to resolve the claim directly, but if the parties do not reach an agreement to do so within 30 days after the Notice of Arbitration is received, you or Refer may commence an arbitration proceeding. If you commence arbitration in accordance with these Terms, Refer will reimburse you for your payment of the filing fee, unless your claim is for more than US$10,000 or if Refer has received 25 or more similar demands for arbitration, in which case the payment of any fees will be decided by the AAA Rules. If the arbitrator finds that either the substance of the claim or the relief sought in the Demand is frivolous or brought for an improper purpose (as measured by the standards set forth in Federal Rule of Civil Procedure 11(b)), then the payment of all fees will be governed by the AAA Rules and the other party may seek reimbursement for any fees paid to AAA.
                </p>
              </li>
              <li>
                <p>
                  <strong>Arbitration Proceedings.</strong>
                  {' '}
                  Any arbitration hearing will take place in the county and state of your billing address unless we agree otherwise or, if the claim is for US$10,000 or less (and does not seek injunctive relief), you may choose whether the arbitration will be conducted: (a) solely on the basis of documents submitted to the arbitrator; (b) through a telephonic or video hearing; or (c) by an in-person hearing as established by the AAA Rules in the county (or parish) of your billing address. During the arbitration, the amount of any settlement offer made by you or Refer must not be disclosed to the arbitrator until after the arbitrator makes a final decision and award, if any. Regardless of the manner in which the arbitration is conducted, the arbitrator must issue a reasoned written decision sufficient to explain the essential findings and conclusions on which the decision and award, if any, are based.&nbsp;
                </p>
              </li>
              <li>
                <p>
                  <strong>Arbitration Relief.</strong>
                  {' '}
                  Except as provided in Section 17.8 (No Class Actions), the arbitrator can award any relief that would be available if the claims had been brough in a court of competent jurisdiction. If the arbitrator awards you an amount higher than the last written settlement amount offered by Refer before an arbitrator was selected, Refer will pay to you the higher of: (a) the amount awarded by the arbitrator and (b)&nbsp;US$10,000. The arbitrator&rsquo;s award shall be final and binding on all parties, except (1) for judicial review expressly permitted by law or (2) if the arbitrator&apos;s award includes an award of injunctive relief against a party, in which case that party shall have the right to seek judicial review of the injunctive relief in a court of competent jurisdiction that shall not be bound by the arbitrator&apos;s application or conclusions of law. Judgment on the award may be entered in any court having jurisdiction.
                </p>
              </li>
              <li>
                <p>
                  <strong>No Class Actions.</strong>
                  {' '}
                  YOU AND REFER AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. Further, unless both you and Refer agree otherwise, the arbitrator may not consolidate more than one person&rsquo;s claims, and may not otherwise preside over any form of a representative or class proceeding. &nbsp;
                </p>
              </li>
              <li>
                <p>
                  <strong>Modifications to this Arbitration Provision.</strong>
                  {' '}
                  If Refer makes any substantive change to this arbitration provision, you may reject the change by sending us written notice within 30&nbsp;days of the change to Refer&rsquo;s address for Notice of Arbitration, in which case your account with Refer will be immediately terminated and this arbitration provision, as in effect immediately prior to the changes you rejected will survive.
                </p>
              </li>
              <li>
                <p>
                  <strong>Enforceability.</strong>
                  {' '}
                  If Section&nbsp;17.8 (No Class Actions) or the entirety of this Section&nbsp;17 (Dispute Resolution and Arbitration) is found to be unenforceable, or if Refer receives an Opt-Out Notice from you, then the entirety of this Section&nbsp;17 (Dispute Resolution and Arbitration) will be null and void and, in that case, the exclusive jurisdiction and venue described in Section&nbsp;18.2 (Governing Law) will govern any action arising out of or related to these Terms.&nbsp;
                </p>
              </li>
            </ol>
          </li>
        </ol>

        <ol start="18">
          <li>
            <p className="bold">Miscellaneous</p>
            <ol>
              <li>
                <p>
                  <strong>General Terms.</strong>
                  {' '}
                  These Terms, including the Privacy Policy and any other agreements expressly incorporated by reference into these Terms, are the entire and exclusive understanding and agreement between you and Refer regarding your use of the Service. You may not assign or transfer these Terms or your rights under these Terms, in whole or in part, by operation of law or otherwise, without our prior written consent. We may assign these Terms and all rights granted under these Terms, including with respect to your User Content, at any time without notice or consent. The failure to require performance of any provision will not affect our right to require performance at any other time after that, nor will a waiver by us of any breach or default of these Terms, or any provision of these Terms, be a waiver of any subsequent breach or default or a waiver of the provision itself. Use of Section&nbsp;headers in these Terms is for convenience only and will not have any impact on the interpretation of any provision. Throughout these Terms the use of the word &ldquo;including&rdquo; means &ldquo;including but not limited to.&rdquo; If any part of these Terms is held to be invalid or unenforceable, then the unenforceable part will be given effect to the greatest extent possible, and the remaining parts will remain in full force and effect.
                </p>
              </li>
              <li>
                <p>
                  <strong>Governing Law.</strong>
                  {' '}
                  These Terms are governed by the laws of the State of California without regard to conflict of law principles. You and Refer submit to the personal and exclusive jurisdiction of the state courts and federal courts located within Santa Clara County, California for resolution of any lawsuit or court proceeding permitted under these Terms. We operate the Service from our offices in California, and we make no representation that Materials included in the Service are appropriate or available for use in other locations.
                </p>
              </li>
              <li>
                <p>
                  <strong>Privacy Policy.</strong>
                  {' '}
                  Please read the Refer
                  {' '}
                  <a href="/privacy">Privacy Policy</a>
                  {' '}
                  (the
                  {' '}
                  <strong>&ldquo;Privacy Policy&rdquo;</strong>
                  ) carefully for information relating to our collection, use, storage, and disclosure of your personal information. The Refer Privacy Policy is incorporated by this reference into, and made a part of, these Terms.
                </p>
              </li>
              <li>
                <p>
                  <strong>Additional Terms.</strong>
                  {' '}
                  Your use of the Service is subject to all additional terms, policies, rules, or guidelines applicable to the Service or certain features of the Service that we may post on or link to from the Service (the
                  {' '}
                  <strong>&ldquo;Additional Terms&rdquo;</strong>
                  ). All Additional Terms are incorporated by this reference into, and made a part of, these Terms.
                </p>
              </li>
              <li>
                <p>
                  <strong>Consent to Electronic Communications.</strong>
                  {' '}
                  By using the Service, you consent to receiving certain electronic communications from us as further described in our Privacy Policy. Please read our Privacy Policy to learn more about our electronic communications practices. You agree that any notices, agreements, disclosures, or other communications that we send to you electronically will satisfy any legal communication requirements, including that those communications be in writing.
                </p>
              </li>
              <li>
                <p>
                  <strong>Contact Information.</strong>
                  {' '}
                  The Service is offered by Refer by Struck Studio Inc., located at 2908 Colorado Ave, Santa Monica, CA 90404. You may contact us by sending correspondence to that address or by emailing us at&nbsp;
                  <a href="mailto:hello@getreferred.co">hello@getreferred.co</a>
                  .
                </p>
              </li>
              <li>
                <p>
                  <strong>Notice to California Residents.</strong>
                  {' '}
                  If you are a California resident, then under California Civil Code Section&nbsp;1789.3, you may contact the Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs in writing at 1625 N. Market Blvd., Suite S-202, Sacramento, California 95834, or by telephone at +1-800-952-5210 in order to resolve a complaint regarding the Service or to receive further information regarding use of the Service.
                </p>
              </li>
              <li>
                <p>
                  <strong>No Support.</strong>
                  {' '}
                  We are under no obligation to provide support for the Service. In instances where we may offer support, the support will be subject to published policies.
                </p>
              </li>
              <li>
                <p>
                  <strong>International Use.</strong>
                  {' '}
                  The Service is intended for visitors located within the United States. We make no representation that the Service is appropriate or available for use outside of the United States. Access to the Service from countries or territories or by individuals where such access is illegal is prohibited.
                </p>
              </li>
            </ol>
          </li>
        </ol>
        <ol start="19">
          <li>
            {' '}
            <p>
              <strong>Notice Regarding Apple.</strong>
              {' '}
              This Section 19 (Notice Regarding Apple) only applies to the extent you are using our mobile application on an iOS device. You acknowledge that these Terms are between you and Refer only, not with Apple Inc. (
              <strong>&ldquo;Apple&rdquo;</strong>
              ), and Apple is not responsible for the Service or the content of it. Apple has no obligation to furnish any maintenance and support services with respect to the Service. If the Service fails to conform to any applicable warranty, you may notify Apple, and Apple will refund any applicable purchase price for the mobile application to you. To the maximum extent permitted by applicable law, Apple has no other warranty obligation with respect to the Service. Apple is not responsible for addressing any claims by you or any third party relating to the Service or your possession and/or use of the Service, including: (1) product liability claims; (2) any claim that the Service fails to conform to any applicable legal or regulatory requirement; or (3) claims arising under consumer protection or similar legislation. Apple is not responsible for the investigation, defense, settlement, and discharge of any third-party claim that the Service and/or your possession and use of the Service infringe a third party&rsquo;s intellectual property rights. You agree to comply with any applicable third-party terms when using the Service. Apple and Apple&rsquo;s subsidiaries are third-party beneficiaries of these Terms, and upon your acceptance of these Terms, Apple will have the right (and will be deemed to have accepted the right) to enforce these Terms against you as a third-party beneficiary of these Terms. You hereby represent and warrant that: (a) you are not located in a country that is subject to a U.S. Government embargo or that has been designated by the U.S. Government as a
              {' '}
              <strong>&ldquo;terrorist supporting&rdquo;</strong>
              {' '}
              country; and (b) you are not listed on any U.S. Government list of prohibited or restricted parties.
            </p>

          </li>
        </ol>
      </div>
    </div>
  );
}

export default Terms;
