import TableEmptyState from '../TableEmptyState';
import TableRow from './TableRow';

function TableList({
  items, onClickItem, emptyTable, currentBounty, isLoading,
}) {
  const rows = !isLoading ? items : [...new Array(6)];
  const renderList = rows.map(
    (item, i) => (
      <TableRow
        key={item?.application?.uuid || i}
        onClick={() => onClickItem(i)}
        item={item}
        isLoading={isLoading}
      />
    ),
  );

  if (isLoading) {
    return renderList;
  }

  return (
    <div className="table-list">
      { emptyTable ? <TableEmptyState currentBounty={currentBounty} /> : renderList }
    </div>
  );
}

export default TableList;
