import dayjs from 'dayjs';
import { DATE_FORMAT } from 'util/constants';
import { emailRegex } from './constants';

/* Converts int to use comma (ex 5000 to 5,000) */
export const parseMoneyInt = (int) => {
  if (!int) return '';
  return int.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
/* Checks if object is empty */
export const isEmpty = (obj) => obj
  && Object.keys(obj).length === 0
  && Object.getPrototypeOf(obj) === Object.prototype;

export const isValidEmail = (email) => {
  if (!email) return;
  return email.toLowerCase().match(emailRegex);
};

export const isAdmin = (email) => {
  const adminEmails = [
    'mcmontero@gmail.com',
    'greendandrew@gmail.com',
    'hi.from.refer@gmail.com',
    'lsmith0925@gmail.com',
    'romidgomez@hotmail.com',
    'tindall.gordon@gmail.com',
    'test.getreferred.1@gmail.com',
    'john.fornaciari@gmail.com',
  ];
  return !!adminEmails.includes(email);
};

export const formatDate = (date) => dayjs(date).format(DATE_FORMAT);

export const formatWebsite = (website) => {
  const pattern = /^((http|https|ftp):\/\/)/;

  if (!pattern.test(website)) {
    return `https://${website}`;
  }
  return website;
};

export const prettifyWebsite = (str) => {
  if (!str || typeof str !== 'string') return '';

  return str.replace('http://', '')
    .replace('https://', '')
    .replace('www.', '');
};

export const prettifyLinkedinUrl = (url) => url?.replace('https://www.linkedin.com', '').replace(/\/+$/, '') || url;

export const prettifyLocations = (locations) => {
  if (!locations || !locations.length) return '';
  const formattedLocations = locations.map((el) => el.value).filter(Boolean);
  if (locations.filter((el) => el.remote)[0]) {
    formattedLocations.unshift('Remote');
  }
  return formattedLocations.length === 1 ? formattedLocations : formattedLocations.join(', ');
};

export const prettifyLocationsData = (locations, editingJob) => {
  const parseId = (id) => {
    if (id === 'canadian_provinces') return 'canadian_province_id';
    if (id === 'cities') return 'city_id';
    if (id === 'continents') return 'continent_id';
    if (id === 'countries') return 'country_id';
    if (id === 'states') return 'state_id';
    return null;
  };
  if (editingJob) {
    return locations.map(
      (el) => ({
        ...el,
        dataType: el.data?.type || parseId(el.dataType),
      }))
      .map((el) => {
        delete el.data;
        return el;
      });
  }

  return locations.map(
    (el) => ({ ...el, dataType: parseId(el.dataType) }));
};

export const statusStyle = (confirmed, rejected, reminded) => {
  if (confirmed) {
    return { color: '#1BA00B' };
  }
  if (rejected) {
    return { color: '#FF9900' };
  }
  if (reminded) {
    return { color: '#DCB902' };
  }
  return { color: '#DCB902' };
};

export const referralStatus = ({ confirmed, rejected, reminded }) => {
  if (confirmed) return 'Confirmed';
  if (rejected) return 'Rejected';
  if (reminded) return 'Reminded';
  return 'Pending';
};

export const getReferralStatus = (item) => {
  const dates = Object.entries(item).map(([id, key]) => ({ value: id, date: key }));
  const sortedDates = dates.sort((a, b) => new Date(b.date) - new Date(a.date));

  return sortedDates[0].value;
};

export const referralStatusNew = (item) => {
  const status = getReferralStatus(item);

  if (!status) return 'New';
  if (status === 'approved' || status === 'synced') return 'Accepted';
  if (status === 'passed') return 'Passed';
  if (status === 'created') return 'New';
};

export const referralStatusStyle = (item) => {
  const status = getReferralStatus(item);

  if (!status) return 'status-label--new';
  if (status === 'approved' || status === 'synced') return 'status-label--approved';
  if (status === 'passed') return 'status-label--passed';
  if (status === 'created') return 'status-label--new';
};

export const getLabelStyle = (stage) => {
  if (!stage) return 'status-label-new';
  if (stage === 'Pass' || stage === 'Reject') return 'status-label-negative';
  return 'status-label-positive';
};

export const getKnobStyle = (stage) => {
  if (!stage) return 'white';
  if (stage === 'Pass' || stage === 'Reject') return '#63637B';
  return '#1ABD00';
};

export const getStageStyle = (stage) => {
  if (!stage || stage === 'Pass' || stage === 'Reject') return '#898989';
  return '#1ba00b';
};

export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
};

export const getOrgUuid = (slug, orgs) => {
  const organization = orgs.filter((org) => org.slug === slug);
  return organization[0]?.uuid;
};

export const referralEmailSubject = (
  referral, jobReferredTo, currentOrganization, firstName, lastName,
) => (
  `Hi ${referral?.firstName}, \n
    Thanks for your interest in ${currentOrganization?.name}! We received your confirmed referral for our ${jobReferredTo.title} role and would like to chat further. \n
    As a next step, we would like to schedule an introductory call - could you please provide your availability over the next week?\n
    Please don’t hesitate to reach out with any questions.\n
    Thanks you,
    ${firstName} ${lastName}`
);

export const getEmoji = (el, max) => {
  if (el === 1 || el === 4) {
    return '👨‍💻';
  }
  if (el === 3) {
    return '🧑‍💻';
  }
  return '👩‍💻';
};
