function Burger({ fill }) {
  return (
    <svg width="30" height="30" viewBox="0 0 24 24" fill="none">
      <path d="M4 18L20 18" stroke={fill} strokeWidth="2" strokeLinecap="round" />
      <path d="M4 12L20 12" stroke={fill} strokeWidth="2" strokeLinecap="round" />
      <path d="M4 6L20 6" stroke={fill} strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
}

export default Burger;
