import Alert from 'components/Alert';
import ReactModal from 'react-modal';

export default function StageModal({
  isOpen, onClose, candidateFirstName, candidateLastName, onSendMessage,
}) {
  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick
    >
      <Alert
        alert={{
          title: 'Great news!',
          subtitle: `Send a message to ${candidateFirstName} ${candidateLastName} now to schedule a first conversation.`,
        }}
        buttonPrimary="Send message"
        buttonPrimaryAction={() => {
          onSendMessage();
          onClose();
        }}
        buttonSecondary="Not now"
        onClose={onClose}
      />
    </ReactModal>
  );
}
