import { createSlice } from '@reduxjs/toolkit';

const editOrganizationForm = createSlice({
  name: 'editOrganizationForm',
  initialState: {},
  reducers: {
    initializeForm(state, { payload }) {
      const {
        funding, links, description, name, category,
      } = payload;
      return {
        funding,
        website: links.website,
        description,
        name,
        category,
      };
    },
    editForm(state, { payload }) {
      state[payload.key] = payload.value;
    },
    clearForm() {
      return {};
    },
  },
});

const { actions, reducer } = editOrganizationForm;

export const {
  initializeForm,
  editForm,
  clearForm,
} = actions;

export default reducer;
