/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import truncate from 'truncate-html';
import classNames from 'classnames';
import Icon from 'components/Icon';
import ReactQuill from 'react-quill';
import './CardRow.scss';

export default function CardRow({
  title,
  subtitle,
  footer,
  icon,
  img,
  iconProps = {},
  showBorder = true,
  isMarkdown,
}) {
  const image = img ? <img className="card-row--img" src={img} alt="decorative" /> : <Icon name={icon} {...iconProps} />;
  return (
    <div className={classNames('card-row', showBorder && 'card-row--border')}>
      { image }
      <div>
        <p className="bold">{title}</p>
        { isMarkdown ? (
          <ReactQuill
            readOnly
            modules={{ toolbar: null }}
            value={truncate(subtitle, 210)}
          />
        ) : <p className="grey">{subtitle}</p>}

        { footer }
      </div>
    </div>
  );
}

CardRow.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  icon: PropTypes.string,
  img: PropTypes.string,
  iconProps: PropTypes.object,
};
