/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import { Routes, Route } from 'react-router-dom';

import Home from 'pages/LandingPages/Home';
import Employer from 'pages/LandingPages/Employer';
import NotFound from 'pages/NotFound';

import EmployerSetup from 'pages/EmployerSetup';
import EmployerJobs from 'pages/EmployerJobs';
import EmployerJobCreate from 'pages/EmployerJobCreate';
import EmployerBounty from 'pages/EmployerBounty';
import EmployerPaymentInfo from 'pages/EmployerPaymentInfo';
import EmployerBountyShare from 'pages/EmployerBountyShare';
import EmployerProfile from 'pages/EmployerProfile';
import EmployerReferrals from 'pages/EmployerReferrals';
import EmployerJobEdit from 'pages/EmployerJobEdit';
import EmployerCreateOrganization from 'pages/EmployerCreateOrganization';

import GreenhouseSetup from 'pages/EmployerSetup/GreenhouseSetup';
import LeverSetup from 'pages/EmployerSetup/LeverSetup';
import WorkableSetup from 'pages/EmployerSetup/WorkableSetup';

import ReferrerPayment from 'pages/ReferrerPayment';
import ReferrerPaymentInfo from 'pages/ReferrerPaymentInfo';
import ReferrerProfile from 'pages/ReferrerProfile';
import ReferrerReferral from 'pages/ReferrerReferral';
import ReferrerReferralSubmitted from 'pages/ReferrerReferralSubmitted';
import ReferrerTaxForm from 'pages/ReferrerTaxForm';

import BountyJob from 'pages/BountyJob';
import CandidateLanding from 'pages/CandidateLanding';
import Privacy from 'pages/Privacy';
import AccountType from 'pages/AccountType';
import GreenhouseDataUse from 'pages/GreenhouseDataUse';
import Terms from 'pages/Terms';
import SignupForm from 'pages/SignupForm';
import EmployerBountyQuestions from 'pages/EmployerBountyQuestions';
import EmployerBountyReview from 'pages/EmployerBountyReview';
import Refer from 'pages/LandingPages/Refer';
import PrivateRoute from './PrivateRoute';

const getRoutes = () => (
  <Routes>
    {/* Landing pages */}
    <Route path="/" element={<Home />} />
    <Route path="/refer" element={<Refer />} />
    <Route path="/employer" element={<Employer />} />

    {/* Other */}
    <Route path="*" element={<NotFound />} />
    <Route path="privacy" element={<Privacy />} />
    <Route path="terms" element={<Terms />} />

    {/* Signup */}
    <Route path="signup/account-type" element={<AccountType />} />
    <Route path="signup/details/:userType" element={<SignupForm />} />
    <Route path="greenhouse-data-use" element={<GreenhouseDataUse />} />

    {/* Employer routes */}
    <Route path="signup/organization" element={<EmployerCreateOrganization />} />
    <Route path="c/:companySlug/greenhouse" element={<GreenhouseSetup />} />
    <Route path="c/:companySlug/workable" element={<WorkableSetup />} />
    <Route path="c/:companySlug/lever" element={<LeverSetup />} />
    <Route path="c/:companySlug/setup" element={<EmployerSetup />} />
    <Route path="c/:companySlug/create" element={<EmployerJobCreate />} />
    <Route path="c/:companySlug/jobs" element={<PrivateRoute><EmployerJobs /></PrivateRoute>} />

    <Route path="/c/:companySlug/bounty-details/:jobId" element={<PrivateRoute><EmployerBounty /></PrivateRoute>} />
    <Route path="/c/:companySlug/bounty-details/:jobId/ranking" element={<PrivateRoute><EmployerBountyQuestions /></PrivateRoute>} />
    <Route path="/c/:companySlug/bounty-details/:jobId/review" element={<PrivateRoute><EmployerBountyReview /></PrivateRoute>} />

    <Route path="c/:companySlug/jobs/:jobId" element={<EmployerBounty />} />
    <Route path="c/:companySlug/jobs/:jobId/ranking" element={<PrivateRoute><EmployerBountyQuestions /></PrivateRoute>} />
    <Route path="c/:companySlug/jobs/:jobId/review" element={<PrivateRoute><EmployerBountyReview /></PrivateRoute>} />

    <Route path="c/:companySlug/bounty-success" element={<PrivateRoute><EmployerBountyShare /></PrivateRoute>} />
    <Route path="c/:companySlug/bounty-confirm" element={<EmployerBountyShare />} />
    <Route path="c/:companySlug" element={<EmployerProfile />}>
      <Route path=":page" element={<EmployerProfile />} />
    </Route>
    <Route path="c/:companySlug/jobs/edit" element={<EmployerJobEdit />} />
    <Route path="c/:companySlug/referrals" element={(<PrivateRoute><EmployerReferrals /></PrivateRoute>)}>
      <Route path=":id" element={<EmployerReferrals />} />
    </Route>
    <Route path="employer-payment" element={<EmployerPaymentInfo />} />

    {/* Referrer routes */}
    <Route path="u/profile" element={<PrivateRoute><ReferrerProfile /></PrivateRoute>} />
    <Route path="c/:companySlug/bounties" element={<ReferrerReferral />}>
      <Route path=":bountySlug" element={<ReferrerReferral />} />
    </Route>
    <Route path="b/:slug/submitted" element={<ReferrerReferralSubmitted />} />
    {/* <Route path="consumer-payment/:id" element={<ReferrerPaymentInfo />} /> */}
    {/* <Route path="b/:slug/tax-form/:id" element={<ReferrerTaxForm />} />
    <Route path="b/:slug/transfer/:id" element={<ReferrerPayment />} /> */}

    {/* Bounty routes */}
    <Route path="c/:companySlug/j/:bountySlug" element={<BountyJob />} />
    <Route path="c/:companySlug/j/:bountySlug/bounty" element={<ReferrerReferral />} />
    <Route path="c/:companySlug/bounties/job" element={<BountyJob />} />
    <Route path="c/:companySlug/bounties/:bountySlug/job" element={<BountyJob />} />

    {/* Candidate routes */}
    <Route path="r/:code" element={<CandidateLanding />} />
  </Routes>
);

export default getRoutes;
