/* eslint-disable react/no-unescaped-entities */
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { faqsReferrer } from 'util/constants';
import { loadOpenJobs } from 'actions/jobs';
import LinkedinSignup from 'components/LinkedinSignup';
import Accordion from 'components/Accordion';
import Image1 from 'images/ReferHome/Image-1.webp';
import Image2 from 'images/ReferHome/Image-2.webp';
import Image3 from 'images/ReferHome/Image-3.webp';
import Image4 from 'images/ReferHome/Image-4.webp';
import Home5 from 'images/home-employer-5.webp';
import '../Home.scss';
import './Refer.scss';
import Footer from 'pages/LandingPages/Footer';
import { getOpenJobsList } from 'pages/ReferrerProfile/util';
import CardLoaderHome from 'components/Card/CardLoaderHome';
import classNames from 'classnames';

export default function Refer() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { shuffledOpenJobs } = useSelector((state) => state.openJobs);
  const { token } = useSelector((state) => state.user);
  const openJobsList = getOpenJobsList(shuffledOpenJobs, navigate);

  useEffect(() => {
    if (!shuffledOpenJobs.length) {
      dispatch(loadOpenJobs());
    }
  }, []);

  const blocksImages = [Image1, Image2, Image3, Image4];
  const blocksTitles = ['Customer', 'Current teammate', 'Friend', 'Ex-Manager'];

  return (
    <div className="refer-home">
      <Helmet title="Refer" />
      <div className={classNames('refer-section-1', !token && 'refer-section-1--with-signup')}>
        <h1>Discover open roles now</h1>
        <p>Earn referral bonuses for top jobs in tech from eng to sales to finance</p>
        <div className="bounties-container">
          { !shuffledOpenJobs.length ? <CardLoaderHome /> : openJobsList?.slice(0, 7)}
        </div>
        { !token && (
          <div className="blurred-signup-section">
            <div className="flex-column align-center">
              <h2>Sign up to view more</h2>
              <LinkedinSignup center variant="black" />
            </div>
          </div>
        )}
      </div>
      <div className="refer-section-2">
        <div>
          <h2 className="home-title">Looking for a new gig?</h2>
          <p>Request a referral from a personal connection for any open role</p>
          <div className="flex-row blocks-container">
            { [...new Array(4)].map((el, i) => (
              <div key={blocksTitles[i]} className="refer-section-2-block flex-column align-center">
                <img src={blocksImages[i]} alt="decorative" />
                <h4 className="mt-15">{blocksTitles[i]}</h4>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="section-5 home-block">
        <div className="section-5__left-screen home-block__left-screen">
          <div className="container">
            <div>
              <h2 className="home-title">Quality matters</h2>
              <div className="flex-column">
                <p className="home-description">Only refer people you know are the best fit for the role to maximize your chances of success.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="section-5__right-screen employer-5">
          <img src={Home5} alt="decoration" loading="lazy" />
        </div>
      </div>
      { !token && (
      <div className="home-cta flex-row justify-between">
        <h2>Sign up today to get started</h2>
        <LinkedinSignup />
      </div>
      )}
      <div className="faqs">
        <h2 className="home-title">FAQs</h2>
        <Accordion items={faqsReferrer} />
      </div>
      <Footer />
    </div>
  );
}
