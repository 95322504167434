import { createSlice } from '@reduxjs/toolkit';

const employerAccountSettingsForm = createSlice({
  name: 'employerAccountSettingsForm',
  initialState: {
    data: {
      company: null,
      city: null,
      address: null,
      zipCode: null,
      state: null,
      taxId: null,
      website: null,
      name: null,
      email: null,
      role: null,
      bank: {
        name: null,
        address: null,
        city: null,
        state: null,
        zipcode: null,
        accountNumber: null,
        routingNumber: null,
      },
    },
    errors: {
      email: null,
    },
  },
  reducers: {
    initializeForm(state, { payload }) {
      state.data = { ...payload };
    },
    editForm(state, { payload }) {
      if (payload.isBank) {
        state.data.bank = {
          ...state.data,
          bank: payload.bank,
          [payload.key]: payload.value,
        };
      } else {
        state.data[payload.key] = payload.value;
      }
    },
    setError(state, { payload }) {
      state.errors[payload.key] = payload.msg;
    },
  },
});

const { actions, reducer } = employerAccountSettingsForm;

export const {
  initializeForm,
  editForm,
  setError,
} = actions;

export default reducer;
