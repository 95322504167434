import { createSlice } from '@reduxjs/toolkit';

const referral = createSlice({
  name: 'referral',
  initialState: {
    isLoading: null,
    referrals: null,
    requestedReferral: null,
    requestedReferralUuid: null,
  },
  reducers: {
    referralRequest(state, { payload }) {
      state.isLoading = true;
    },
    referralSuccess(state, { payload }) {
      state.referral = payload.data;
      state.isLoading = false;
    },
    referralFailure(state) {
      state.isLoading = false;
    },
    confirmReferralRequest(state) {
      state.isLoading = true;
    },
    confirmReferralSuccess(state) {
      state.isLoading = false;
    },
    confirmReferralFailure(state) {
      state.isLoading = false;
    },
    referralRequestRequest(state) {
      state.isLoading = true;
    },
    referralRequestSuccess(state, { payload }) {
      state.requestedReferral = payload.data;
      state.isLoading = false;
    },
    referralRequestFailure(state) {
      state.isLoading = false;
    },
    setReferralRequestId(state, { payload }) {
      state.requestedReferralUuid = payload.requestedReferralUuid;
    },
    linkedinShareRequest(state) {
      state.isLinkedinShareLoading = true;
    },
    linkedinShareSuccess(state) {
      state.isLinkedinShareLoading = false;
    },
    linkedinShareFailure(state) {
      state.isLinkedinShareLoading = false;
    },
    clearRequestedReferral(state) {
      state.requestedReferralUuid = null;
      state.requestedReferral = null;
    },
  },
});

const { actions, reducer } = referral;

export const {
  referralRequest,
  referralSuccess,
  referralFailure,
  setReferralRequestId,
  referralRequestRequest,
  referralRequestSuccess,
  referralRequestFailure,
  confirmReferralRequest,
  confirmReferralSuccess,
  confirmReferralFailure,
  linkedinShareRequest,
  linkedinShareSuccess,
  linkedinShareFailure,
  clearRequestedReferral,
} = actions;

export default reducer;
