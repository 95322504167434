import { createSlice } from '@reduxjs/toolkit';

const candidateReferralForm = createSlice({
  name: 'candidateReferralForm',
  initialState: {
    candidate: {
      countryCode: {
        code: 1,
        country: 'United States',
        id: 232,
        value: 'United States',
      },
      linkedinProfile: null,
      firstName: null,
      lastName: null,
      email: null,
      phoneNumber: null,
      response: '',
      ranking: {
        ranking1: null,
        ranking2: null,
        ranking3: null,
      },
    },
    organization: null,
    job: null,
    bounty: null,
    errors: {
      linkedinProfile: null,
      email: null,
      phone: null,
    },
  },
  reducers: {
    editFormCandidate(state, { payload }) {
      if (payload.isRanking) {
        state.candidate.ranking[payload.key] = payload.value;
      } else {
        state.candidate[payload.key] = payload.value;
      }
    },
    editFormReferrer(state, { payload }) {
      state.referrer[payload.key] = payload.value;
    },
    clearForm() {
      return {
        candidate: {
          ranking: {},
        },
        referrer: {},
      };
    },
    initializeForm(state, { payload }) {
      state.candidate = payload;
    },
    loadBountyRequest(state, { payload }) {
      state.isFetching = true;
    },
    loadBountySuccess(state, { payload }) {
      state.isFetching = false;
      state.bounty = payload.data.bounty;
      state.job = payload.data.job;
      state.organization = payload.data.organization;
    },
    loadBountyFailure(state) {
      state.isFetching = false;
    },
    setError(state, { payload }) {
      state.errors[payload.key] = payload.msg;
    },
  },
});

const { actions, reducer } = candidateReferralForm;

export const {
  editFormCandidate,
  editFormReferrer,
  clearForm,
  initializeForm,
  setError,
  loadBountyRequest,
  loadBountySuccess,
  loadBountyFailure,
} = actions;

export default reducer;
