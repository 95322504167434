/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  useNavigate, useSearchParams, useParams, useLocation,
} from 'react-router-dom';
import classNames from 'classnames';
import { formatWebsite } from 'util';
import { loadOrganization } from 'actions/organizations';
import { loadBounty } from 'actions/bounty';
import toast from 'components/Alerts';
import Card from 'components/Card';
import BountiesSidebar from 'components/BountiesSidebar';
import { setError, initializeForm, editFormCandidate } from 'store/reducers/candidateReferralForm';
import { referralRequestSuccess, setReferralRequestId } from 'store/reducers/referral';
import ReferringInstructionsCard from './ReferringInstructionsCard';
import JobDescriptionCard from './JobDescriptionCard';
import {
  stepsCards,
  nextStepValidation,
  getTitle,
  getDescription,
  candidateReferralFormValidation,
  submitReferral,
} from './util';

import './ReferrerReferral.scss';

function ReferrerReferral() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { requestedReferral, requestedReferralUuid } = useSelector((state) => state.referral);
  const candidateReferralForm = useSelector((state) => state.candidateReferralForm);
  const user = useSelector((state) => state.user);
  const hasCustomQuestion = candidateReferralForm?.job?.bounty?.question;

  // Pathname values
  const step = parseInt(searchParams.get('step')) || 1;
  const { bountySlug, companySlug, slug } = params;
  const showJobs = location.pathname.includes('bounties') && step !== 3;
  const currentStepCard = stepsCards[step];

  useEffect(() => {
    if (showJobs) {
      dispatch(loadOrganization(companySlug))
        .then((res) => {
          if (res.status === 200) {
            if (!bountySlug) {
              const bounties = res.data.data.departments[0].jobs.filter((el) => el.bounty);
              const currentBounty = bounties[0]?.bounty.slug;
              navigate(`${currentBounty}`);
            }
          }
        });
    }
    if (slug || bountySlug) {
      dispatch(loadBounty(slug || bountySlug)).then((res) => {
        if (res.response?.status === 404) {
          navigate('/referrer');
        }
      });
    }
  }, []);

  useEffect(() => {
    if (bountySlug) {
      dispatch(loadBounty(bountySlug));
    }
  }, [bountySlug]);

  useEffect(() => {
    if ((step !== 1 && !candidateReferralForm.candidate.firstName && !requestedReferral)) {
      setSearchParams({ step: 1 });
    }
    if (searchParams.get('submit')) {
      if (sessionStorage.getItem('requestedReferral')) {
        const requestedReferral = JSON.parse(sessionStorage.getItem('requestedReferral'));
        dispatch(referralRequestSuccess({ data: requestedReferral }));
        dispatch(setReferralRequestId({ requestedReferralUuid: sessionStorage.getItem('requestedReferralUuid') }));
      } else {
        dispatch(initializeForm(JSON.parse(localStorage.getItem('referralInfo'))));
      }
      onSubmit();
    }
  }, [step]);

  const onNextPage = () => {
    if (step === 1 || !step) {
      const { candidate } = candidateReferralForm;
      const { validEmail, validNumber, validLinkedin } = candidateReferralFormValidation(candidate);
      if (!validEmail) {
        dispatch(setError({ key: 'email', msg: 'Please enter a valid email address' }));
      }
      if (!validNumber) {
        dispatch(setError({ key: 'phoneNumber', msg: 'Please enter a valid phone number' }));
      }
      if (!validLinkedin) {
        dispatch(setError({ key: 'linkedinProfile', msg: 'Please enter a valid Linkedin profile' }));
      }
      if (validNumber && validEmail && validLinkedin) {
        setSearchParams({ step: 2 });
      }
    }
    if (step === 2) {
      if (user.token) {
        onSubmit();
      } else {
        setSearchParams({ step: 3 });
      }
    }
  };

  const onBackPage = () => {
    setSearchParams({ step: step - 1 });
  };

  const onSubmit = () => {
    dispatch(
      submitReferral(
        {
          companySlug,
          candidateReferralForm,
          slug: slug || bountySlug,
          linkedinProfile: formatWebsite(candidateReferralForm.candidate.linkedinProfile),
        },
        requestedReferralUuid,
      )).then((res) => {
      if (res.status === 201) {
        navigate(`/b/${slug || bountySlug}/submitted`);
        toast({ title: 'Success', subtitle: 'Your referral has been submitted' });
      } else {
        localStorage.removeItem('referralInfo');
        localStorage.removeItem('referralSlug');
        localStorage.removeItem('companySlug');
      }
    });
  };

  const onSelectJob = (bountySlug) => {
    navigate(`/c/${params.companySlug}/bounties/${bountySlug}`);
  };

  return (
    <div className="consumer-referral">
      <div className="left-column">
        { showJobs
          && (
          <BountiesSidebar
            companySlug={params.companySlug}
            onClick={({ bounty }) => onSelectJob(bounty.slug)}
          />
          ) }
        <Card
          variant="dynamic"
          title={getTitle(step, requestedReferral)}
          subtitle={getDescription(step)}
          className={classNames(
            showJobs && 'hide-border-radius',
            step === 3 && 'center-card',
          )}
          buttons={[
            (step !== 1 && !requestedReferral) && {
              id: 'back',
              text: 'Back',
              variant: 'reverse-secondary',
              onClick: onBackPage,
            },
            step !== 3 && {
              id: 'card-form',
              type: 'submit',
              text: step === 2 ? 'Submit' : 'Next',
              variant: !nextStepValidation(step, candidateReferralForm, hasCustomQuestion) ? 'disabled' : 'default',
              onClick: onNextPage,
            },
          ]}
          progressBar={{
            length: 3,
            progress: step,
          }}
        >
          { currentStepCard }
        </Card>
      </div>
      { step !== 3 && (
      <div className="right-column">
        <ReferringInstructionsCard
          job={candidateReferralForm.job}
          companySlug={candidateReferralForm.organization?.slug}
        />
        <JobDescriptionCard job={candidateReferralForm.organization} />
      </div>
      )}
    </div>
  );
}

export default ReferrerReferral;
