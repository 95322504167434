import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import Button from 'components/Button';
import Card from 'components/Card';
import './NotFound.scss';

function NotFound() {
  const navigate = useNavigate();

  return (
    <div className="not-found">
      <Helmet title="Not found | Refer" />
      <Card title="Oops! Page not found." variant="small" subtitle="Sorry we couldn't find this page.">
        <Button onClick={() => navigate('/')} text="Back to home" />
      </Card>
    </div>
  );
}

export default NotFound;
