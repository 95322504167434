import { referralStatusNew, prettifyLinkedinUrl } from 'util';

function ReferralContent({ item }) {
  const { referral, organization, job } = item;

  const rankingInfo = referral.ranking.map((el) => (
    <div className="mt-10">
      <p className="bold">{el.question}</p>
      <p className="">{el.option}</p>
    </div>
  ));

  return (
    <div className="referral-sidebar__body">
      <h3 className="referral-sidebar__body-name mt-5">{ `${referral.firstName} ${referral.lastName}` }</h3>
      <div className="flex-column">
        <div className="flex-column">
          <p className="bold">Linkedin</p>
          <a href={referral.linkedinProfile} target="_blank" rel="noreferrer">{prettifyLinkedinUrl(referral.linkedinProfile)}</a>
        </div>
        <div className="flex-column mt-10">
          <p className="bold">Email</p>
          <p>{referral.email}</p>
        </div>
        <div className="flex-column mt-10">
          <p className="bold">Phone</p>
          <p>{referral.phone?.localized}</p>
        </div>
      </div>
      {rankingInfo}
      { job.bounty.question && (
      <div className="mt-10" key={job.bounty.question}>
        <p className="bold">{job.bounty.question}</p>
        <p className="">{referral.response}</p>
      </div>
      ) }
      <h3 className="mt-25">Referred to</h3>
      <div className="flex-row align-center justify-between bold mt-10">
        <p>{organization.name}</p>
        <p>{job.bounty.referral.localized}</p>
      </div>
      <div className="flex-row align-center justify-between grey-text">
        <p>{job.title}</p>
        <p>{referralStatusNew(referral.dates)}</p>
      </div>
    </div>
  );
}

export default ReferralContent;
