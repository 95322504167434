/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-unescaped-entities */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import SignupModal from 'components/SignupModal';
import { prettifyLocations } from 'util';
import ButtonGroup from 'components/ButtonGroup';
import { loadOpenJobs } from 'actions/jobs';
import Button from 'components/Button';
import Home1 from 'images/home-referrer-1.webp';
import LinkedinSignup from 'components/LinkedinSignup';
import Footer from '../Footer';
import './Home.scss';

function Home() {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.user);
  const { shuffledOpenJobs } = useSelector((state) => state.openJobs);
  const [isModalOpen, openSignupModal] = useState();

  useEffect(() => {
    if (!shuffledOpenJobs.length) {
      dispatch(loadOpenJobs());
    }
  }, []);

  const openJobsCards = shuffledOpenJobs.slice(0, 10).map(({
    organization, title, locations, salary, bounty,
  }) => (
    <div className="mini-card">
      <div>
        <img src={organization.links.logo.medium} alt="decorative" />
        <strong>{title}</strong>
        <p className="grey-text">{`${organization.name} (${organization.funding?.value})`}</p>
        <p className="locations grey-text">{prettifyLocations(locations)}</p>
        <p>
          {
            salary && (
            <p className="grey-text">
              {salary.localized?.min ? `${salary.localized?.min} - ` : ''}
              {salary.localized?.max || '' }
            </p>
            )
          }
        </p>
        <strong className="green-text f18 mt-5">{bounty.referral.localized}</strong>
      </div>
      <Button className="mt-10" size="small" text="Refer" to={`/c/${organization.slug}/j/${bounty.slug}`} />
    </div>
  ));

  return (
    <div className="home">
      <Helmet title="Refer" />
      <div className="section-1 home-block">
        <div className="section-1__left-screen home-block__left-screen">
          <div className="container">
            <h2 className="home-title section-1-title">
              Activate your network
            </h2>
            <div className="flex-column">
              <p className="home-description section-1-description">Earn or offer referral rewards for hiring top talent.</p>
              <ButtonGroup
                className="justify-start mt-25"
                items={[
                  {
                    id: '1',
                    text: 'I know great talent',
                    variant: 'white',
                    to: '/refer',
                  },
                  {
                    id: '2',
                    text: 'I’m hiring',
                    variant: 'white-reverse',
                    to: '/employer',
                  },
                ]}
              />
            </div>
          </div>
        </div>
        <div className="section-1__right-screen">
          <div className="section-1__right-screen-image">
            <img className="w-100" src={Home1} alt="decoration" />
          </div>
        </div>
      </div>
      <div className="section-2 ">
        <div>
          <h2 className="home-title">Discover top talent</h2>
          <p className="home-description">Here’s a sample of active bounties live on the platform</p>
          <div className="flex-row mini-cards">
            {openJobsCards}
          </div>
          { !token && (
            <div
              className="green-text f18 pointer"
              onClick={() => openSignupModal(true)}
            >
              Sign up to view more
            </div>
          )}
        </div>
      </div>
      <div className="section-3-home">
        <div>
          <h2 className="home-title">The benefits of referrals</h2>
          <div className="flex-row benefit-blocks justify-between">
            <div className="flex-column benefit-block">
              <h2>30X</h2>
              <p className="bold">Interview rate</p>
              <p className="description">Personal recommendations amplify discovery and break through the noise</p>
            </div>
            <div className="flex-column benefit-block">
              <h2>55%</h2>
              <p className="bold">Faster time to hire</p>
              <p className="description">Enjoy a more efficient hiring process with an engaged candidate pipeline</p>
            </div>
            <div className="flex-column benefit-block">
              <h2>70%</h2>
              <p className="bold">Higher retention</p>
              <p className="description">Deeper personal connections between candidates and your company from the start</p>
            </div>
          </div>
        </div>
      </div>
      { !token && (
      <div className="home-cta flex-row justify-between home-cta-custom-padding">
        <h2>Sign up today to get started</h2>
        <LinkedinSignup />
      </div>
      )}
      <Footer className="footer-custom-padding" />
      <SignupModal isModalOpen={isModalOpen} openSignupModal={openSignupModal} />
    </div>
  );
}

export default Home;
