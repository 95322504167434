import Candidate from './Candidate';
import DateReferred from './DateReferred';
import Company from './Company';
import ReferredBy from './ReferredBy';
import Status from './Status';
import Action from './Action';

const tableColumns = {
  candidate: Candidate,
  company: Company,
  referredBy: ReferredBy,
  dateReferred: DateReferred,
  status: Status,
  action: Action,
};

export default tableColumns;
