/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Checkbox from 'components/Checkbox';
import Input from 'components/Input';
import Icon from 'components/Icon';
import './ListItem.scss';

function ListItem({
  item,
  showCheckbox,
  isSelected,
  onClick,
  fieldName,
  highlight = true,
  greenSubtitle,
  readOnly,
  showCustom,
  footer,
  hoverEffect = true,
  hoverDeleteIconAction,
  hoverEditIconAction,
  showEditIcon,
  variation = 'small',
  onChangeInput,
  className,
  to,
}) {
  const {
    title, text, subtitle, icon, avatar,
  } = item || {};
  const [inputValue, onChangeInputValue] = useState('');

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    if (item.onClick) {
      item.onClick();
    }
  };

  const onChange = (value) => {
    onChangeInputValue(value);
    onChangeInput(value);
  };

  if (item.type) {
    return (
      <div className="list-item">
        <Input
          id="list-input"
          placeholder={item.text}
          value={inputValue}
          icon={icon}
          onChange={onChange}
          disableAnimation
        />
      </div>
    );
  }

  const Element = to ? Link : 'div';
  return (
    <div
      onClick={!footer ? handleClick : null}
      className={
        classNames(
          'list-item',
          'pointer',
          footer ? 'list-item--with-footer' : `list-item--${variation}`,
          'field',
          isSelected && 'list-item--selected',
          readOnly && 'list-item--read-only',
          hoverEffect && 'list-item--hover-default pointer',
          className,
        )
      }
    >
      <Element className="list-item--row" to={to} onClick={(footer && !to) ? handleClick : null}>
        <div>
          { icon && (
          <Icon className="mr-10 list-item--row-icon" name={icon} />
          )}
          { avatar && <img className="mr-15 list-item__avatar" src={avatar} alt="company-avatar" />}
          <div className="list-item--row__content">
            <div className="list-item--row__content-text">
              { fieldName && <p className="fieldName">{ fieldName }</p>}
              <p
                className={
                  classNames('list-item--row__content-text-title',
                    highlight && '--highlight',
                    fieldName && '--with-fieldName',
                  )
                }
              >
                {text || title}
              </p>
              { subtitle && (
                <p
                  className={classNames(
                    'list-item--row__content-text-subtitle',
                    greenSubtitle && 'list-item--row__content-text-subtitle--green',
                  )}
                >
                  {subtitle}
                </p>
              )}
            </div>
          </div>
        </div>
        <div>
          { hoverDeleteIconAction && <Icon onClick={() => hoverDeleteIconAction(item)} name="bin" className="hover-delete-icon" />}
          { hoverEditIconAction && <Icon onClick={() => hoverEditIconAction(item)} name="pencil" className="hover-edit-icon" />}
          { showEditIcon && <Icon name="pencil" />}
          { showCheckbox && <Checkbox isSelected={isSelected} /> }
          { showCustom && showCustom() }
        </div>
      </Element>
      { footer && footer() }
    </div>
  );
}

export default ListItem;

ListItem.propTypes = {
  fieldName: PropTypes.string,
  greenSubtitle: PropTypes.bool,
  highlight: PropTypes.bool,
  /* Show delete icon on hover + perform action */
  hoverDeleteIconAction: PropTypes.func,
  /* Show edit icon on hover + perform action */
  hoverEditIconAction: PropTypes.func,
  /* Highlight item on hover */
  hoverEffect: PropTypes.bool,
  isSelected: PropTypes.bool,
  showCheckbox: PropTypes.bool,
  /* Show custom element on the right end of item */
  showCustom: PropTypes.func,
  /* Show edit icon statically */
  showEditIcon: PropTypes.bool,
  onClick: PropTypes.func,
  readOnly: PropTypes.bool,
  /* Convert to Link component. Only available for listItems with a footer */
  to: PropTypes.string,
};
