/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import Card from 'components/Card';
import Input from 'components/Input';
import {
  authSuccess, clearSignupForm, editSignupForm, setError,
} from 'store/reducers/user';
import { useDispatch, useSelector } from 'react-redux';
import { candidateReferralFormValidation } from 'pages/ReferrerReferral/util';
import { loadBootstrap } from 'actions';
import { inviteUser } from 'actions/user';
import { formatWebsite } from 'util';
import { continueWithReferralFlow } from 'util/authorization';

function SignupForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const {
    firstName, lastName, email, linkedinProfile, uuid, errors = {},
  } = useSelector((state) => state.user.signupForm);
  const canContinue = firstName && lastName && email && linkedinProfile;
  const isCompany = params.userType === 'company';

  useEffect(() => {
    if (!uuid) navigate('/');
    return () => {
      dispatch(clearSignupForm());
    };
  }, []);

  const onChange = (key, value) => {
    dispatch(editSignupForm({ key, value }));

    // If user is typing, remove error
    if (errors[key]) {
      dispatch(setError({ key, msg: null }));
    }
  };

  const onNext = () => {
    const { validEmail, validLinkedin } = candidateReferralFormValidation({ email, linkedinProfile });
    if (!validEmail) {
      dispatch(setError({ key: 'email', msg: 'Please enter a valid email address' }));
    }
    if (!validLinkedin) {
      dispatch(setError({ key: 'linkedinProfile', msg: 'Please enter a valid Linkedin profile' }));
    }

    if (validEmail && validLinkedin) {
      dispatch(inviteUser({
        firstName, lastName, email, linkedinProfile: formatWebsite(linkedinProfile), uuid,
      })).then((res) => {
        const { data = {} } = res.data;
        if (res.status === 201) {
          dispatch(authSuccess({ data: { token: data.token } }));
          dispatch(loadBootstrap());
          localStorage.setItem('userStatus', data.status.account);

          // User was about to confirm a referral.
          if (localStorage.getItem('confirmReferral')) {
            navigate(localStorage.getItem('confirmReferral'));
            localStorage.removeItem('confirmReferral');

            // User was referring someone. We'll clear localStorage after they submit the data.
          } else if (localStorage.getItem('referralInfo')) {
            continueWithReferralFlow(navigate);

            // User logged in from Home. Continue as usual.
          } else {
            navigate(isCompany ? '/signup/organization' : '/u/profile');
          }
        }
      });
    }
  };

  return (
    <>
      <Helmet title="Your information | Refer" />
      <Card
        title="Your information"
        subtitle="Please confirm your information below"
        buttons={[
          {
            id: 'next',
            text: 'Next',
            variant: !canContinue ? 'disabled' : 'default',
            onClick: onNext,
          },
        ]}
      >
        <div className="flex-row">
          <Input
            className="w-100 mr-10"
            id="name"
            placeholder="First name"
            onChange={(value) => dispatch(editSignupForm({ key: 'firstName', value }))}
            value={firstName}
          />
          <Input
            className="w-100"
            id="lastName"
            placeholder="Last name"
            onChange={(value) => dispatch(editSignupForm({ key: 'lastName', value }))}
            value={lastName}
          />
        </div>
        <Input
          id="email"
          placeholder={errors.email || 'What is your email?'}
          onChange={(value) => onChange('email', value)}
          value={email}
          error={!!errors.email}
        />
        <Input
          id="linkedin"
          placeholder={errors.linkedinProfile || 'What is your Linkedin URL?'}
          onChange={(value) => onChange('linkedinProfile', value)}
          value={linkedinProfile}
          error={!!errors.linkedinProfile}
        />
      </Card>
    </>
  );
}

export default SignupForm;
