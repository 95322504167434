/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ListItem from 'components/ListItem';

function List({
  items, onSelectItem, showCheckbox = false, withLocationsSubtitles, className, ...props
}) {
  const [isSelected, setSelectedItem] = useState(null);

  const onClick = (item) => {
    setSelectedItem(item.id);
    if (onSelectItem) {
      onSelectItem(item);
    }
  };

  const children = items.map((item) => {
    if (!item) return;
    return (
      <ListItem
        key={item.id || item.uuid}
        showCheckbox={showCheckbox}
        isSelected={item.id === isSelected}
        onClick={() => onClick(item)}
        item={item}
        className={className}
        {...props}
      />
    );
  });

  return (
    <div className="list">
      {children}
    </div>
  );
}

export default List;

List.propTypes = {
  items: PropTypes.array,
  onSelectItem: PropTypes.func,
};
