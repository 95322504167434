/* eslint-disable react/style-prop-object */
/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */

import './Privacy.scss';

function Privacy() {
  return (
    <div className="privacy">
      <div className="privacy-container">
        <h1>Refer Privacy Policy</h1>
        <p>
          <span className="bold">Last updated:&nbsp;</span>
          <span>December 14, 2022</span>
        </p>
        <p>
          <span>Refer by Struck Studio Inc. (</span>
          <span className="bold">"Refer" </span>
          <span className="bold">"we", </span>
          <span className="bold">"our", </span>
          <span>and/or </span>
          <span className="bold">"us"</span>
          <span>) values the privacy of individuals who use our mobile applications, websites, and related services (collectively, our </span>
          <span className="bold">"Services"</span>
          <span>). This privacy policy (the </span>
          <span className="bold">"Privacy Policy"</span>
          <span>) explains how we collect, use, and share information from users of our Services (</span>
          <span className="bold">"Users"</span>
          <span>) to facilitate employers in rewarding referrals from individuals in their network and to continue to improve our Services. By using our Services, you agree to the collection, use, disclosure, and procedures this Privacy Policy describes. Beyond the Privacy Policy, your use of our Services is also subject to our&nbsp;</span>
          <span>Terms of Service</span>
          <span>.</span>
        </p>
        <p><span className="bold">Information We Collect</span></p>
        <p><span>We may collect a variety of information from or about you or your devices from various sources, as described below.</span></p>
        <p className="bold">
          <span>A</span>
          <span>.&nbsp;</span>
          <span>Information You Provide to Us</span>
          <span>.</span>
        </p>
        <p>
          <span className="bold">Registration and Profile Information</span>
          <span>. When you sign up for an account, register to use our Services, sign up for notifications or updates, or participate in our surveys, we may ask you for your name, email address, phone number, address, title, employer, work authorization &amp; visa sponsorship status, salary, educational history, employment history, continuing education, accreditations and certifications, training, and photos. If you provide a link to a social media platform such as LinkedIn, we will also receive information from that social media platform such as your name, educational history, phone number, employer, title, educational history, and employment history.</span>
        </p>
        <p>
          <span className="bold">Communications</span>
          <span>. If you contact us directly, we may receive additional information about you. For example, when you contact our customer support team, we may receive your name, email address, phone number, the contents of a message or attachments that you may send to us, and other information you choose to provide. If you subscribe to our content, then we will collect certain information from you, such as your email address. When we send you emails, we may track whether you open them to learn how to deliver a better customer experience and improve our Services.</span>
        </p>
        <p>
          <span className="bold">Careers</span>
          <span>. If you decide that you wish to apply for a job with us, you may submit your contact information online. You may also apply through LinkedIn. If you do so, we will collect the information you make available to us on LinkedIn.&nbsp;</span>
        </p>
        <p>
          <span className="bold">Financial Account Information</span>
          <span>. If you make a referral through our Services that is successful, we may collect your financial account information, such as bank account or other financial information, to pay your bounty for the referral. If you hire and onboard an employee through our Services, we may collect your financial account information to withdraw bounty payment and service fees. Payments will be handled through integration with our service provider, Stripe.&nbsp;</span>
        </p>
        <p className="bold">
          <span>B</span>
          <span>.&nbsp;</span>
          <span>Information We Collect When You Use Our Services</span>
          <span>.&nbsp;</span>
        </p>
        <p>
          <span className="bold">Location Information</span>
          <span>. When you use our Services, we infer your general location information, for example by using your internet protocol (IP) address.&nbsp;</span>
        </p>
        <p>
          <span className="bold">Device Information</span>
          <span>. We receive information about the device and software you use to access our Services, including IP address, web browser type, operating system version, phone carrier and manufacturer, application installations, device identifiers, mobile advertising identifiers, and push notification tokens.&nbsp;</span>
        </p>
        <p>
          <span className="bold">Usage Information</span>
          <span>. To help us understand how you use our Services and to help us improve them, we automatically receive information about your interactions with our Services, like the pages or other content you view, any content you post, and the dates and times of your visits.</span>
        </p>
        <p>
          <span className="bold">Your Contacts</span>
          <span>. We collect your contacts if you invite them to use our Services or share information or content with them from our Services.</span>
        </p>
        <p>
          <span className="bold">Information from Cookies and Similar Technologies</span>
          <span>.</span>
          <span>&nbsp;</span>
          <span>We and our third-party partners collect information using cookies, pixel tags, or similar technologies.&nbsp;</span>
          <span>Our third-party partners, such as analytics and advertising partners, may use these technologies to collect information about your online activities over time and across different services.&nbsp;</span>
          <span>Cookies are small text files containing a string of alphanumeric characters. We may use both session cookies and persistent cookies. A session cookie disappears after you close your browser. A persistent cookie remains after you close your browser and may be used by your browser on subsequent visits to&nbsp;</span>
          <span>our Services</span>
          <span>.</span>
        </p>
        <p><span>Please review your web browser&rsquo;s &ldquo;Help&rdquo; file to learn the proper way to modify your cookie settings. Please note that if you delete or choose not to accept cookies from the Service, you may not be able to utilize the features of the Service to their fullest potential.</span></p>
        <p className="bold">
          <span>C</span>
          <span>.</span>
          <span>&nbsp;Information We Receive from Third Parties</span>
          <span>.&nbsp;</span>
        </p>
        <p>
          <span className="bold">Information from Third Party Services</span>
          <span>.</span>
          <span>&nbsp;</span>
          <span>If you choose to link our Services to a third-party account, we may receive information about you, including your profile information and your photo, and your use of the third-party account. If you wish to limit the information available to us, you should visit the privacy settings of your third-party accounts to learn about your options.</span>
        </p>
        <p>
          <span className="bold">Other Third Parties</span>
          <span>.</span>
          <span>&nbsp;</span>
          <span>We may receive additional information about you, such as contact information, from individuals referring you for an employment opportunity and combine it with other information we have about you.&nbsp;</span>
        </p>
        <p className="bold"><span>How We Use the Information We Collect</span></p>
        <p><span>We use the information we collect:</span></p>
        <ul>
          <li aria-level="1">
            <p><span>To enable core functionality of account creation, necessary integrations, opportunity discovery, referral creation, and referral review;</span></p>
          </li>
          <li aria-level="1">
            <p><span>To assess the quality of referrers and candidates and facilitate the hiring process;</span></p>
          </li>
          <li aria-level="1">
            <p><span>To otherwise provide, maintain, improve, and enhance our Services;</span></p>
          </li>
          <li aria-level="1">
            <p><span>To personalize your experience on our Services such as by providing tailored content and recommendations;&nbsp;</span></p>
          </li>
          <li aria-level="1">
            <p><span>To understand and analyze how you use our Services and develop new products, services, features, and functionality;</span></p>
          </li>
          <li aria-level="1">
            <p><span>To communicate with you, provide you with updates and other information relating to our Services, provide information that you request, respond to comments and questions, and otherwise provide customer support;</span></p>
          </li>
          <li aria-level="1">
            <p>
              <span>For marketing and advertising purposes,&nbsp;</span>
              <span>such as developing and providing promotional and advertising materials that may be relevant, valuable or otherwise of interest to you;</span>
            </p>
          </li>
          <li aria-level="1">
            <p><span>To generate anonymized, aggregate data containing only de-identified, non-personal information that we may use for any lawful purpose;</span></p>
          </li>
          <li aria-level="1">
            <p><span>To manage payments to referrers and otherwise facilitate transactions and payments;</span></p>
          </li>
          <li aria-level="1">
            <p><span>To find and prevent fraud, and respond to trust and safety issues that may arise;&nbsp;</span></p>
          </li>
          <li aria-level="1">
            <p>
              <span>For compliance purposes, including&nbsp;</span>
              <span>enforcing our Terms of Services or other legal rights, or as may be required by applicable laws and regulations or requested by any judicial process or governmental agency; and</span>
            </p>
          </li>
          <li aria-level="1">
            <p><span>For other purposes for which we provide specific notice at the time the information is collected.</span></p>
          </li>
        </ul>
        <p className="bold"><span>How We Share the Information We Collect</span></p>
        <p>
          <span className="bold">Sharing Between Referrers, Job Candidates, and Employers</span>
          <span>.</span>
          <span>&nbsp;</span>
          <span>If you submit a referral in response to a bounty, we will share your name, LinkedIn profile, referred candidate assessment, and relevant profile attributes (e.g., current role, relationship to hiring company, relationship to referred candidate) with the employer who posted the bounty and received your referral. If you are a job candidate referred by another user of our Services, we will share your name, LinkedIn profile, email address, and phone number with the employer for the job for which you were referred if you choose to have your information shared with that employer. We may also share your visa sponsorship status as relevant.</span>
        </p>
        <p>
          <span className="bold">Affiliates</span>
          <span>.</span>
          <span>&nbsp;</span>
          <span>We may share any information we receive with our affiliates for any of the purposes described in this Privacy Policy.&nbsp;</span>
        </p>
        <p>
          <span className="bold">Vendors and Service Providers</span>
          <span>. We may share any information we receive with vendors and service providers retained in connection with the provision of&nbsp;</span>
          <span>our Services</span>
          <span>.&nbsp;</span>
        </p>
        <p>
          <span className="bold">Marketing</span>
          <span>.&nbsp;</span>
          <span>We do not rent, sell, or share information about you with non affiliated companies for their direct marketing purposes, unless we have your permission</span>
          <span>.&nbsp;</span>
        </p>
        <p>
          <span className="bold">Analytics Partners</span>
          <span>. We use analytics services such as Google Analytics to collect and process certain analytics data. These services may also collect information about your use of other websites, apps, and online resources. You can learn more about Google&rsquo;s practices by visiting</span>
          <a href="https://www.google.com/policies/privacy/partners/">
            <span>&nbsp;</span>
            <span>https://www.google.com/policies/privacy/partners/</span>
          </a>
          <span>.&nbsp;</span>
        </p>
        <p>
          <span className="bold">As Required by Law and Similar Disclosures</span>
          <span>.</span>
          <span>&nbsp;</span>
          <span>We may access, preserve, and disclose your information if we believe doing so is required or appropriate to: (a) comply with law enforcement requests and legal process, such as a court order or subpoena; (b) respond to your requests; or (c) protect your, our, or others&rsquo; rights, property, or safety. For the avoidance of doubt, the disclosure of your information may occur if you post any objectionable content on or through the&nbsp;</span>
          <span>Services</span>
          <span>.</span>
        </p>
        <p>
          <span className="bold">Merger, Sale, or Other Asset Transfers</span>
          <span>.</span>
          <span>&nbsp;</span>
          <span>We may transfer your information to service providers, advisors, potential transactional partners, or other third parties in connection with the consideration, negotiation, or completion of a corporate transaction in which we are acquired by or merged with another company, or we sell, liquidate, or transfer all or a portion of our assets. The use of your information following any of these events will be governed by the provisions of this Privacy Policy in effect at the time the applicable information was collected.</span>
        </p>
        <p>
          <span className="bold">Consent</span>
          <span>.</span>
          <span>&nbsp;</span>
          <span>We may also disclose your information with your permission.</span>
        </p>
        <p><span className="bold">Your Choices</span></p>
        <p>
          <span className="bold">Marketing Communications</span>
          <span>.</span>
          <span>&nbsp;</span>
          <span>You can unsubscribe from our promotional emails via the link provided in the emails. Even if you opt out of receiving promotional messages from us, you will continue to receive administrative messages from us.</span>
        </p>
        <p><span className="bold">If you choose not to provide us with information we collect, some features of our Services may not work as intended.</span></p>
        <p><span className="bold">Third Parties</span></p>
        <p><span>Our Services may contain links to other websites, products, or services that we do not own or operate. We are not responsible for the privacy practices of these third parties. Please be aware that this Privacy Policy does not apply to your activities on these third-party services or any information you disclose to these third parties. We encourage you to read their privacy policies before providing any information to them.</span></p>
        <p><span className="bold">Security&nbsp;</span></p>
        <p><span>We make reasonable efforts to protect your information by using physical and electronic safeguards designed to improve the security of the information we maintain. However, as no electronic transmission or storage of information can be entirely secure, we can make no guarantees as to the security or privacy of your information.</span></p>
        <p><span className="bold">Children&rsquo;s Privacy</span></p>
        <p><span>We do not knowingly collect, maintain, or use personal information from children under 13 years of age, and no part of our Services is directed to children. If you learn that a child has provided us with personal information in violation of this Privacy Policy, then you may alert us at privacy@getreferred.co.&nbsp;</span></p>
        <p><span className="bold">International Visitors</span></p>
        <p>
          <span>Our Services are</span>
          <span>&nbsp;hosted in the United States and intended for visitors located within the United States. If you choose to use&nbsp;</span>
          <span>the Services</span>
          <span>&nbsp;from the European Union or other regions of the world with laws governing data collection and use that may differ from U.S. law, then please note that you are transferring your personal information outside of those regions to the United States for storage and processing. Also, we may transfer your data from the U.S. to other countries or regions in connection with storage and processing of data, fulfilling your requests, and operating the&nbsp;</span>
          <span>Services</span>
          <span>. By providing any information, including personal information, on or to the&nbsp;</span>
          <span>Services</span>
          <span>, you consent to such transfer, storage, and processing.</span>
        </p>
        <p><span className="bold">Update Your Information&nbsp;</span></p>
        <p><span>You can update your account and profile information or close your account by contacting us at support@getreferred.co.&nbsp;</span></p>
        <p><span className="bold">Changes to this Privacy Policy</span></p>
        <p>
          <span>We will post any adjustments to the Privacy Policy on this page, and the revised version will be effective when it is posted. If we materially change the ways in which we use or share personal information previously collected from you through&nbsp;</span>
          <span>the Services</span>
          <span>, we will notify you through&nbsp;</span>
          <span>the Services</span>
          <span>, by email, or other communication.</span>
        </p>
        <p><span className="bold">Contact Information</span></p>
        <p>
          <span>If you have any questions, comments, or concerns about our processing activities, please email us at&nbsp;</span>
          <a href="mailto:privacy@getreferred.co"><span>privacy@getreferred.co</span></a>
          <span>&nbsp;or write to us at 2908 Colorado Ave, Santa Monica, CA 90404.</span>
        </p>
      </div>
    </div>
  );
}

export default Privacy;
