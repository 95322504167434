import Icon from 'components/Icon';
import './Facet.scss';

function Facet({ onClose, name }) {
  return (
    <div className="facet">
      <p>{ name }</p>
      <Icon name="close" onClick={onClose} styleProps={{ width: 10, height: 10, fill: '#64637b' }} />
    </div>
  );
}

export default Facet;
