/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import BountiesSidebar from 'components/BountiesSidebar';
import Table from 'components/Table';
import './EmployerReferrals.scss';
import { loadJobs } from 'actions/jobs';
import { loadApplications } from 'actions/referrals';
import SideBar from 'components/SideBar';
import { isAdmin } from 'util';
import Dropdown from 'components/Dropdown';
import { trackEvent } from 'util/tracking';
import ReactModal from 'react-modal';
import { completeEducation, getEducation } from 'actions/ui';
import ReferralSidebarContent from './ReferralSidebarContent';
import ReferralBody from './ReferralSidebarContent/ReferralBody';
import { Onboarding } from './Onboarding';

function EmployerReferrals() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const [isSideBarOpen, openSideBar] = useState(false);
  const [itemIndex, setItemIndex] = useState();
  const { educationCompleted } = useSelector((state) => state.ui);
  const { referrals, isFetching } = useSelector((state) => state.referralsTable);
  const { jobs } = useSelector((state) => state.jobs);
  const user = useSelector((state) => state.user);
  const jobsWithBounty = jobs.filter((job) => job.bounty);
  const currentBountyId = params.id || jobsWithBounty[0]?.uuid;
  const currentBounty = jobsWithBounty.filter((el) => el.uuid === currentBountyId)[0]?.bounty;
  const currentOrganization = user.organizations.filter((org) => org.slug === params.companySlug)[0];
  const ouid = currentOrganization?.uuid;

  const filteredReferrals = referrals.filter((referral) => referral.job.uuid === currentBountyId);
  const visibleItems = !currentBountyId ? referrals : filteredReferrals;

  useEffect(() => {
    if (!jobs) dispatch(loadJobs(ouid));

    dispatch(loadJobs(ouid));
    dispatch(getEducation(ouid));
    dispatch(loadApplications(ouid));
  }, [params.companySlug]);

  const onSelectBounty = (jobId) => {
    navigate(`/c/${params.companySlug}/referrals/${jobId}`);
  };

  const onClickItem = (itemIndex) => {
    trackEvent('Employer: View applicant');
    setItemIndex(itemIndex);
    openSideBar(true);
  };

  return (
    <>
      <Helmet title="Bounties and referrals | Refer" />
      <div className="employer-referral">
        <div className="left-column">
          <BountiesSidebar
            onClick={({ uuid }) => onSelectBounty(uuid)}
            items={jobsWithBounty}
            currentBounty={currentBountyId}
            createBountyButton
          />
        </div>
        <div className="table-wrapper">
          <div className="flex-row justify-between table-wrapper__header">
            <div>
              <h2>
                Referrals (
                {visibleItems.length || 0}
                )
              </h2>
              <p>Below are the referrals you have received for this role.</p>
            </div>
            {
              isAdmin(user.email) && (
              <Dropdown
                placeholder="Organization"
                className="orgs-dropdown"
                items={user.organizations.map(
                  (org) => ({ name: org.name, id: org.slug }))}
                onClick={({ id }) => navigate(`/c/${id}/referrals`)}
                value={currentOrganization?.name}
              />
              )
            }
          </div>
          <Table
            items={visibleItems}
            isLoading={isFetching}
            currentBounty={currentBounty}
            onClickItem={(itemIndex) => onClickItem(itemIndex)}
          />
        </div>
      </div>
      { isSideBarOpen && (
      <SideBar openSidebar={(status) => openSideBar(status)}>
        <ReferralSidebarContent
          items={visibleItems}
          itemIndex={itemIndex}
          component={ReferralBody}
        />
      </SideBar>
      )}
      <ReactModal
        ariaHideApp={false}
        isOpen={!educationCompleted}
        onRequestClose={() => dispatch(completeEducation(ouid))}
        shouldCloseOnOverlayClick
      >
        <Onboarding
          onSkip={() => {
            dispatch(completeEducation(ouid));
          }}
        />
      </ReactModal>
    </>
  );
}

export default EmployerReferrals;
