import { useDispatch } from 'react-redux';
import { setAlert } from 'store/reducers/alerts';
import Button from 'components/Button';
import './Alert.scss';
import classNames from 'classnames';

export default function Alert({
  alert, buttonPrimary = 'Okay', buttonSecondary = 'Cancel', buttonPrimaryAction, onClose, children, className, primaryVariant = 'default', hideSecondaryButton,
}) {
  const dispatch = useDispatch();

  const onButtonPrimaryClick = () => {
    if (buttonPrimaryAction) {
      buttonPrimaryAction();
    }
    dispatch(setAlert({}));
    onClose();
  };

  const onButtonSecondaryClick = () => {
    dispatch(setAlert({}));
    onClose();
  };

  return (
    <div className={classNames('error-alert', className)}>
      <h2>{alert.title}</h2>
      <p>{alert.subtitle}</p>
      { children && (
      <div className="mt-15">
        {children}
      </div>
      )}
      <div className="error-alert__actions">
        <Button size="medium" variant={primaryVariant} text={buttonPrimary} onClick={onButtonPrimaryClick} />
        {!hideSecondaryButton && <Button size="medium" variant="reverse-secondary" text={buttonSecondary} onClick={onButtonSecondaryClick} />}
      </div>
    </div>
  );
}
