/* eslint-disable max-len */
import LogoReverse from 'images/logo-refer-reverse.png';
import { Link, useNavigate } from 'react-router-dom';
import './Home.scss';
import classNames from 'classnames';

function Footer({ className }) {
  return (
    <div className={classNames('footer', className)}>
      <div className="flex-row justify-between">
        <div className="flex-column footer-info">
          <img src={LogoReverse} alt="refer-logo" />
          <p>
            Refer enables companies to incentivize their networks, and individuals to get rewarded for referring their network.

          </p>
          <p>{`© ${new Date().getFullYear()} Refer. All rights reserved.`}</p>
        </div>
        <div className="flex-row justify-between footer-links">
          <ul>
            <li><a href="mailto: hello@getreferred.co">Contact</a></li>
            <li><Link to="/privacy">Privacy Policy</Link></li>
            <li><Link to="/terms">Terms of Service</Link></li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Footer;
