import { createSlice } from '@reduxjs/toolkit';

const bounties = createSlice({
  name: 'bounties',
  initialState: {
    isLoading: null,
    data: null,
    error: null,
    newBounty: {
      data: null,
      createBountySuccess: null,
    },
  },
  reducers: {
    bountiesRequest(state) {
      state.isLoading = true;
    },
    createBountyRequest(state) {
      state.isLoading = true;
    },
    createBountySuccess(state, { payload }) {
      state.newBounty.createBountySuccess = 'Create bounty successful';
      state.newBounty.data = payload.data;
      state.isLoading = false;
    },
    createBountyFailure(state) {
      state.isLoading = false;
    },
    createReferralRequest() {},
    createReferralSuccess() {},
    createReferralFailure() {},
    clearNewBounty(state) {
      state.newBounty = {
        data: null,
        createBountySuccess: null,
      };
    },
  },
});

const { actions, reducer } = bounties;

export const {
  bountiesRequest,
  bountiesSuccess,
  bountiesFailure,
  createBountyRequest,
  createBountySuccess,
  createBountyFailure,
  clearNewBounty,
  createReferralRequest,
  createReferralSuccess,
  createReferralFailure,
} = actions;

export default reducer;
