/* eslint-disable max-len */
import Card from 'components/Card';
import { Helmet } from 'react-helmet-async';

function GreenhouseDataUse() {
  return (
    <>
      <Helmet title="Greenhouse data use | Refer" />
      <Card
        title="Greenhouse data use"
      >
        <p>Refer understands the sensitivity of data housed in your ATS and only processes that data under the terms of our agreement, for the exclusive purpose of providing our services. The below details permissions we request when integrating with your Greenhouse instance and a brief explanation of how this data will be used within our product. Customers may choose to ‘Select All’ access permissions at their discretion for ease of integration; our use of your data, its protection, and governing terms as a data processor remain the same.</p>
        <p className="mt-20">In most cases, we only request access to the data (‘GET’) with no intent or ability to modify your existing internal data. The exception to this is in posting new candidates, prospects or applications via the platform once you accept a referral into your interview process or otherwise request access to candidate or prospect details.</p>
        <p className="mt-20 mb-10 italic">
          Note: Current functionality at present only
          {' '}
          <span className="underline">requires</span>
          {' '}
          selecting the ‘GET’ details in [Jobs, Job Posts] and the ‘Post’ details in [Candidates]. The broader requests below are to enable additional near-term functionality. Please contact
          {' '}
          <a href="mailto: support@getreferred.co" className="inline-display">support@getreferred.co</a>
          {' '}
          with any questions.
        </p>

        <table className="greenhouse-table">
          <tr>
            <td style={{ width: 140 }} className="bold">Object</td>
            <td style={{ width: 190 }} className="bold">Action(s)</td>
            <td style={{ width: 210 }} className="bold">Descriptions of usage</td>
          </tr>
          <tr>
            <td>Activity Feed</td>
            <td>GET: Retrieve Activity Feed</td>
            <td>This allows us to track referred candidate progress through stages.</td>
          </tr>
          <tr>
            <td>Applications</td>
            <td>
              <span>GET: Retrieve Applications</span>
              <br />
              <span>PATCH: Convert a prospect</span>
            </td>
            <td>This allows us to i) ensure Refer candidates are not duplicative to those already in your pipeline and ii) push an approved Refer-sourced candidate to your ATS. The associated data additionally helps in developing intelligence around optimal referrals for your business.</td>
          </tr>
          <tr>
            <td>Candidates</td>
            <td>
              <span>GET: Retrieve Candidate</span>
              <br />
              <span>POST: Add Candidate</span>
              <br />
              <span>POST: Add Prospect</span>
              <br />
              <span>POST: Add Application</span>
            </td>
            <td>This allows us to i) ensure Refer candidates are not duplicative to those already in your pipeline and ii) push an approved Refer-sourced candidate to your ATS. The associated data additionally helps in developing intelligence around optimal referrals for your business.</td>
          </tr>
          <tr>
            <td>Departments</td>
            <td>
              <span>GET: List Departments</span>
              <br />
              <span>GET: Retrieve Department</span>
            </td>
            <td>This allows us to categorize roles within your company and to harmonize across companies. </td>
          </tr>
          <tr>
            <td>Job Posts</td>
            <td>
              <span>GET: List Job Posts</span>
              <br />
              <span>GET: List Job Posts for Job</span>
              <br />
              <span>GET: Retrieve Job Post for Jobs</span>
            </td>
            <td>This gives us access to job postings and associated data/tagging to import your roles into our system.</td>
          </tr>
          <tr>
            <td>Job Stages</td>
            <td>
              <span>GET: List Job Stages for Job</span>
              <br />
              <span>GET: Retrieve Job Stage</span>
            </td>
            <td>This is necessary to name &amp; contextualize stage ids in other objects.</td>
          </tr>
          <tr>
            <td>Jobs</td>
            <td>
              <span>GET: List Jobs</span>
              <br />
              <span>GET: Retrieve Jobs</span>
              <br />
              <span>GET: Hiring Team</span>
            </td>
            <td>This enables us to join datasets across your ATS to create a unified information set. It additionally provides important tagging and filtration data such as department and assigned recruiter(s).</td>
          </tr>
          <tr>
            <td>Offers</td>
            <td>
              <span>GET: List Offers for Applications</span>
              <br />
              <span>GET: Retrieve Offer</span>
            </td>
            <td>This allows us to track referred candidates through outcomes and helps automate the onboarding start date for accepted offers.</td>
          </tr>
          <tr>
            <td>Offices</td>
            <td>
              <span>GET: List Offices</span>
              <br />
              <span>GET: Retrieve Offices</span>
            </td>
            <td>This data allows us to import job locations if specified and using the office id format.</td>
          </tr>
          <tr>
            <td>Rejection Reasons</td>
            <td>GET: List Rejection Reasons</td>
            <td>This data helps train our system on referral quality and scoring.</td>
          </tr>
          <tr>
            <td>Scorecards</td>
            <td>
              <span>GET: List Scorecards for Application</span>
              <br />
              <span>GET: Retrieve Scorecard</span>
              <br />
              <span>GET: Retrieve Application’s Scorecard</span>
            </td>
            <td>This data helps train our system on referral quality and scoring.</td>
          </tr>
          <tr>
            <td>Users</td>
            <td>GET: List Users</td>
            <td>This allows us to surface referrals and progress on each role specific to the relevant individual users in your organization.</td>
          </tr>
        </table>
      </Card>
    </>
  );
}

export default GreenhouseDataUse;
