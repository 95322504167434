import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Icon from 'components/Icon';
import { LinkedinInit } from 'actions/linkedin';
import './LinkedinSignup.scss';

function LinkedinSignup({
  text = 'Continue with LinkedIn', className, preLoginAction, variant = 'white', center,
}) {
  const onClick = () => {
    if (preLoginAction) {
      preLoginAction();
    }

    LinkedinInit().then((url) => {
      const newUrl = url.replace('https%3A//staging.getreferred.co/', encodeURIComponent(process.env.REACT_APP_AUTHORIZATION_DOMAIN));
      window.location.assign(newUrl);
    });
  };

  return (
    <div className={classNames('flex-column', center && 'align-center')}>
      <div className={classNames(className, 'field linkedin-signup')} onClick={onClick}>
        <Icon className="mr-10" name="linkedin" />
        <span className="bold">{ text }</span>
      </div>
      <p className={`privacy-text privacy-text--${variant}`}>
        By continuing, you agree to our
        {' '}
        <Link className={`privacy-link privacy-link--${variant} pointer`} to="/privacy">Privacy Policy</Link>
      </p>
    </div>
  );
}

export default LinkedinSignup;
