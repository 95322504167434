import axios from 'axios';
import { fetchData, headers } from 'actions/fetchData';
import { authRequest, authSuccess, authFailure } from 'store/reducers/user';
import { linkedinShareRequest, linkedinShareSuccess, linkedinShareFailure } from 'store/reducers/referral';

export const LinkedinInit = async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/linkedin/init`, { headers });

  if (response.status === 200) {
    return response.data.data.url;
  } else {
    throw new Error('Linkedin init failed');
  }
};

export const LinkedinAuth = ({ state, code, forceAuth }) => (dispatch, getState) => (
  fetchData({
    endpoint: `linkedin/auth?code=${code}&state=${state}`,
    actions: [authRequest, authSuccess, authFailure],
    forceAuth,
  })
)(dispatch, getState);

export const LinkedinShareText = (content) => (dispatch, getState) => (
  fetchData({
    endpoint: 'linkedin/share/text',
    actions: [linkedinShareRequest, linkedinShareSuccess, linkedinShareFailure],
    httpMethod: 'post',
    body: { content },
  })
)(dispatch, getState);
