/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Modal from 'react-modal';
import { loadUserOrganizations } from 'actions/user';
import { editOrganization, loadOrganization } from 'actions/organizations';
import { trackEvent } from 'util/tracking';
import { loadApplications } from 'actions/referrals';
import ModalContent from 'components/ModalContent';
import { employerLandscapePlaceholder } from 'util/constants';
import Card from 'components/Card';
import Button from 'components/Button';
import Icon from 'components/Icon';
import BountiesList from './BountiesList';
import EditCompanyDetails from '../EditCompanyDetails';
import { bountiesCardSubtitle, companyCardSubtitle } from './util';
import '../../EmployerProfile.scss';

function EmployerProfileMain({ organization }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [isModalOpen, openModal] = useState(false);
  const { referrals } = useSelector((state) => state.referralsTable);
  const { activeJobs } = useSelector((state) => state.jobs);
  const myOrganizations = useSelector((state) => state.user?.organizations);
  const { isLoading } = useSelector((state) => state.organizations);
  const isMyCompany = myOrganizations?.some((el) => el.slug === organization?.slug);
  const organizationLogo = organization.links?.logo?.large;

  useEffect(() => {
    trackEvent('Employer: View profile');
  }, []);

  useEffect(() => {
    if (isMyCompany) {
      dispatch(loadApplications(organization.uuid));
    }
  }, [isMyCompany, params.companySlug]);

  const {
    name = 'Unknown',
    description = 'The information for this company has not yet been updated.',
    uuid,
  } = organization || {};

  const onEditOrganization = () => {
    dispatch(editOrganization(uuid)).then(() => {
      trackEvent('Employer: Edit');
      dispatch(loadOrganization(params.companySlug));
      dispatch(loadUserOrganizations());
    });
  };

  const bountiesTitle = () => (
    <div className="flex-row justify-between">
      <h2>{!uuid ? 'No active bounties' : 'Bounties'}</h2>
      { isMyCompany && (
      <Icon
        name="close"
        onClick={() => {
          navigate(`/c/${organization.slug}/jobs`);
          trackEvent('Employer: Add bounty');
        }}
        className="icon-plus"
      />
      ) }
    </div>
  );

  return (
    <>
      <Modal
        className="profile-modal"
        ariaHideApp={false}
        isOpen={isModalOpen}
        onRequestClose={() => openModal(false)}
        shouldCloseOnOverlayClick
      >
        <ModalContent
          title="Edit"
          secondaryButton="Cancel"
          primaryButton="Save"
          onClose={() => openModal(false)}
          primaryButtonAction={onEditOrganization}
        >
          <EditCompanyDetails organization={organization} />
        </ModalContent>
      </Modal>
      <div className="employer-profile-container">
        <Card
          title={name}
          displayAvatar={organizationLogo}
          editAction={isMyCompany ? () => openModal(true) : null}
          subtitle={() => companyCardSubtitle(organization || {}, description)}
          landscapeImage={organization.links?.header || employerLandscapePlaceholder}
          variant="small"
        />
        { !activeJobs.length && (
        <Card
          title="Create bounty"
          subtitle="You have no bounties available for referrers and candidates to discover your roles. Attach a bounty to an open role to get started."
          variant="small"
        >
          <Button text="Create bounty" onClick={() => navigate('jobs')} />
        </Card>
        )}
        <Card
          title={bountiesTitle}
          subtitle={() => bountiesCardSubtitle({
            referrals, uuid, name, organization, isMyCompany, slug: organization?.slug, params,
          })}
          variant="small"
          isLoading={isLoading}
        >
          <BountiesList
            jobs={isMyCompany ? activeJobs : (organization?.departments?.[0]?.jobs || [])}
            isMyCompany={isMyCompany}
            organization={organization}
            referrals={referrals}
          />
        </Card>
      </div>
    </>
  );
}

export default EmployerProfileMain;
