/* eslint-disable import/prefer-default-export */
export const paymentOpions = [
  {
    id: 'plaid',
    icon: 'plaid',
    text: 'Connect your bank with Plaid',
  },
  //   {
  //       id: 'brex',
  //       icon: 'brex',
  //       text: 'Brex',
  //   },
  //   {
  //       id: 'mercury',
  //       icon: 'mercury',
  //       text: 'Mercury',
  //   },
  //   {
  //       id: 'ramp',
  //       icon: 'ramp',
  //       text: 'Ramp',
  //   },
  {
    id: 'credit-card',
    icon: 'card',
    text: 'Corporate Credit Card',
  },
];
