import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import Card from 'components/Card';
import { clearForm } from 'store/reducers/candidateReferralForm';

import './ReferrerReferralSubmitted.scss';
import { useNavigate, useParams } from 'react-router-dom';
import List from 'components/List';
import Button from 'components/Button';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { loadSuggestedJobs } from 'actions/jobs';
import { trackEvent } from 'util/tracking';
import { clearRequestedReferral } from 'store/reducers/referral';

function ReferrerReferralSubmitted() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [suggestedJobs, setSuggestedJobs] = useState([]);
  const [referralInfo, setReferralInfo] = useState({});
  const { requestedReferral } = useSelector((state) => state.referral);
  const { candidate, organization = {} } = useSelector((state) => state.candidateReferralForm);
  const { firstName, lastName } = requestedReferral?.referral || referralInfo;
  const { width } = useWindowDimensions();
  const isTablet = width < 720;

  useEffect(() => {
    if (localStorage.getItem('referralInfo')) {
      setReferralInfo(JSON.parse(localStorage.getItem('referralInfo')));
    } else {
      setReferralInfo(candidate);
    }

    dispatch(loadSuggestedJobs(params.slug)).then((res) => {
      const jobs = res.data?.data?.['3'];
      if (!jobs) return;
      setSuggestedJobs(jobs.filter((el) => el.bounty));
    });

    return () => {
      dispatch(clearRequestedReferral());
      dispatch(clearForm());
      localStorage.removeItem('referralInfo');
      localStorage.removeItem('referralSlug');
      localStorage.removeItem('companySlug');
      sessionStorage.removeItem('requestedReferral');
      sessionStorage.removeItem('requestedReferralUuid');
    };
  }, []);

  const suggestedJobsListItems = suggestedJobs.map(({
    uuid, title, bounty, links, organization,
  }) => ({
    uuid,
    title,
    subtitle: bounty?.referral?.localized,
    slug: bounty?.slug,
    links,
    avatar: organization.links.logo.medium,
  }));

  const subtitle = () => (
    <p>
      Your referral for
      {' '}
      <span className="bold">{`${firstName} ${lastName} `}</span>
      {' '}
      has been submitted. We’ve sent
      {' '}
      <span className="bold">{ firstName }</span>
      {' '}
      an email to confirm they’re
      interested in the role. Once they confirm,
      {' '}
      <span className="bold no-wrap">{ organization?.name }</span>
      {' '}
      will have access to their information.
      {' '}
    </p>
  );

  const onSelectItem = ({ links, slug }) => {
    trackEvent('Chaining: view role');
    const companySlug = links.page.split('/')[3];
    navigate(`/c/${companySlug}/j/${slug}`);
  };

  return (
    <>
      <Helmet title="Submitted!" />
      <Card
        title="Submitted!"
        subtitle={subtitle}
      >
        {suggestedJobsListItems.length > 0 && (
        <>
          <h3>Know more talent?</h3>
          <p className="submitted-description mb-10">Check out more relevant open roles from across our platform</p>
          <List
            className="suggested-jobs-items"
            items={suggestedJobsListItems}
            onSelectItem={onSelectItem}
            greenSubtitle
            variation="medium"
          />
          { !isTablet && <Button text="View more" to="/u/profile" className="mt-20" /> }
        </>
        ) }
      </Card>
    </>
  );
}

export default ReferrerReferralSubmitted;
