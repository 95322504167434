import React, { useState } from 'react';
import classNames from 'classnames';
import Icon from 'components/Icon';
import './ReferralSidebarContent.scss';
import Navigation from './ReferralBody/Navigation';

function ReferralSidebarContent({ items, itemIndex, component: Component }) {
  const [currentIndex, setCurrentIndex] = useState(itemIndex);
  const currentReferral = items[currentIndex];

  const isNext = items.length - 1 !== currentIndex;
  const isBack = currentIndex > 0;

  if (!Component) {
    if (process.env.REACT_APP_MODE === 'development') {
      console.log('Sidebar has nothing to render! 😵 You forgot to pass it a component.');
    }
    return null;
  }

  return (
    <div className="referral-sidebar">
      <Navigation
        setCurrentIndex={setCurrentIndex}
        isBack={isBack}
        isNext={isNext}
        currentIndex={currentIndex}
      />
      <Component item={currentReferral} />
    </div>
  );
}

export default ReferralSidebarContent;
