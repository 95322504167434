/* eslint-disable import/prefer-default-export */
export const listItems = [
  {
    id: 'individual',
    text: 'Individual',
    icon: 'user',
  },
  {
    id: 'company',
    text: 'Company',
    icon: 'briefcase',
  },
];
