/* eslint-disable no-unused-vars */
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Card from 'components/Card';
import CardRow from 'components/CardRow';

function ReferringInstructionsCard({ job, companySlug }) {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    note, referral, inRole, slug,
  } = job?.bounty || {};
  const { description, title } = job || {};

  const onClickViewJobDetails = () => {
    const { pathname } = location;
    if (pathname.includes('bounties')) {
      return `${pathname}/job`;
    }
    return `/c/${companySlug}/j/${slug}`;
  };
  return (
    <Card
      title="Details"
      noPadding
      variant="small"
    >
      {/* <CardRow
        title="Dummy name (dummy role)"
        subtitle={note}
        img={null}
        showBorder={false}
      /> */}
      <CardRow
        title={`${referral?.localized} bonus`}
        subtitle="This amount will be paid if your referral gets hired."
        icon="money-bag"
        showBorder={false}
      />
      <CardRow
        isMarkdown
        title={title}
        subtitle={description}
        footer={(
          <Link className="mt-10 green-text pointer" to={onClickViewJobDetails()}>
            View job details
          </Link>
        )}
        icon="briefcase"
      />
      <CardRow
        title="Verified"
        subtitle="You must know the candidate. They will need to verify your connection."
        icon="checkmark-circle"
        iconProps={{ fill: '#000000' }}
      />
      <CardRow
        title={`${inRole?.value.match(/\d+/)[0]} days`}
        subtitle={inRole?.value}
        icon="calendar"
      />
      <CardRow
        title="No split referral bonuses"
        subtitle="If the same candidate is referred multiple times, we will pay the first person that made the referral."
        icon="trophy"
      />
    </Card>
  );
}

export default ReferringInstructionsCard;
