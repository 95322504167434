export const Title = ({ title }) => {
  const Element = title;

  const titleElement = typeof title === 'string'
    ? <h2>{title}</h2>
    : <Element />;

  return titleElement;
};

export const Subtitle = ({ subtitle }) => {
  const Element = (subtitle);

  const subtitleElement = typeof subtitle === 'string'
    ? <p className="f14">{subtitle}</p>
    : <Element />;

  return subtitleElement;
};
