import { fetchData } from 'actions/fetchData';
import {
  referralRequest,
  referralSuccess,
  referralFailure,
  confirmReferralRequest,
  confirmReferralSuccess,
  confirmReferralFailure,
  referralRequestSuccess,
  referralRequestRequest,
  referralRequestFailure,
} from 'store/reducers/referral';

import { referralsRequest, referralsSuccess, referralsFailure } from 'store/reducers/referralsTable';
import { generalFailure, generalRequest, generalSuccess } from 'store/reducers/user';

export const loadReferral = (code) => (dispatch,
  getState) => (
  fetchData({
    endpoint: `referral/${code}`,
    actions: [referralRequest, referralSuccess, referralFailure],
  })
)(dispatch, getState);

export const confirmReferral = (code) => (dispatch,
  getState) => (
  fetchData({
    endpoint: `referral/${code}/confirm`,
    actions: [confirmReferralRequest, confirmReferralSuccess, confirmReferralFailure],
    httpMethod: 'post',
  })
)(dispatch, getState);

export const rejectReferral = (code) => (dispatch,
  getState) => (
  fetchData({
    endpoint: `referral/${code}/reject`,
    actions: [confirmReferralRequest, confirmReferralSuccess, confirmReferralFailure],
    httpMethod: 'patch',
  })
)(dispatch, getState);

export const loadApplications = (orgUuid) => (dispatch,
  getState) => fetchData({
  endpoint: `applications/${orgUuid}`,
  actions: [referralsRequest, referralsSuccess, referralsFailure],
})(dispatch, getState);

export const approveApplication = (applicationId) => (dispatch,
  getState) => (
  fetchData({
    endpoint: `application/${applicationId}/approve`,
    actions: [generalRequest, generalSuccess, generalFailure],
    httpMethod: 'post',
  })
)(dispatch, getState);

export const syncApplication = (applicationId) => (dispatch,
  getState) => (
  fetchData({
    endpoint: `application/${applicationId}/sync`,
    actions: [generalRequest, generalSuccess, generalFailure],
    httpMethod: 'post',
  })
)(dispatch, getState);

export const rejectApplication = (applicationId) => (dispatch,
  getState) => (
  fetchData({
    endpoint: `application/${applicationId}/pass`,
    actions: [generalRequest, generalSuccess, generalFailure],
    httpMethod: 'post',
  })
)(dispatch, getState);

export const changeApplicationStep = ({ applicationId, stagIid }) => (dispatch,
  getState) => (
  fetchData({
    endpoint: `application/${applicationId}/interview/stage`,
    actions: [generalRequest, generalSuccess, generalFailure],
    httpMethod: 'post',
    body: {
      interview_stage_id: stagIid,
    },
  })
)(dispatch, getState);

export const requestReferral = (juid, body) => (dispatch,
  getState) => (
  fetchData({
    endpoint: `referral/${juid}/request`,
    actions: [null, null, null],
    httpMethod: 'post',
    body,
  })
)(dispatch, getState);

export const loadRequestedReferral = (uuid) => (dispatch,
  getState) => fetchData({
  endpoint: `referral/request/${uuid}`,
  actions: [referralRequestRequest, referralRequestSuccess, referralRequestFailure],
})(dispatch, getState);
