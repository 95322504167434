/* eslint-disable prefer-destructuring */
import { createSlice } from '@reduxjs/toolkit';

const user = createSlice({
  name: 'user',
  initialState: {
    token: null,
    isEmployer: false,
    authError: false,
    organizations: [],
    referrals: {
      isLoading: false,
      data: [],
    },
    signupForm: {
      email: null,
      firstName: null,
      lastName: null,
      linkedinProfile: null,
      errors: {},
    },
    rewards: {
      isLoading: false,
      data: {},
    },
  },
  reducers: {
    userRequest() {},
    userSuccess(state, { payload }) {
      return { ...state, ...payload.data };
    },
    userFailure(state, { payload }) {
      if (payload.error === 'user does not exist') {
        state.authError = true;
      }
      state.isLoadingUser = false;
    },
    authRequest() {},
    authSuccess(state, { payload }) {
      const { data } = payload;

      // Waitlist user
      if (data.profile) {
        state.signupForm = {
          ...state.signupForm,
          ...data.profile,
          uuid: data.uuid,
        };
      }
      if (data.status?.account) {
        localStorage.setItem('userStatus', data.status.account);
      }
      state.token = data.token || null;
      state.authError = false;
    },
    authFailure() {},
    userOrganizationsRequest() {},
    userOrganizationsSuccess(state, { payload }) {
      if (payload.data.length) {
        state.isEmployer = true;
      }
      state.organizations = payload.data;
    },
    userOrganizationsFailure() {},
    userReferralsRequest(state) {
      state.referrals.isLoading = true;
    },
    userReferralsSuccess(state, { payload }) {
      state.referrals.isLoading = false;
      state.referrals.data = payload.data;
    },
    userReferralsFailure(state) {
      state.referrals.isLoading = false;
    },
    editSignupForm(state, { payload }) {
      const { key, value } = payload;
      state.signupForm[key] = value;
    },
    clearSignupForm(state) {
      state.signupForm = {
        email: null,
        firstName: null,
        lastName: null,
        linkedinProfile: null,
        errors: {},
      };
    },
    setError(state, { payload }) {
      state.signupForm.errors[payload.key] = payload.msg;
    },
    userRewardsRequest(state) {
      state.rewards.isLoading = true;
    },
    userRewardsSuccess(state, { payload }) {
      state.rewards.isLoading = false;
      state.rewards.data = payload.data[0];
    },
    userRewardsFailure(state) {
      state.rewards.isLoading = false;
    },
    generalRequest() {},
    generalSuccess() {},
    generalFailure() {},
  },
});

const { actions, reducer } = user;

export const {
  userRequest,
  userSuccess,
  userFailure,
  authRequest,
  authSuccess,
  authFailure,
  userReferralsRequest,
  userReferralsSuccess,
  userReferralsFailure,
  userOrganizationsRequest,
  userOrganizationsSuccess,
  userOrganizationsFailure,
  editOrganizationRequest,
  editOrganizationSuccess,
  editOrganizationFailure,
  integrateAtsRequest,
  integrateAtsSuccess,
  integrateAtsFailure,
  submitAtsRequest,
  submitAtsSuccess,
  submitAtsFailure,
  editSignupForm,
  clearSignupForm,
  setError,
  generalRequest,
  generalSuccess,
  generalFailure,
  userRewardsRequest,
  userRewardsSuccess,
  userRewardsFailure,
} = actions;

export default reducer;
