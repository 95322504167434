/* eslint-disable max-len */
import Avatar from 'components/Avatar';
import Icon from 'components/Icon';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { referralEmailSubject } from 'util';

function HeaderSection({ users, referralLastJob, jobReferredTo }) {
  const params = useParams();
  const { organizations, firstName, lastName } = useSelector((state) => state.user);
  const currentOrganization = organizations.filter((org) => org.slug === params.companySlug)[0];
  const { referral } = users;

  return (
    <>
      <Avatar
        alt="referrer-avatar"
        size="x-large"
        src={referral.links?.photo}
        fallbackContent={referral?.firstName?.charAt(0)}
      />
      <h3 className="referral-sidebar__body-name mt-5">{ `${referral?.firstName} ${referral?.lastName}` }</h3>
      { referralLastJob && (
      <p>
        {referralLastJob?.title}
        {' '}
        •
          {' '}
        {referralLastJob?.company?.name}
      </p>
      )}
      <div className="flex-column referral-sidebar__body-content">
        <div className="flex-column">
          <div className="flex-row">
            <p className="bold">Email</p>
            <a
              href={`mailto:${referral?.email}?subject=Your ${currentOrganization?.name} referral&body=${encodeURIComponent(referralEmailSubject(referral, jobReferredTo, currentOrganization, firstName, lastName))}`}
              className="text-green flex-row align-center ml-8"
            >
              <Icon className="mr-5" name="email" styleProps={{ fill: '#1cbd00', width: 18, height: 12 }} />
              Message
            </a>
          </div>
          <p>{referral?.email}</p>
        </div>
        <div className="flex-column mt-10">
          <p className="bold">Phone</p>
          <p>{referral?.phone?.localized}</p>
        </div>
      </div>
    </>
  );
}

export default HeaderSection;
