import Icon from 'components/Icon';
import './FullScreenLoader.scss';

function FullScreenLoader() {
  return (
    <div className="full-screen-loader">
      <div className="flex-column align-center">
        <Icon name="refer-checkmark" />
        <div className="progress-bar" />
      </div>
    </div>
  );
}

export default FullScreenLoader;
