function Email({ styleProps }) {
  const { fill = 'white', width = 24, height = 16 } = styleProps || {};
  return (
    <svg width={width} height={height} viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M10.9982 8.92576L0.616005 0H23.384L13.0133 8.91598C12.7314 9.14995 12.3731 9.27704 12.0034 9.27376C11.6362 9.28086 11.279 9.15723 10.9979 8.92581L10.9982 8.92576ZM15.6539 8.04433L24 15.2193V0.869317L15.6539 8.04433ZM13.7203 9.70671C13.2424 10.1078 12.6329 10.3271 12.0035 10.3243C11.3762 10.3321 10.7675 10.1162 10.2912 9.71673L9.1593 8.74388L0.718403 16H23.2821L14.8412 8.74388L13.7203 9.70671ZM0 0.869503V15.2195L8.34612 8.04452L0 0.869503Z" fill={fill} />
    </svg>
  );
}

export default Email;
