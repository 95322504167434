/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from 'components/Dropdown';
import Input from 'components/Input';
import { initializeForm, editForm } from 'store/reducers/editOrganizationForm';
import ImageUploader from 'components/ImageUploader';
import './EditCompanyDetails.scss';
import { employerLandscapePlaceholder } from 'util/constants';
import { loadOrganization, uploadOrganizationImages } from 'actions/organizations';

function EditCompanyDetails({ organization }) {
  const dispatch = useDispatch();
  const { funding: fundingOptions } = useSelector((state) => state.main.organization);
  const {
    description, website, funding, category, name,
  } = useSelector((state) => state.editOrganizationForm) || {};
  const { logo, header } = organization.links;

  useEffect(() => {
    dispatch(initializeForm(organization));
  }, []);

  const onEditForm = (item) => {
    dispatch(editForm(item));
  };

  const editImage = (img) => {
    dispatch(uploadOrganizationImages(img, organization.uuid)).then((res) => {
      if (res.status === 200) {
        dispatch(loadOrganization(organization.slug));
      }
    });
  };

  return (
    <>
      <div>
        <ImageUploader
          id="landscape"
          saveImage={editImage}
          fileName="header"
          showEditIcon
          avatar={header || employerLandscapePlaceholder}
          variant="card"
        />
        <ImageUploader
          id="avatar"
          saveImage={editImage}
          fileName="logo"
          showEditIcon
          className="edit-info-user-avatar"
          iconSize="small"
          avatar={logo?.large}
          variant="profile"
        />
      </div>
      <Input
        id="name"
        onChange={(value) => onEditForm({ key: 'name', value })}
        value={name}
        placeholder="Name"
      />
      <Input
        id="description"
        onChange={(value) => onEditForm({ key: 'description', value })}
        value={description}
        placeholder="Description"
      />
      <div className="flex-row edit-company__dropdowns">
        <Dropdown
          items={fundingOptions}
          onClick={(value) => onEditForm({ key: 'funding', value })}
          value={funding?.name || funding?.value}
          placeholder="Funding round"
        />
        <Input
          id="category"
          onChange={(value) => onEditForm({ key: 'category', value })}
          value={category}
          placeholder="Category"
        />
      </div>
      <Input
        id="website"
        onChange={(value) => onEditForm({ key: 'website', value })}
        value={website}
        placeholder="Website"
      />
    </>
  );
}

export default EditCompanyDetails;
