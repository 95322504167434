function CheckmarkGrey() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2710_1763)">
        <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" fill="#63637B" stroke="#63637B" />
        <g clipPath="url(#clip1_2710_1763)">
          <path fillRule="evenodd" clipRule="evenodd" d="M11.4902 3.55237L13.533 5.26648L9.24787 10.3733L9.24808 10.3735L7.53397 12.4163L7.53375 12.4161L7.5336 12.4163L5.4908 10.7022L5.49096 10.702L2.42698 8.13099L4.14109 6.0882L7.20507 8.65918L11.4902 3.55237Z" fill="white" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2710_1763">
          <rect width="16" height="16" fill="white" />
        </clipPath>
        <clipPath id="clip1_2710_1763">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CheckmarkGrey;
