import { bootstrapRequest, bootstrapSuccess, bootstrapFailure } from 'store/reducers/main';
import { geoLocationsRequest, geoLocationsSuccess, geoLocationsFailure } from 'store/reducers/ui';
import {
  generalRequest,
  generalSuccess,
  generalFailure,
  authSuccess,
} from 'store/reducers/user';

import { fetchData } from 'actions/fetchData';

export const loadBootstrap = () => (dispatch, getState) => fetchData({
  endpoint: 'bootstrap',
  actions: [bootstrapRequest, bootstrapSuccess, bootstrapFailure],
})(dispatch, getState)
  .then((res) => {
    if (res.data.data.user) {
      const { token } = res.data.data.user.credentials;
      dispatch(authSuccess({ data: { token } }));
    }
    return res;
  })
  .catch((e) => e);

export const loadGeoLocations = (keyword) => (dispatch, getState) => (
  fetchData({
    endpoint: `geo/search?q=${keyword}`,
    actions: [geoLocationsRequest, geoLocationsSuccess, geoLocationsFailure],
  })
)(dispatch, getState);

export const contact = (body) => (dispatch, getState) => (
  fetchData({
    endpoint: 'contact',
    actions: [generalRequest, generalSuccess, generalFailure],
    httpMethod: 'post',
    body,
  })
)(dispatch, getState);
