import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Card from 'components/Card';
import List from 'components/List';
import { listItems } from './util';

function AccountType() {
  const navigate = useNavigate();
  const [selectedType, setAccountType] = useState(null);
  const { uuid } = useSelector((state) => state.user.signupForm);

  useEffect(() => {
    if (!uuid) {
      navigate('/');
    }
  }, []);

  const onNext = () => {
    navigate(`/signup/details/${selectedType.id}`);
  };

  return (
    <>
      <Helmet title="Account type | Refer" />
      <Card
        title="Account type"
        subtitle="Are you setting up an individual or company page? If you select “Individual” now you can always create a company page later on."
        buttons={[
          {
            id: 'next',
            text: 'Next',
            variant: !selectedType ? 'disabled' : 'default',
            onClick: onNext,
          },
        ]}
      >
        <List items={listItems} onSelectItem={setAccountType} showCheckbox className="ats-list" />
      </Card>
    </>

  );
}

export default AccountType;
