import { createSlice } from '@reduxjs/toolkit';

const editJobForm = createSlice({
  name: 'editJobForm',
  initialState: {
    uuid: null,
    title: '',
    description: '',
    type: null,
    experience: null,
    salaryMin: null,
    salaryMax: null,
    locations: [],
    role: null,
    ats: {},
    public: null,
  },
  reducers: {
    initializeForm(state, { payload }) {
      const {
        uuid, title, description, locations, role, ats, employment, work, salary, public: isPublic,
      } = payload;
      return {
        uuid,
        title,
        description,
        type: employment?.type,
        experience: work?.experience,
        locations,
        role,
        ats,
        salaryMin: salary?.min,
        salaryMax: salary?.max,
        public: isPublic,
      };
    },
    editForm(state, { payload }) {
      state[payload.key] = payload.value;
    },
    clearForm() {
      return {
        uuid: null,
        title: '',
        description: '',
        type: null,
        experience: null,
        locations: [],
        role: null,
        ats: {},
        salaryMin: null,
        salaryMax: null,
        public: null,
      };
    },
  },
});

const { actions, reducer } = editJobForm;

export const {
  initializeForm,
  editForm,
  clearForm,
} = actions;

export default reducer;
