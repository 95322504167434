import { createSlice } from '@reduxjs/toolkit';

const referralsTable = createSlice({
  name: 'referralsTable',
  initialState: {
    filters: null,
    referrals: [],
    isFetching: false,
  },
  reducers: {
    setFilters(state, { payload }) {
      state.filters = payload;
    },
    referralsRequest(state, { payload }) {
      state.isFetching = true;
    },
    referralsSuccess(state, { payload }) {
      state.referrals = payload.data;
      state.isFetching = false;
    },
    referralsFailure(state, { payload }) {
      state.isFetching = false;
    },
  },
});

const { actions, reducer } = referralsTable;

export const {
  setFilters,
  referralsRequest,
  referralsSuccess,
  referralsFailure,
} = actions;

export default reducer;
