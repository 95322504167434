import ExperienceSection from './ExperienceSection';
import HeaderSection from './HeaderSection';
import ReferrerSection from './ReferrerSection';

function ReferralBody({ item }) {
  const {
    referral, job, profiles, users,
  } = item;

  const referralLastJob = profiles?.referral?.highlights?.job?.[0];
  const referrerLastJob = profiles?.referrer?.highlights?.job?.[0];

  const rankingInfo = referral.ranking?.map((el) => (
    <div className="mt-10" key={el.question}>
      <p className="bold">{el.question}</p>
      <p className="">{el.option}</p>
    </div>
  ));

  return (
    <div className="referral-sidebar__body">
      <HeaderSection users={users} referralLastJob={referralLastJob} jobReferredTo={job} />
      <ExperienceSection profiles={profiles} referral={referral} />
      <ReferrerSection
        users={users}
        referrerLastJob={referrerLastJob}
        rankingInfo={rankingInfo}
        job={job}
        referral={referral}
      />
    </div>
  );
}

export default ReferralBody;
