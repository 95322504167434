function Checkmark({ width = 22, height = 22, fill = '#1ABD00' }) {
  return (
    <svg width="14" height="10" viewBox="0 0 14 10" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M12.6396 0.511876C13.3447 1.10355 13.4367 2.15481 12.845 2.85994L7.50454 9.22451C7.49931 9.23093 7.49402 9.23733 7.48868 9.2437C7.13619 9.66378 6.62058 9.86624 6.11223 9.83618C5.7672 9.81582 5.42549 9.68835 5.14038 9.44912C5.13489 9.44451 5.12944 9.43988 5.12402 9.43521L1.31036 6.23517C0.605233 5.6435 0.51326 4.59223 1.10493 3.88711C1.69661 3.18198 2.74787 3.09 3.453 3.68167L6.00636 5.8242L10.2915 0.717306C10.8832 0.0121769 11.9345 -0.0797966 12.6396 0.511876Z" fill="#1ABD00" />
    </svg>

  );
}

export default Checkmark;
