/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import { Link, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isAdmin } from 'util';
import './ProfileMenu.scss';
import { trackEvent } from 'util/tracking';

function ProfileMenu() {
  const params = useParams();
  const location = useLocation();
  const { organizations, email } = useSelector((state) => state.user);
  const firstOrgSlug = organizations[0]?.slug;

  const menuActiveClasses = (name) => {
    if (
      (name === 'Profile' && location.pathname === '/u/profile')
      || (name === 'Jobs' && params.page === 'profile-jobs')
      || (name === 'Users' && params.page === 'profile-users')
      || (name === 'Account settings' && params.page === 'profile-settings')
      || (`/c/${name}` === location.pathname)
    ) {
      return 'bold';
    }
  };

  return (
    <div className="profile-menu">
      <ul>
        <Link className={menuActiveClasses('Profile')} to="/u/profile">Profile</Link>
        {
          organizations?.map(({ slug, name }) => (
            <Link
              key={slug}
              className={menuActiveClasses(slug)}
              to={`/c/${slug}`}
            >
              {name}
            </Link>
          ))
        }
        <Link
          className={menuActiveClasses('Jobs')}
          onClick={() => trackEvent('Employer: View jobs')}
          to={`/c/${params.companySlug || firstOrgSlug}/profile-jobs`}
        >
          Jobs
        </Link>
        { isAdmin(email) && <Link className="green-text bold" to="/signup/organization">Create organization</Link>}
      </ul>
    </div>
  );
}

export default ProfileMenu;
