import './Card.scss';

function CardLoaderHome() {
  return (
    <div className="card-loader home-loader">
      <div className="row-home row flex-row">
        <span className="row-avatar" />
        <div className="flex-column w-100">
          <span className="row-description description" />
          <span className="row-description mt-10 description" />
        </div>
      </div>
      <div className="row-home row flex-row">
        <span className="row-avatar" />
        <div className="flex-column w-100">
          <span className="row-description description" />
          <span className="row-description mt-10 description" />
        </div>
      </div>
      <div className="row-home row flex-row">
        <span className="row-avatar" />
        <div className="flex-column w-100">
          <span className="row-description description" />
          <span className="row-description mt-10 description" />
        </div>
      </div>
      <div className="row-home row flex-row">
        <span className="row-avatar" />
        <div className="flex-column w-100">
          <span className="row-description description" />
          <span className="row-description mt-10 description" />
        </div>
      </div>
      <div className="row-home row flex-row">
        <span className="row-avatar" />
        <div className="flex-column w-100">
          <span className="row-description description" />
          <span className="row-description mt-10 description" />
        </div>
      </div>
      <div className="row-home row flex-row">
        <span className="row-avatar" />
        <div className="flex-column w-100">
          <span className="row-description description" />
          <span className="row-description mt-10 description" />
        </div>
      </div>
    </div>
  );
}

export default CardLoaderHome;
