import alerts from './alerts';
import bounties from './bounties';
import candidateReferralForm from './candidateReferralForm';
import createBountyForm from './createBountyForm';
import editOrganizationForm from './editOrganizationForm';
import editJobForm from './editJobForm';
import editUserForm from './editUserForm';
import employerAccountSettingsForm from './employerAccountSettingsForm';
import jobs from './jobs';
import main from './main';
import openJobs from './openJobs';
import organizations from './organizations';
import referral from './referral';
import referralsTable from './referralsTable';
import referralsTableForm from './referralsTableForm';
import taxForm from './taxForm';
import ui from './ui';
import user from './user';

const rootReducer = {
  alerts,
  bounties,
  candidateReferralForm,
  createBountyForm,
  editOrganizationForm,
  editJobForm,
  editUserForm,
  employerAccountSettingsForm,
  jobs,
  main,
  openJobs,
  organizations,
  referral,
  referralsTable,
  referralsTableForm,
  taxForm,
  ui,
  user,
};

export default rootReducer;
