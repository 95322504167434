import { createSlice } from '@reduxjs/toolkit';

const ui = createSlice({
  name: 'ui',
  initialState: {
    suggestions: [],
    isFetching: false,
    isMobileMenuOpen: false,
    educationCompleted: true,
  },
  reducers: {
    geoLocationsRequest(state) {
      state.isFetching = true;
    },
    geoLocationsSuccess(state, { payload }) {
      const suggestions = [];
      if (payload.data?.[0]) {
        Object.entries(payload.data[0]).forEach(([id, data]) => {
          suggestions.push(data.map((el) => ({ ...el, dataType: id })));
        });
      }

      state.isFetching = false;
      state.suggestions = suggestions.flat();
    },
    geoLocationsFailure(state) {
      state.isFetching = false;
    },
    setMobileMenuStatus(state, { payload }) {
      state.isMobileMenuOpen = payload.status;
    },
    getEducationSuccess(state, { payload }) {
      const { completed, eligible } = payload.data.status;
      const isEducationCompleted = eligible ? completed : true;
      state.educationCompleted = isEducationCompleted;
    },
    completeEducationSuccess(state) {
      state.educationCompleted = true;
    },
  },
});

const { actions, reducer } = ui;

export const {
  geoLocationsRequest,
  geoLocationsSuccess,
  geoLocationsFailure,
  setMobileMenuStatus,
  getEducationSuccess,
  completeEducationSuccess,
} = actions;

export default reducer;
