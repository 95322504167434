import toastLib from 'react-hot-toast';
import Icon from 'components/Icon';
import './Alert.scss';

const toast = ({ title, subtitle }) => {
  toastLib((t) => (
    <div className="flex-row alert">
      <div className="flex-column">
        <p className="bold">{title}</p>
        <p>{subtitle}</p>
      </div>
      <Icon name="close" styleProps={{ fill: '#fff' }} onClick={() => toastLib.dismiss(t.id)} />
    </div>
  ));
};

export default toast;
