function Filters({ width = 24, height = 24 }) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <rect x="4.5" y="6.5" width="15" height="1" rx="0.5" fill="#63637B" stroke="#63637B" />
      <circle cx="15" cy="7" r="1.5" fill="white" stroke="#63637B" />
      <rect x="4.5" y="11.5" width="15" height="1" rx="0.5" fill="#63637B" stroke="#63637B" />
      <circle cx="6" cy="12" r="1.5" fill="white" stroke="#63637B" />
      <rect x="4.5" y="16.5" width="15" height="1" rx="0.5" fill="#63637B" stroke="#63637B" />
      <circle cx="11" cy="17" r="1.5" fill="white" stroke="#63637B" />
    </svg>
  );
}

export default Filters;
