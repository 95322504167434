import columns from './util';
import TableHeaderColumn from './TableHeaderColumn';
import './TableHeader.scss';

function TableHeader() {
  return (
    <div className="table-header">
      <div className="scroller">
        <div className="table-columns-header">
          { columns.map((item) => <TableHeaderColumn key={item.key} item={item} />)}
        </div>
      </div>
    </div>
  );
}

export default TableHeader;
