function Trophy({ width = 24, height = 24 }) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" fill="white" />
      <path d="M5.20915 3C4.96896 2.99913 4.77332 3.19226 4.77246 3.43135C4.77246 6.90524 5.5248 10.0558 6.76915 12.3724C8.01366 14.6886 9.79419 16.2182 11.8467 16.2182C13.8993 16.2182 15.6755 14.6886 16.9202 12.3724C18.1647 10.0561 18.9178 6.90524 18.9178 3.43135C18.9169 3.19226 18.7213 2.99911 18.4811 3H5.20915ZM11.8144 5.04848C11.9601 5.05065 12.095 5.12532 12.1738 5.24726L13.2885 6.95744L15.4178 7.32456H15.4179C15.5744 7.35226 15.7031 7.4626 15.754 7.61252C15.8048 7.76244 15.7694 7.92788 15.6618 8.04431L14.1842 9.64882L14.4094 11.65L14.4092 11.6499C14.4258 11.8017 14.3609 11.9509 14.2384 12.0426C14.1159 12.1343 13.9539 12.1552 13.812 12.0973L11.7983 11.2761L9.81674 12.2124C9.66933 12.2816 9.49542 12.2629 9.36622 12.164C9.23702 12.0651 9.1741 11.9026 9.20337 11.7429L9.58223 9.7258L8.05034 8.2998C7.93468 8.19294 7.88618 8.03215 7.92347 7.87962C7.9609 7.72709 8.0783 7.6066 8.23037 7.5647L10.3147 6.99211L11.4423 5.24727C11.5234 5.12156 11.6641 5.04645 11.8144 5.04848L11.8144 5.04848Z" fill="black" />
      <path d="M6.28684 17.5061C5.60983 17.5061 5.05371 18.0597 5.05371 18.7336V19.7693C5.05371 20.4432 5.60983 21 6.28684 21H17.4014C18.0785 21 18.6378 20.4432 18.6378 19.7693V18.7336C18.6378 18.0597 18.0785 17.5061 17.4014 17.5061H6.28684Z" fill="black" />
      <path d="M8.38051 15.5269L7.30134 17.6947C7.15627 17.9837 7.36864 18.3234 7.69317 18.3215C11.7517 16.5194 14.7213 19.2967 16.368 17.6576L15.1495 15.6775C14.18 16.5975 13.0618 17.1382 11.8451 17.1382C10.4398 16.9686 9.18192 16.317 8.38036 15.5269H8.38051Z" fill="black" />
      <path d="M19.7225 4.34912C19.5639 6.77152 19.086 9.00744 18.3613 10.9175C18.5615 10.8556 18.7632 10.7698 18.9689 10.6561C19.4651 10.3822 19.9781 9.96234 20.4456 9.41541C21.3805 8.32128 22.1294 6.70783 21.981 4.79643C21.9636 4.57082 21.7749 4.39667 21.5476 4.39641C20.7129 4.39641 20.185 4.37075 19.7225 4.349L19.7225 4.34912Z" fill="black" />
      <path d="M3.96717 4.36008C3.57142 4.37835 3.09685 4.39647 2.45552 4.39647C2.22699 4.39488 2.03618 4.5696 2.01884 4.79649C1.87041 6.70778 2.61926 8.32127 3.55423 9.41547C3.98581 9.96835 4.55621 10.4037 5.26876 10.7729C4.57646 8.89842 4.12229 6.71624 3.96717 4.35999V4.36008Z" fill="black" />
    </svg>
  );
}

export default Trophy;
