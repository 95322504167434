import { fetchData } from 'actions/fetchData';
import {
  jobsRequest,
  jobsSuccess,
  jobsFailure,
  generalRequest,
  generalSuccess,
  generalFailure,
} from 'store/reducers/jobs';
import { openJobsRequest, openJobsSuccess, openJobsFailure } from 'store/reducers/openJobs';

export const loadJobs = (orgUuid) => (dispatch,
  getState) => (
  fetchData({
    endpoint: `organization/${orgUuid}/jobs`,
    actions: [jobsRequest, jobsSuccess, jobsFailure],
  })
)(dispatch, getState);

export const createJob = (body, orgUuid) => (dispatch, getState) => (
  fetchData({
    endpoint: `organization/${orgUuid}/job`,
    actions: [generalRequest, generalSuccess, generalFailure],
    httpMethod: 'post',
    body,
  })
)(dispatch, getState);

export const editJob = (body, orgUuid) => (dispatch, getState) => (
  fetchData({
    endpoint: `organization/${orgUuid}/job/${body.uuid}`,
    actions: [generalRequest, generalSuccess, generalFailure],
    httpMethod: 'patch',
    body,
  })
)(dispatch, getState);

export const deactivateJob = (jobUuid, orgUuid) => (dispatch, getState) => (
  fetchData({
    endpoint: `organization/${orgUuid}/job/${jobUuid}/deactivate`,
    actions: [generalRequest, generalSuccess, generalFailure],
    httpMethod: 'patch',
  })
)(dispatch, getState);

// Fetches a selection of jobs from all companies
export const loadOpenJobs = () => (dispatch,
  getState) => (
  fetchData({
    endpoint: 'jobs/board',
    actions: [openJobsRequest, openJobsSuccess, openJobsFailure],
  })
)(dispatch, getState);

export const loadSuggestedJobs = (bountySlug) => (dispatch,
  getState) => (
  fetchData({
    endpoint: `jobs/suggested/${bountySlug}`,
    actions: [generalRequest, generalSuccess, generalFailure],
  })
)(dispatch, getState);
