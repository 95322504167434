/* eslint-disable max-len */
import { fetchData } from 'actions/fetchData';
import { completeEducationSuccess, getEducationSuccess } from 'store/reducers/ui';
import { generalFailure, generalRequest, generalSuccess } from 'store/reducers/user';

export const submitAtsSuggestion = (body) => (dispatch, getState) => (
  fetchData({
    endpoint: 'user/ats',
    actions: [generalRequest, generalSuccess, generalFailure],
    httpMethod: 'post',
    body,
  })
)(dispatch, getState);

export const getEducation = (ouid) => (dispatch, getState) => (
  fetchData({
    endpoint: `education/dashboard/${ouid}`,
    actions: [generalRequest, getEducationSuccess, generalFailure],
  })
)(dispatch, getState);

export const startEducation = (ouid) => (dispatch, getState) => (
  fetchData({
    endpoint: `education/dashboard/start/${ouid}`,
    actions: [generalRequest, generalSuccess, generalFailure],
    httpMethod: 'patch',
  })
)(dispatch, getState);

export const completeEducation = (ouid) => (dispatch, getState) => dispatch(startEducation(ouid))
  .then(() => fetchData({
    endpoint: `education/dashboard/completed/${ouid}`,
    actions: [generalRequest, completeEducationSuccess, generalFailure],
    httpMethod: 'patch',
  })(dispatch, getState));
