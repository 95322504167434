import { createSlice } from '@reduxjs/toolkit';

const editUserForm = createSlice({
  name: 'editUserForm',
  initialState: {
    linkedinProfile: null,
    website: null,
    headline: null,
  },
  reducers: {
    initializeForm(state, { payload }) {
      const {
        linkedinProfile, website, headline,
      } = payload;
      return {
        linkedinProfile,
        website,
        headline,
      };
    },
    editForm(state, { payload }) {
      state[payload.key] = payload.value;
    },
    clearForm() {
      return {
        linkedinProfile: null,
        website: null,
        headline: null,
      };
    },
  },
});

const { actions, reducer } = editUserForm;

export const {
  initializeForm,
  editForm,
  clearForm,
} = actions;

export default reducer;
