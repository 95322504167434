import classNames from 'classnames';
import { getEmoji } from 'util';

export default function Rewards({ data }) {
  const { count } = data?.referrals || {};
  const referralsGoal = 5;

  const elements = [...Array(referralsGoal).keys()].map((el) => {
    if (el === 0) {
      return (
        <div key={el} className={classNames('emoji-container', count > 0 && 'emoji-container-full')}>
          <div className="emoji">{getEmoji(el + 1, referralsGoal)}</div>
        </div>
      );
    } else {
      return (
        <div key={el} className="flex-row w-100">
          <div className={classNames('progress-bar w-100', count > el && 'progress-bar-full')} />
          <div className={classNames('emoji-container', count > el && 'emoji-container-full')}>
            <div className="emoji">{getEmoji(el + 1, referralsGoal)}</div>
          </div>
        </div>
      );
    }
  });

  return (
    <>
      <div className="rewards-container">
        <div className="progress-container w-100">
          { elements }
        </div>
      </div>
      { count > 4 && (
      <a
        className="mt-25 button button--default button--small"
        href={data.reward?.links?.url}
        target="_blank"
        rel="noreferrer"
      >
        Claim
      </a>
      )}
    </>
  );
}
