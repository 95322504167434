/* eslint-disable react/jsx-props-no-spreading */

import PropTypes from 'prop-types';
import Icon from 'components/Icon';
import './Button.scss';
import { Link } from 'react-router-dom';

export default function Button({
  size = 'large',
  variant = 'default',
  text,
  className,
  icon,
  iconProps,
  to,
  type,
  submitted,
  ...props
}) {
  const onClick = (e) => {
    e.stopPropagation();
    props.onClick();
  };

  const Element = to ? Link : 'button';
  return (
    <Element
      className={`button button--${size} button--${variant} flex-row ${className}`}
      onClick={(!to && type !== 'submit') ? onClick : undefined}
      to={to}
      type={type || 'button'}
    >
      { variant === 'submitted' ? <Icon name="checkmark" /> : text}
      <Icon name={icon} className="ml-10" {...iconProps} />
    </Element>
  );
}

Button.propTypes = {
  size: PropTypes.oneOf([
    'medium',
    'large',
    'small',
  ]),
  variant: PropTypes.oneOf([
    'default',
    'reverse',
    'reverse-secondary',
    'disabled',
    'reverse-multi',
    'white',
    'white-round',
    'white-reverse',
    'submitted',
  ]),
  onClick: PropTypes.func,
  text: PropTypes.string,
  icon: PropTypes.string,
};
