import ListItem from 'components/ListItem';
import CopyText from 'components/CopyText';
import './TableEmptyState.scss';
import { useParams } from 'react-router-dom';

export default function TableEmptyState({ currentBounty }) {
  const params = useParams();
  const { companySlug } = params;
  const copyText = () => (
    <CopyText
      content={`${process.env.REACT_APP_AUTHORIZATION_DOMAIN}c/${companySlug}/j/${currentBounty?.slug}`}
    />
  );

  if (!currentBounty) return null;
  return (
    <div className="table-empty-state">
      <p>Share the job with your network, to get referrals</p>
      <ListItem
        item={{ text: `getreferred.co/c/${companySlug}/j/${currentBounty?.slug}` }}
        highlight={false}
        readOnly
        hoverEffect={false}
        showCustom={copyText}
      />
    </div>
  );
}
