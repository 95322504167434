/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import Button from 'components/Button';
import './ButtonGroup.scss';
import classNames from 'classnames';

export default function ButtonGroup({ items, className }) {
  const buttons = items.filter(Boolean).map(({ id, ...props }) => <Button key={id} {...props} />);
  return (
    <div className={classNames('button-group', className)}>{buttons}</div>
  );
}

ButtonGroup.propTypes = {
  items: PropTypes.array,
};
