import {
  userRequest,
  userSuccess,
  userFailure,
  userOrganizationsRequest,
  userOrganizationsSuccess,
  userOrganizationsFailure,
  userReferralsRequest,
  userReferralsSuccess,
  userReferralsFailure,
  userRewardsRequest,
  userRewardsSuccess,
  userRewardsFailure,
} from 'store/reducers/user';
import { fetchData } from 'actions/fetchData';
import { generalFailure, generalRequest, generalSuccess } from 'store/reducers/jobs';

export const loadUser = () => (dispatch, getState) => (
  fetchData({
    endpoint: 'user',
    actions: [userRequest, userSuccess, userFailure],
  })
)(dispatch, getState);

export const editUser = (body, uuid) => (dispatch, getState) => (
  fetchData({
    endpoint: `user/${uuid}`,
    actions: [userRequest, userSuccess, userFailure],
    httpMethod: 'patch',
    body,
  })
)(dispatch, getState);

export const loadUserOrganizations = () => (dispatch, getState) => (
  fetchData({
    endpoint: 'user/organizations',
    actions: [userOrganizationsRequest, userOrganizationsSuccess, userOrganizationsFailure],
  })
)(dispatch, getState);

export const loadUserReferrals = () => (dispatch,
  getState) => (
  fetchData({
    endpoint: 'user/referrals',
    actions: [userReferralsRequest, userReferralsSuccess, userReferralsFailure],
  })
)(dispatch, getState);

export const waitlistUser = (body) => (dispatch, getState) => (
  fetchData({
    endpoint: 'waitlist',
    actions: [generalRequest, generalSuccess, generalFailure],
    httpMethod: 'post',
    body,
  })
)(dispatch, getState);

export const inviteUser = (body) => (dispatch, getState) => (
  fetchData({
    endpoint: 'invite',
    actions: [generalRequest, generalSuccess, generalFailure],
    httpMethod: 'post',
    body,
  })
)(dispatch, getState);

export const uploadUserImages = (body, ouid) => (dispatch, getState) => (
  fetchData({
    endpoint: `user/${ouid}/image/upload`,
    actions: [generalRequest, generalSuccess, generalFailure],
    httpMethod: 'post',
    isMedia: true,
    body,
  })
)(dispatch, getState);

export const loadUserRewards = (uuid) => (dispatch, getState) => (
  fetchData({
    endpoint: `user/${uuid}/rewards`,
    actions: [userRewardsRequest, userRewardsSuccess, userRewardsFailure],
  })
)(dispatch, getState);
