import { authSuccess } from 'store/reducers/user';

export function saveToken(token) {
  if (!token) return;
  localStorage.setItem('token', token);
}

export function deleteToken() {
  localStorage.removeItem('token');
  localStorage.removeItem('userStatus');
}

export function logOut() {
  return (dispatch, getState) => {
    localStorage.clear();
    dispatch(authSuccess({ data: { token: null } }));
    window.location.assign('/');
  };
}

export function continueWithReferralFlow(navigate) {
  const bountySlug = localStorage.getItem('referralSlug');
  const companySlug = localStorage.getItem('companySlug');
  navigate(`/c/${companySlug}/j/${bountySlug}/bounty?submit=true&step=2`);
}
