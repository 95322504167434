/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { logOut } from 'util/authorization';
import Balloon from 'components/Balloon';
import './Navbar.scss';
import Avatar from 'components/Avatar';
import { setMobileMenuStatus } from 'store/reducers/ui';

export default function ReferrerSection({ user = {}, isMobile }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const btnRef = useRef();
  const [isOpen, openDropdown] = useState(false);

  const avatar = user.links?.image.user || user.links?.linkedin.image;

  const onClickDropdownItem = (item) => {
    openDropdown(false);
    dispatch(setMobileMenuStatus({ status: false }));
    if (item.name === 'Log out') {
      dispatch(logOut());
    } else navigate(item.path);
  };

  const consumerNavbarItems = [
    {
      name: 'Profile',
      path: '/u/profile',
      id: 1,

    },
    {
      name: 'Set up my business',
      path: '/signup/organization',
      id: 2,

    },
    {
      name: 'Log out',
      id: 3,
    },
  ];

  return (
    <div className="navbar-section">
      {
        !isMobile && (
          <div className="navbar-section__avatar" ref={btnRef} onClick={() => openDropdown(!isOpen)}>
            <Avatar
              src={avatar}
              alt="user-avatar"
              className="pointer"
              size="medium"
              fallbackContent={user?.firstName?.charAt(0)}
            />
          </div>
        )
      }
      {
        isMobile && (
          <ul>
            { consumerNavbarItems.map((item) => <li onClick={() => onClickDropdownItem(item)}>{item.name}</li>,
            )}
          </ul>
        )
      }
      { isOpen && (
      <Balloon
        openDropdown={(status) => openDropdown(status)}
        ref={btnRef}
        className="navbar-dropdown"
        items={consumerNavbarItems}
        onClick={onClickDropdownItem}
      />
      )}
    </div>
  );
}
