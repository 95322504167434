/* eslint-disable react/jsx-props-no-spreading */

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './TextEditor.scss';

const modules = {
  toolbar: [
    ['bold', 'italic'],
    [{ header: [1, 2, false] }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link'],
  ],
};

function TextEditor({ onChange, value, ...props }) {
  return (
    <ReactQuill
      modules={modules}
      value={value}
      onChange={(value) => onChange(value)}
      {...props}
    />
  );
}

export default TextEditor;
