function TableStatusLoadingBar() {
  return (
    <svg width="96" height="28" viewBox="0 0 96 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="95" height="27" rx="3.5" fill="white" />
      <rect x="16" y="8" width="64" height="12" rx="2" fill="url(#paint0_linear_1200_3001)" />
      <rect x="0.5" y="0.5" width="95" height="27" rx="3.5" stroke="#CACAD9" />
      <defs>
        <linearGradient id="paint0_linear_1200_3001" x1="80" y1="14" x2="16" y2="14" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D8D8E4" />
          <stop offset="1" stopColor="#F4F4FA" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default TableStatusLoadingBar;
