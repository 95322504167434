import Checkbox from 'components/Checkbox';
import ListItem from 'components/ListItem';
import { useDispatch } from 'react-redux';
import { editForm } from 'store/reducers/createBountyForm';
import { parseMoneyInt, prettifyLocations } from 'util';
import { trackEvent } from 'util/tracking';

function BountyConfirmation({ data }) {
  const dispatch = useDispatch();
  const {
    currentJob, referralAmount, inRole, isPublic,
  } = data || {};

  return (
    <div>
      <ListItem
        item={{ ...currentJob, subtitle: prettifyLocations(currentJob?.locations) }}
        readOnly
        hoverEffect={false}
        variation="medium"
      />
      <ListItem
        item={{ text: `$ ${parseMoneyInt(referralAmount)}` }}
        highlight={false}
        fieldName="Referral amount"
        readOnly
        hoverEffect={false}
      />
      <ListItem
        item={{ text: inRole?.name || inRole?.value }}
        highlight={false}
        fieldName="How long does the referral need to be in role?"
        readOnly
        hoverEffect={false}
      />
      <Checkbox
        key="share"
        variant="square"
        text="Share with the Refer community"
        isSelected={isPublic}
        onClick={() => {
          if (isPublic) {
            trackEvent('Employer: Make bounty private');
          }
          dispatch(editForm({ key: 'isPublic', value: !isPublic }));
        }}
      />
    </div>
  );
}

export default BountyConfirmation;
