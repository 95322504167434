import Card from 'components/Card';
import './JobDescriptionCard.scss';

function JobDescriptionCard({ job }) {
  const { name, description } = job || {};
  return (
    <Card
      title={name}
      noPadding
      variant="small"
    >
      <p className="job-description-card">{description}</p>
    </Card>
  );
}

export default JobDescriptionCard;
