/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import classNames from 'classnames';

function BalloonDropdown({
  coords,
  wrapperRef,
  onClickDropdownItem,
  items,
  className,
}) {
  return (
    <ul
      className={classNames(className, 'balloon-dropdown')}
      style={coords}
      ref={wrapperRef}
    >
      {items.map((el) => (
        <li key={el.id} onClick={(e) => onClickDropdownItem(e, el)}>
          {el.name || el.value}
        </li>
      ))}
    </ul>
  );
}

export default BalloonDropdown;
