export const listItems = [
  {
    id: 'greenhouse',
    icon: 'greenhouse',
    text: 'Greenhouse',
  },
  {
    id: 'no-ats',
    icon: 'no-ats',
    text: 'Manually create a job',
  },
];

export const atsSuggestions = [
  {
    id: 'ashby',
    icon: 'ashby',
    text: 'Ashby',
  },
  {
    id: 'lever',
    icon: 'lever',
    text: 'Lever',
  },
  {
    id: 'linkedin',
    icon: 'linkedin',
    text: 'Linkedin',
  },
  {
    id: 'workable',
    icon: 'workable',
    text: 'Workable',
  },
  {
    id: 'workday',
    icon: 'workday',
    text: 'Workday',
  },
  {
    id: 'other',
    icon: 'pencil',
    type: 'input',
    text: 'Other',
  },
];
