import { useState } from 'react';
import ReactModal from 'react-modal';
import ReactQuill from 'react-quill';
import { useDispatch } from 'react-redux';
import Icon from 'components/Icon';
import ModalContent from 'components/ModalContent';
import './LinkedinShare.scss';
import { LinkedinShareText } from 'actions/linkedin';
import toast from 'components/Alerts';
import { trackEvent } from 'util/tracking';

export default function LinkedinShare({
  bounty, organization, shareLinkText, job,
}) {
  const dispatch = useDispatch();
  const [isOpen, openModal] = useState(false);
  const [descriptionValue, setDescription] = useState(`Hey everyone! ${organization?.name} is growing and we're hiring for a great ${job?.title}! Know someone who would be a great fit? Send us your referrals and earn ${bounty?.referral?.localized} when they get hired.<br><br>To refer someone, simply click the link to get started! #hiring #refer<br><br>${shareLinkText}`);

  const onShareToLinkedin = () => {
    dispatch(LinkedinShareText(
      descriptionValue
        .replaceAll('<br><br>', ' ')
        .replaceAll('<br>', ' ')
        .replaceAll('<p>', '')
        .replaceAll('</p>', '')
        .replaceAll('<', '')
        .replaceAll('/>', ''),
    )).then(({ status }) => {
      if (status === 201) {
        trackEvent('Employer: Share on Linkedin');
        toast({ subtitle: 'Bounty shared successfully' });
      }
    });
  };

  return (
    <>
      <div onClick={() => openModal(true)} className="linkedin-share">
        <Icon name="linkedin-filled" />
        Share on LinkedIn
      </div>
      <ReactModal
        ariaHideApp={false}
        isOpen={isOpen}
        className="modal-content-quill"
      >
        <ModalContent
          title="Share on Linkedin"
          onClose={() => openModal(false)}
          primaryButtonAction={onShareToLinkedin}
          primaryButton="Share on Linkedin"
        >
          <ReactQuill
            modules={{ toolbar: null }}
            onChange={(value) => setDescription(value)}
            value={descriptionValue}
          />
        </ModalContent>
      </ReactModal>
    </>
  );
}
