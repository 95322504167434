function Avatar({ width = 96, height = 96, fill = '#5b5b65' }) {
  return (
    <svg width={width} height={height} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3981_390)">
        <rect width={width} height={height} rx="18" fill="#1BA00B" />
        <path fillRule="evenodd" clipRule="evenodd" d="M18 4.41167C20.2408 4.41167 22.2697 5.32013 23.7373 6.7881L23.8063 6.86271C25.2332 8.32638 26.1132 10.3257 26.1132 12.525C26.1132 14.7658 25.2052 16.794 23.7373 18.262C22.2695 19.7304 20.2407 20.6379 18 20.6379C15.7648 20.6379 13.7353 19.7284 12.2653 18.2592C10.7946 16.7941 9.88658 14.7658 9.88658 12.5252C9.88658 10.2909 10.7961 8.26318 12.2625 6.79351L12.2715 6.78544C13.7403 5.31994 15.7675 4.41187 17.9998 4.41187L18 4.41167ZM18 0C22.9697 0 27.4717 2.01447 30.728 5.272C33.9856 8.5285 36 13.0303 36 18C36 22.9697 33.9855 27.4717 30.728 30.728C27.4715 33.9856 22.9697 36 18 36C13.0303 36 8.52833 33.9855 5.272 30.728C2.01443 27.4715 0 22.9697 0 18C0 13.0303 2.01447 8.52833 5.272 5.272C8.5285 2.01443 13.0303 0 18 0ZM29.4743 6.526C26.5381 3.58927 22.4807 1.77267 18 1.77267C13.5193 1.77267 9.462 3.5892 6.52567 6.526C3.58893 9.4622 1.77233 13.5197 1.77233 18.0003C1.77233 21.3867 2.81073 24.5313 4.58523 27.133C5.01283 26.2959 5.53017 25.5115 6.12403 24.793C7.97977 22.5485 10.591 20.9417 13.5604 20.3724C13.8018 20.328 14.0588 20.3544 14.297 20.4659C14.8671 20.7314 15.4729 20.9393 16.1007 21.0786C16.6964 21.2109 17.3338 21.2803 18 21.2803C18.6661 21.2803 19.3035 21.2109 19.8992 21.0786C20.5087 20.9435 21.0963 20.7441 21.6515 20.49C21.8849 20.3672 22.1608 20.319 22.4396 20.3724C25.4088 20.9416 28.0199 22.5484 29.8759 24.793C30.4698 25.5117 30.9871 26.2959 31.4147 27.133C33.1888 24.5317 34.2276 21.3877 34.2276 18.0003C34.2276 13.5197 32.4111 9.46235 29.4743 6.52601L29.4743 6.526Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_3981_390">
          <rect width={width} height={height} rx="18" fill="white" />
        </clipPath>
      </defs>
    </svg>

  );
}

export default Avatar;
