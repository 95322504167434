import './TableListColumn.scss';

function TableListColumn({
  item, id, component, isLoading,
}) {
  if (!id) return <div className="empty" />;
  const Element = component;
  return (
    <div className={`table-list-column ${id}`}>
      <Element item={item} isLoading={isLoading} />
    </div>
  );
}

export default TableListColumn;
