/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable max-len */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Icon from 'components/Icon';
import Balloon from 'components/Balloon';
import { statusOptions } from 'util/constants';
import {
  approveApplication, changeApplicationStep, loadApplications, rejectApplication, syncApplication,
} from 'actions/referrals';
import { useParams } from 'react-router-dom';
import {
  getOrgUuid, referralEmailSubject, getLabelStyle, getKnobStyle,
} from 'util';
import toast from 'components/Alerts';
import { trackEvent } from 'util/tracking';
import classNames from 'classnames';
import AcceptCandidateModal from 'components/Modals/AcceptCandidateModal';
import CompleteIntegrationModal from 'components/Modals/CompleteIntegrationModal';
import StageModal from 'components/Modals/StageModal';

export default function ({ item = {}, isLoading }) {
  const dispatch = useDispatch();
  const params = useParams();
  const btnRef = React.createRef();
  const [isCompleteIntegrationAlert, openCompleteIntegrationsAlert] = useState(false);
  const [isAcceptCandidateAlertOpen, openAcceptCandidateAlert] = useState(false);
  const [isStageModalOpen, openStageModal] = useState(false);
  const [isOpen, openDropdown] = useState(false);
  const { interview } = useSelector((state) => state.main);
  const { organizations } = useSelector((state) => state.user);
  const currentOrganization = organizations.filter((org) => org.slug === params.companySlug)[0];
  const isConnectedToGH = currentOrganization?.departments?.[0].configuration?.ats?.greenhouse;
  const isSyncedToGH = isConnectedToGH?.sync;
  const isManualJob = item.job?.ats?.type?.id === 3;
  const interviewStage = item.application?.ats?.interview?.stage || item.application?.interview?.stage?.value;
  const candidateFirstName = item.candidate?.firstName;
  const candidateLastName = item.candidate?.lastName;
  // Is job is from GH and user has already accepted or passed, disable dropdow.
  // We'll just show whatever stage GH is returning at this point.
  const disableDropdown = interviewStage && !isManualJob;

  const openDropdownAction = (e) => {
    if (disableDropdown) return;

    e.stopPropagation();
    if (!isOpen) trackEvent('Employer: Action');

    openDropdown(!isOpen);
  };

  const onClickDropdownItem = async (action) => {
    openDropdown(false);

    if (isManualJob) {
      const setStage = await dispatch(changeApplicationStep({ applicationId: item.application.uuid, stagIid: action.id }));
      if (setStage.status === 200) {
        if (setStage.data.data.education.eligible) {
          return openStageModal(true);
        }
        dispatch(loadApplications(getOrgUuid(params.companySlug, organizations)));
      }
    }
    // Reject action
    if (action.id === 2) {
      trackEvent('Employer: Pass on applicant');
      handleAction(rejectApplication);
    }

    // Accept/Sync action
    if (action.id === 1) {
      trackEvent('Employer: Accept applicant');
      if (isConnectedToGH) {
        if (item.job.ats.type.value !== 'Refer') {
          if (isSyncedToGH) {
            if (localStorage.getItem('educationCounter') > 0) {
              handleAction(syncApplication, true);
            } else openAcceptCandidateAlert(true);
          } else {
            openCompleteIntegrationsAlert(true);
          }
        } else {
          handleAction(approveApplication);
        }
      } else {
        handleAction(approveApplication);
      }
    }
  };

  const handleAction = (apiCall, displaySuccessToast) => {
    dispatch(apiCall(item.application.uuid))
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          dispatch(loadApplications(getOrgUuid(params.companySlug, organizations)));
          if (displaySuccessToast) {
            toast({ subtitle: 'Sync in progress, it may take a minute to update in Greenhouse' });
          }
        }
      });
  };

  const onSendMessage = () => {
    trackEvent('Employer: Message applicant');
    window.location = `mailto:${item.referral?.email}?subject=Your ${
      currentOrganization?.name
    } referral&body=${encodeURIComponent(
      referralEmailSubject(
        item.referral,
        item.job,
        currentOrganization,
        candidateFirstName,
        candidateLastName,
      ),
    )}`;
  };

  const closeStageModal = () => {
    openStageModal(false);
    dispatch(loadApplications(getOrgUuid(params.companySlug, organizations)));
  };

  if (isLoading) return <Icon name="table-status-loading-bar" />;

  return (
    <div
      className="action-container"
      id={item.application.id}
    >
      <div ref={btnRef}>
        <div
          className={classNames('action-toggle status-label flex-row', getLabelStyle(interviewStage))}
          onClick={openDropdownAction}
        >
          <p className="mr-5">{interviewStage || 'New'}</p>
          { !disableDropdown && <Icon name="knobDown" style={{ fill: getKnobStyle(interviewStage) }} />}
        </div>
      </div>
      { isOpen && (
      <Balloon
        className="column-action-dropdown"
        openDropdown={(status) => openDropdown(status)}
        ref={btnRef}
        items={isManualJob ? interview.stage : statusOptions}
        onClick={(item) => onClickDropdownItem(item)}
      />
      )}
      {isAcceptCandidateAlertOpen && (
      <AcceptCandidateModal onClose={openAcceptCandidateAlert} isOpen={isAcceptCandidateAlertOpen} handleAction={handleAction} />
      )}
      {isCompleteIntegrationAlert && (
      <CompleteIntegrationModal onClose={openCompleteIntegrationsAlert} isOpen={isCompleteIntegrationAlert} />
      )}
      {isStageModalOpen && (
      <StageModal onClose={closeStageModal} isOpen={isStageModalOpen} candidateLastName={candidateLastName} candidateFirstName={candidateFirstName} onSendMessage={onSendMessage} />
      )}
    </div>
  );
}
