import Avatar from 'components/Avatar';

function ReferrerSection({
  users = {}, referrerLastJob, rankingInfo, job, referral,
}) {
  return (
    <>
      <h3>Referred by</h3>
      <div className="referrer flex-row">
        <Avatar
          alt="referrer-avatar"
          size="large"
          src={users.referrer.links?.photo}
          fallbackContent={users.referrer?.firstName?.charAt(0)}
        />
        <div className="flex-column ml-10">
          <p className="bold">{ `${users.referrer?.firstName} ${users.referrer?.lastName}`}</p>
          {referrerLastJob && (
          <p>
            {referrerLastJob.title}
            {' '}
            •
              {' '}
            {referrerLastJob.company?.name}
          </p>
          )}
          <a
            className="f11"
            href={users.referrer.links?.linkedin}
            target="_blank"
            rel="noreferrer"
          >
            View on Linkedin

          </a>
        </div>
      </div>
      {rankingInfo}
      { job.bounty.question && (
      <div className="mt-10" key={job.bounty.question}>
        <p className="bold">{job.bounty.question}</p>
        <p className="">{referral.response}</p>
      </div>
      ) }
    </>
  );
}

export default ReferrerSection;
