import mixpanel from 'mixpanel-browser';

export const trackEvent = (name) => {
  if (process.env.REACT_APP_MODE !== 'production') return;
  mixpanel.track(name);
};

const identify = (uuid) => mixpanel.identify(uuid);

const setUserProfile = (properties) => mixpanel.people.set(properties);

export const identifyUser = (user) => {
  if (!user || process.env.REACT_APP_MODE !== 'production') return;
  identify(user.uuid);
  setUserProfile({
    first_name: user.firstName,
    last_name: user.lastName,
    email: user.email,
    phone: user.phone?.localized,
  });
};

export const initTracking = () => {
  if (process.env.REACT_APP_MODE !== 'production') return;
  // Production token
  const mixpanelToken = '9b171ac6b5736cafad46948c563e5c07';
  mixpanel.init(mixpanelToken);
};
