/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Modal from 'react-modal';
import { editForm, clearForm } from 'store/reducers/editJobForm';
import { editJob, loadJobs } from 'actions/jobs';
import toast from 'components/Alerts';
import Card from 'components/Card';
import Alert from 'components/Alert';
import Dropdown from 'components/Dropdown';
import Input from 'components/Input';
import FacetsSelector from 'components/FacetsSelector';
import TextEditor from 'components/TextEditor';
import { prettifyLocationsData, parseMoneyInt } from 'util';
import SearchDropdown from 'components/SearchDropdown';

function EmployerJobEdit() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const [isModalOpen, openModal] = useState(false);
  const { job } = useSelector((state) => state.main);
  const organizations = useSelector((state) => state.user.organizations);
  const currentOrganization = organizations.filter((org) => org.slug === params.companySlug);
  const slug = currentOrganization[0]?.slug;
  const {
    uuid, description, title, locations, type, role, experience, salaryMax, salaryMin, public: isPublic, ats,
  } = useSelector((state) => state.editJobForm);

  useEffect(() => () => dispatch(clearForm()), []);

  const onCancel = () => {
    navigate(`/c/${slug}/jobs`);
  };

  const onSave = () => {
    dispatch(editJob({
      uuid,
      atsJobId: ats?.job?.id,
      atsTypeId: ats?.type?.id || 3,
      atsPostId: ats?.post?.id,
      description,
      title,
      locations: prettifyLocationsData(locations, true),
      employmentTypeId: type?.id,
      roleId: role?.id,
      workExperienceId: experience?.id,
      salaryMax,
      salaryMin,
      public: isPublic,
    },
    currentOrganization[0]?.uuid,
    ))
      .then((res) => {
        if (res.status === 200) {
          dispatch(loadJobs(currentOrganization[0]?.uuid));
          openModal(false);
          clearForm();
          navigate(-1);
          toast({ subtitle: 'Your changes have been saved' });
        }
      });
  };

  return (
    <>
      <Helmet title="Edit job | Refer" />
      <Modal
        ariaHideApp={false}
        isOpen={isModalOpen}
        onRequestClose={() => openModal(false)}
        shouldCloseOnOverlayClick
      >
        <Alert
          alert={{ title: 'Confirm changes', subtitle: 'Are you sure you want to save changes to your job listing? This will update your bounty as well.' }}
          buttonPrimary="Save"
          buttonPrimaryAction={onSave}
          onClose={() => openModal(false)}
        />
      </Modal>
      <Card
        title="Edit job"
        subtitle="Fill out the details below for the role"
        buttons={[
          {
            id: 'cancel',
            text: 'Cancel',
            variant: 'reverse-secondary',
            onClick: onCancel,
          },
          {
            id: 'save',
            text: 'Save',
            variant: !locations.length ? 'disabled' : 'default',
            onClick: () => openModal(true),
          },
        ]}
      >
        <Input
          id="title"
          placeholder="Job title"
          onChange={(value) => dispatch(editForm({ key: 'title', value }))}
          value={title}
        />
        <TextEditor
          placeholder="Describe the responsibilities of the position."
          value={description}
          onChange={(value) => dispatch(editForm({ key: 'description', value }))}
        />
        <div className="flex-row mt-10">
          <Dropdown
            className="mr-10"
            placeholder="Job type (e.g., Full-time)"
            items={job.employment.type}
            onClick={(value) => dispatch(editForm({ key: 'type', value }))}
            value={type?.value}
          />
          <Dropdown
            placeholder="Work experience"
            items={job.work.experience}
            onClick={(value) => dispatch(editForm({ key: 'experience', value }))}
            value={experience?.value}
          />
        </div>
        <SearchDropdown
          placeholder="Add a role"
          items={job.roles}
          onClick={(value) => dispatch(editForm({ key: 'role', value }))}
          value={role?.value}
        />
        <div className="flex-row">
          <Input
            id="salary-min"
            placeholder="Salary min (Optional)"
            onChange={(value) => dispatch(editForm({ key: 'salaryMin', value }))}
            value={parseMoneyInt(salaryMin)}
            className="mr-10 w-100"
            isMoney
          />
          <Input
            id="salary-max"
            placeholder="Salary max (Optional)"
            onChange={(value) => dispatch(editForm({ key: 'salaryMax', value }))}
            value={parseMoneyInt(salaryMax)}
            className="w-100"
            isMoney
          />
        </div>
        <FacetsSelector
          value={locations}
          placeholder="Locations (e.g., Los Angeles)"
          onSelectFacet={(value) => dispatch(editForm({ key: 'locations', value }))}
        />
      </Card>
    </>
  );
}

export default EmployerJobEdit;
