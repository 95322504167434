/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import { Link, useLocation } from 'react-router-dom';
import './Navbar.scss';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { setMobileMenuStatus } from 'store/reducers/ui';
import Button from 'components/Button';
import { useState } from 'react';
import ReactModal from 'react-modal';
import ContactForm from 'components/ContactForm';

export default function LoggedOutSection() {
  const location = useLocation();
  const dispatch = useDispatch();
  const path = location.pathname;
  const [isOpen, openModal] = useState();

  return (
    <div className="navbar-section">
      <Link
        className={classNames(path === '/refer' && 'active', 'item', 'logged-out-item')}
        to="/refer"
        onClick={() => dispatch(setMobileMenuStatus({ status: false }))}
      >
        Refer
      </Link>
      <Link
        className={classNames(path === '/employer' && 'active', 'item', 'logged-out-item')}
        to="/employer"
        onClick={() => dispatch(setMobileMenuStatus({ status: false }))}
      >
        I&apos;m hiring
      </Link>
      <Button
        size="small"
        text="Request demo"
        className="home-contact-us"
        variant="white-round"
        onClick={() => {
          dispatch(setMobileMenuStatus({ status: false }));
          openModal(true);
        }}
      />
      <ReactModal
        ariaHideApp={false}
        isOpen={isOpen}
        className="modal-content-quill"
        onRequestClose={() => openModal(false)}
        shouldCloseOnOverlayClick
      >
        <ContactForm openModal={openModal} />
      </ReactModal>
    </div>
  );
}
