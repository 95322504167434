import AccordionItem from './AccordionItem';
import './Accordion.scss';

function Accordion({ items }) {
  const accordionItems = items.map((item, id) => (
    <AccordionItem key={item.id} item={item} id={id} />
  ));

  return (
    <div className="accordion">
      { accordionItems }
    </div>
  );
}

export default Accordion;
