/* eslint-disable no-unused-vars */
/* eslint-disable max-len */

import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import classNames from 'classnames';
import Icon from 'components/Icon';
import './BountiesSidebar.scss';

function BountiesSidebar({
  createBountyButton, items, onClick, forceSelection = true, currentBounty,
}) {
  const params = useParams();
  const { bountySlug, companySlug } = params;
  const { organizations } = useSelector((state) => state.user);
  const { data } = useSelector((state) => state.organizations);
  const currentOrganization = data[companySlug];
  const companyBounties = items || currentOrganization?.departments[0]?.jobs?.filter((el) => el.bounty) || [];
  let bountyId = currentBounty || (bountySlug || companyBounties[0]?.bounty.slug);

  if (!forceSelection) {
    bountyId = null;
  }
  const avatar = currentOrganization || organizations.filter((el) => el.slug === companySlug)[0];

  const jobList = companyBounties?.map((el) => {
    const {
      title, bounty, uuid, status,
    } = el;
    return (
      <div
        className={classNames(
          'flex-row',
          `bounty-status--${status}`,
          `${bountyId === bounty.slug && 'bounty--selected'}`,
          `${bountyId === uuid && 'bounty--selected'}`,
        )}
        key={uuid}
      >
        <div className="flex-row w-100 justify-between" onClick={() => onClick(el)}>
          <div className="flex-row">
            <img className="bounty-avatar" src={avatar?.links?.logo?.medium} alt="decorative" />
            <div className="flex-column">
              <p className="bold">{ title }</p>
              { bounty && (
              <p className="grey">
                { bounty?.referral?.localized }
              </p>
              )}
            </div>
          </div>
          { status === 'closed' && <p className="italic bold">Inactive</p>}
        </div>
      </div>
    );
  });
  return (
    <div className="employer-jobs">
      { jobList }
      { createBountyButton && (
        <Link to={`/c/${companySlug}/jobs`} className="create-bounty-button">
          <span>
            <Icon name="close" styleProps={{ fill: '#63637B' }} />
            Create bounty
          </span>
        </Link>
      )}
    </div>
  );
}

export default BountiesSidebar;
