const supportedIconNames = {
  avatar: require('./Avatar'),
  'arrow-down': require('./ArrowDown'),
  'arrow-left': require('./ArrowLeft'),
  'arrow-right': require('./ArrowRight'),
  ashby: require('./Ashby'),
  bin: require('./Bin'),
  burger: require('./Burger'),
  brex: require('./Brex'),
  briefcase: require('./BriefCase'),
  'bounty-active': require('./BountyActive'),
  camera: require('./Camera'),
  calendar: require('./Calendar'),
  card: require('./Card'),
  checkmark: require('./Checkmark'),
  'checkmark-circle': require('./CheckmarkCircle'),
  'checkmark-square': require('./CheckmarkSquare'),
  'checkmark-grey': require('./CheckmarkGrey'),
  close: require('./Close'),
  email: require('./Email'),
  filters: require('./Filters'),
  greenhouse: require('./Greenhouse'),
  knobDown: require('./KnobDown'),
  lever: require('./Lever'),
  link: require('./Link'),
  linkedin: require('./Linkedin'),
  'linkedin-filled': require('./LinkedinFilled'),
  'money-bag': require('./MoneyBag'),
  'money-balloons': require('./MoneyBalloons'),
  mercury: require('./Mercury'),
  'no-ats': require('./NoAts'),
  pencil: require('./Pencil'),
  plaid: require('./Plaid'),
  ramp: require('./Ramp'),
  referred: require('./Referred'),
  'refer-checkmark': require('./ReferCheckmark'),
  'table-loading-bar': require('./TableLoadingBar'),
  'table-candidate-loading-bar': require('./TableCandidateLoadingBar'),
  'table-status-loading-bar': require('./TableStatusLoadingBar'),
  user: require('./User'),
  verticalDots: require('./VerticalDots'),
  trophy: require('./Trophy'),
  workday: require('./Workday'),
  workable: require('./Workable'),
};

export default supportedIconNames;
