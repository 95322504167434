import ListItem from 'components/ListItem';
import {
  getStageStyle,
  prettifyWebsite,
  referralStatus,
  parseMoneyInt, prettifyLocations, statusStyle,
} from 'util';

export const getOpenJobsList = (jobs) => {
  if (!jobs.length) return [];
  return jobs.map(({
    uuid, title, organization, locations, salary, bounty,
  }) => {
    const showCustom = () => <p className="bold green-text">{bounty?.referral?.localized}</p>;
    const jobSalary = salary ? `• ${salary.localized.min ? `${salary.localized.min} - ` : ''}${salary.localized.max || ''}` : '';
    return (
      <ListItem
        key={uuid}
        to={`/c/${organization.slug}/j/${bounty.slug}`}
        hoverEffect
        item={{
          text: title,
          subtitle: (
            <span className="flex-column">
              <span>{`${organization.name} (${organization.funding?.value})`}</span>
              <span>{`${prettifyLocations(locations)} ${jobSalary}`}</span>
            </span>
          ),
          avatar: organization.links.logo?.medium,
        }}
        className="open-jobs-list"
        showCustom={showCustom}
        readOnly
      />
    );
  });
};

export const referralsList = (referrals, onClickUser) => referrals.map(({
  referral, job, organization, application,
}, index) => {
  const { confirmed, rejected, reminded } = referral.dates;
  const status = referralStatus(referral.dates);
  const subtitle = `${organization.name} • ${job.title}`;
  const customInfo = () => (
    <div className="flex-column align-end">
      <p className="bold">
        {job.bounty?.referral?.localized}
      </p>
      {
        application?.interview?.stage
          ? (
            <p
              style={{ color: getStageStyle(application.interview.stage) }}
            >
              {application.interview.stage}
            </p>
          )
          : <p style={statusStyle(confirmed, rejected, reminded)}>{status}</p>
      }

    </div>
  );
  return (
    <ListItem
      key={Math.floor(Math.random() * 9999)}
      item={{
        text: `${referral.firstName} ${referral.lastName}`,
        subtitle,
        avatar: '',
      }}
      onClick={() => onClickUser(index)}
      variation="medium"
      hoverEffect
      showCustom={customInfo}
      readOnly
    />
  );
});

export const companyCardSubtitle = ({ linkedin, headline, website }) => (
  <div>
    { headline && <p>{headline}</p>}
    { linkedin && <a href={linkedin} target="_blank" rel="noreferrer">Linkedin profile</a>}
    { website && <a href={website}>{prettifyWebsite(website)}</a>}
  </div>
);

export const referralsCardSubtitle = (amountMade) => (
  <p>
    {' '}
    You have pending referrals worth up to
    {' '}
    <span className="bold green-text">
      $
      {amountMade ? parseMoneyInt(amountMade) : 0}
    </span>
    {' '}
  </p>
);
