import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Card from 'components/Card';
import toast from 'components/Alerts';
import Dropdown from 'components/Dropdown';
import Input from 'components/Input';
import FacetsSelector from 'components/FacetsSelector';
import TextEditor from 'components/TextEditor';
import { editForm, clearForm } from 'store/reducers/editJobForm';
import { createJob, loadJobs } from 'actions/jobs';
import { parseMoneyInt, prettifyLocationsData, getOrgUuid } from 'util';
import SearchDropdown from 'components/SearchDropdown';
import { trackEvent } from 'util/tracking';

function EmployerJobCreate() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const { organizations } = useSelector((state) => state.user);
  const { employment, work, roles } = useSelector((state) => state.main.job);
  const {
    title, description, type, experience, locations, role, ats, salaryMax, salaryMin,
  } = useSelector((state) => state.editJobForm);
  const canCreateBounty = title && description && locations.length && type;
  const organizationUuid = getOrgUuid(params.companySlug, organizations);

  const onNext = () => {
    dispatch(createJob({
      atsJobId: ats?.job?.id,
      atsTypeId: ats?.type?.id || 3,
      atsPostId: ats?.post?.id,
      title,
      description,
      employmentTypeId: type?.id,
      workExperienceId: experience?.id,
      locations: prettifyLocationsData(locations),
      roleId: role?.id,
      salaryMax,
      salaryMin,
      public: true,
    }, organizationUuid)).then(({ data }) => {
      if (data) {
        trackEvent('Employer: Job created');
        dispatch(loadJobs(organizationUuid))
          .then((res) => {
            if (res.status === 200) {
              dispatch(clearForm());
              if (window.location.search.includes('post-action=createBounty')) {
                navigate(`/c/${params.companySlug}/bounty-details/${data?.data?.uuid}`);
              } else {
                navigate(`/c/${params.companySlug}/jobs`);
              }
              toast({ subtitle: 'Your job has been created' });
            }
          });
      }
    });
  };

  return (
    <>
      <Helmet title="Create job | Refer" />
      <Card
        title="Create job"
        subtitle="Fill out the details below for the role"
        buttons={[
          {
            id: 'card-form',
            type: 'submit',
            text: 'Next',
            variant: canCreateBounty ? 'default' : 'disabled',
            onClick: onNext,
          },
        ]}
      >
        <Input
          id="title"
          placeholder="Job title"
          onChange={(value) => {
            if (!title) trackEvent('Employer: job title');
            dispatch(editForm({ key: 'title', value }));
          }}
          value={title}
        />
        <TextEditor
          placeholder="Describe the responsibilities of the position."
          value={description}
          onChange={(value) => {
            if (!description) trackEvent('Employer: job description');
            dispatch(editForm({ key: 'description', value }));
          }}
        />
        <div className="flex-row mt-10">
          <Dropdown
            className="mr-10"
            placeholder="Job type (e.g., Full-time)"
            items={employment.type}
            onClick={(value) => {
              if (!title) trackEvent('Employer: job type');
              dispatch(editForm({ key: 'type', value }));
            }}
            value={type?.name}
          />
          <Dropdown
            placeholder="Work experience"
            items={work.experience}
            onClick={(value) => {
              if (!experience) trackEvent('Employer: work experience');
              dispatch(editForm({ key: 'experience', value }));
            }}
            value={experience?.name}
          />
        </div>
        <SearchDropdown
          placeholder="Add a role"
          items={roles}
          onClick={(value) => {
            if (!role) trackEvent('Employer: add a role');
            dispatch(editForm({ key: 'role', value }));
          }}
          value={role?.value}
        />
        <div className="flex-row">
          <Input
            id="salary-min"
            placeholder="Salary min (Optional)"
            onChange={(value) => {
              if (!salaryMin) trackEvent('Employer: salary min');
              dispatch(editForm({ key: 'salaryMin', value }));
            }}
            value={parseMoneyInt(salaryMin)}
            className="mr-10 w-100"
            isMoney
          />
          <Input
            id="salary-max"
            placeholder="Salary max (Optional)"
            onChange={(value) => {
              if (!salaryMin) trackEvent('Employer: salary max');
              dispatch(editForm({ key: 'salaryMax', value }));
            }}
            value={parseMoneyInt(salaryMax)}
            className="w-100"
            isMoney
          />
        </div>
        <FacetsSelector
          value={locations}
          placeholder="Locations (e.g., Los Angeles)"
          onSelectFacet={(value) => dispatch(editForm({ key: 'locations', value }))}
        />
      </Card>
    </>
  );
}

export default EmployerJobCreate;
