import { createSlice } from '@reduxjs/toolkit';

const referralsTableForm = createSlice({
  name: 'referralsTableForm',
  initialState: {},
  reducers: {
    setFiltersForm(state, { payload }) {
      const { key, value } = payload;
      const previousState = state[key] || [];

      if (key === 'all') {
        return state = value;
      }

      if (key === 'connection') {
        state[key] = value;
      } else if (previousState.includes(value)) {
        state[key] = previousState.filter((el) => el !== value);
      } else state[key] = [...previousState, value];
    },
    clearFiltersForm(state) {
      return state = {};
    },
  },
});

const { actions, reducer } = referralsTableForm;

export const {
  setFiltersForm,
  clearFiltersForm,
} = actions;

export default reducer;
