import { createSlice } from '@reduxjs/toolkit';

const createBountyForm = createSlice({
  name: 'createBountyForm',
  initialState: {
    job: null,
    referralAmount: null,
    inRole: null,
    customQuestion: '',
    slug: null,
    isPublic: null,
  },
  reducers: {
    initializeForm(state, { payload }) {
      const {
        description, locations, title, bounty, ats,
      } = payload.job;
      const {
        inRole = null, customQuestion = '', slug, referral, question,
      } = bounty || {};

      return {
        job: {
          description, locations, title, ats,
        },
        inRole,
        slug,
        customQuestion: question,
        referralAmount: referral?.amount,
        isPublic: payload.job.public,
      };
    },
    editForm(state, { payload }) {
      state[payload.key] = payload.value;
    },
    clearForm(state) {
      return {
        job: null,
        referralAmount: null,
        inRole: null,
        customQuestion: '',
        slug: null,
      };
    },
  },
});

const { actions, reducer } = createBountyForm;

export const {
  initializeForm,
  editForm,
  clearForm,
} = actions;

export default reducer;
