import { useEffect } from 'react';

function ScrollToTop(props) {
  const location = window.location.pathname;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return props.children;
}

export default ScrollToTop;
