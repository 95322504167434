import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Input.scss';
import Icon from 'components/Icon';

function Input({
  placeholder, inputType = 'input', isMoney, id, className, error, icon, disableAnimation, showCustom, ...props
}) {
  const [inputValue, setValue] = useState('');
  const Component = inputType;

  useEffect(() => {
    setValue(props.value || '');
  }, [props.value]);

  const onChange = (e) => {
    const { value } = e.target;
    // let formattedValue = value.replace(/,/g, '');
    let formattedValue = value;

    if (isMoney) {
      formattedValue = formattedValue.replace(/,/g, '').trim();
    }

    if (isMoney && Number.isNaN(formattedValue)) return;

    setValue(formattedValue);
    props.onChange(formattedValue);
  };

  const onKeyDown = (e) => {
    if (props.onKeyDown && e.key === 'Enter') {
      props.onKeyDown(e.target.value);
      setValue('');
    }
  };

  return (
    <div className={classNames('input-field field flex-row', className, error && 'input-field--error')}>
      { inputValue
        && !disableAnimation
        && (
        <p className={classNames(
          'fieldName',
          inputType === 'textarea' && 'fieldName--textarea',
        )}
        >
          { placeholder }
        </p>
        )}
      { icon && <Icon name={icon} />}
      <span className="flex-row align-center w-100">
        <span className={classNames('currency', disableAnimation && 'disable-animation')}>{ isMoney && inputValue && '$'}</span>
        <Component
          className={classNames('input', disableAnimation && 'disable-animation')}
          id={id}
          onChange={onChange}
          type={props.type || 'text'}
          value={inputValue}
          autoComplete="off"
          onKeyDown={onKeyDown}
          placeholder={disableAnimation ? placeholder : ' '}
        />
        {!inputValue && !disableAnimation && <label className="placeholder" htmlFor={id}>{placeholder}</label>}
      </span>
      { showCustom && showCustom()}
    </div>
  );
}

export default Input;

Input.propTypes = {
  id: PropTypes.string.isRequired,
  /* Input should display dollar sign */
  isMoney: PropTypes.bool,
  /* Display error message + styling if input isn't valid */
  error: PropTypes.bool,
  placeholder: PropTypes.string,
  inputType: PropTypes.string,
};
