function VerticalDots() {
  return (
    <svg width="4" height="11" viewBox="0 0 4 11" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M3.5 1.5C3.5 2.32843 2.82843 3 2 3C1.17157 3 0.5 2.32843 0.5 1.5C0.5 0.671573 1.17157 0 2 0C2.82843 0 3.5 0.671573 3.5 1.5ZM3.5 5.5C3.5 6.32843 2.82843 7 2 7C1.17157 7 0.5 6.32843 0.5 5.5C0.5 4.67157 1.17157 4 2 4C2.82843 4 3.5 4.67157 3.5 5.5ZM2 11C2.82843 11 3.5 10.3284 3.5 9.5C3.5 8.67157 2.82843 8 2 8C1.17157 8 0.5 8.67157 0.5 9.5C0.5 10.3284 1.17157 11 2 11Z" fill="#150828" />
    </svg>

  );
}

export default VerticalDots;
