import { createSlice } from '@reduxjs/toolkit';

const alerts = createSlice({
  name: 'alerts',
  initialState: {
    errors: null,
    alert: {},
  },
  reducers: {
    setAlert(state, { payload }) {
      state.alert = payload;
    },
    setError(state, { payload }) {
      state.errors = payload;
    },
    clearError(state, { payload }) {
      state.errors = null;
    },
  },
});

const { actions, reducer } = alerts;

export const {
  setError,
  clearError,
  setAlert,
} = actions;

export default reducer;
