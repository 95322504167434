import Avatar from 'components/Avatar';
import Button from 'components/Button';

/* eslint-disable import/prefer-default-export */
export function ReferralsList({ referrals, buttonVariant, onSubmitPastReferral }) {
  return (
    <div className="flex-column referrals-list">
      { referrals.map(({ referral, user }) => (
        <div key={referral.email} className="flex-row justify-between referral-block align-center">
          <div className="flex-row">
            <Avatar
              size="medium"
              src={user?.links?.photo}
              fallbackContent={referral?.firstName?.charAt(0)}
            />
            <div className="ml-8">
              <p className="bold f11">{`${referral.firstName} ${referral.lastName}`}</p>
            </div>
          </div>
          <Button
            size="small"
            text="Refer"
            className="refer-button"
            variant={buttonVariant.includes(referral.email) ? 'submitted' : 'default'}
            onClick={() => onSubmitPastReferral(referral)}
          />
        </div>
      ))}
    </div>
  );
}
