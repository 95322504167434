import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { formatWebsite, isAdmin } from 'util';
import { createOrganization } from 'actions/organizations';
import { loadUserOrganizations } from 'actions/user';
import Card from 'components/Card';
import Dropdown from 'components/Dropdown';
import Input from 'components/Input';
import { candidateReferralFormValidation } from 'pages/ReferrerReferral/util';

function EmployerCreateOrganization() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { organizations, email } = useSelector((state) => state.user);
  const { funding: fundingOptions } = useSelector((state) => state.main.organization);
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [category, setCategory] = useState(null);
  const [linkedinProfile, setLinkedinProfile] = useState(null);
  const [website, setWebsite] = useState(null);
  const [funding, setFunding] = useState(null);
  const [errors, setError] = useState({});
  const canContinue = name && description && category && website && funding && linkedinProfile;

  useEffect(() => {
    if (organizations.length && !isAdmin(email)) {
      navigate('/');
    }
  }, []);

  const onNext = () => {
    if (linkedinProfile) {
      const { validLinkedin } = candidateReferralFormValidation({ linkedinProfile });
      if (!validLinkedin) {
        setError({ linkedinProfile: 'Please enter a valid Linkedin profile' });
        return;
      }
    }

    dispatch(createOrganization({
      name,
      description,
      category,
      fundingId: funding.id,
      website: formatWebsite(website),
      linedin: formatWebsite(linkedinProfile),
    })).then((res) => {
      if (res.status === 201) {
        const companySlug = res.data.data.slug;
        dispatch(loadUserOrganizations());
        navigate(`/c/${companySlug}/setup`);
      }
    });
  };

  const onBack = () => {
    navigate('/signup/account-type');
  };

  return (
    <>
      <Helmet title="Company info | Refer" />
      <Card
        title="Company info"
        subtitle="We need a few details about your company"
        buttons={[
          {
            id: 'back',
            text: 'Back',
            variant: 'reverse-secondary',
            onClick: onBack,
          },
          {
            id: 'card-form',
            type: 'submit',
            text: 'Next',
            variant: canContinue ? 'default' : 'disabled',
            onClick: onNext,
          },
        ]}
      >
        <Input
          id="name"
          placeholder="Company name"
          onChange={(value) => setName(value)}
          value={name}
        />
        <Input
          id="description"
          placeholder="Description"
          onChange={(value) => setDescription(value)}
          value={description}
        />
        <Input
          id="linkedin"
          placeholder={errors.linkedinProfile || 'What is your Linkedin URL?'}
          onChange={(value) => {
            setLinkedinProfile(value);
            setError({});
          }}
          value={linkedinProfile}
          error={!!errors.linkedinProfile}
        />
        <Input
          id="website"
          placeholder="Website"
          onChange={(value) => setWebsite(value)}
          value={website}
        />
        <div className="flex-row mt-10">
          <Dropdown
            placeholder="Funding round"
            className="mr-10"
            items={fundingOptions}
            onClick={(value) => setFunding(value)}
            value={funding?.value}
          />
          <Input
            id="category"
            placeholder="Category"
            onChange={(value) => setCategory(value)}
            value={category}
            className="w-100"
          />
        </div>
      </Card>
    </>
  );
}

export default EmployerCreateOrganization;
