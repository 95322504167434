/* eslint-disable max-len */

import { createReferral, createRequestedReferral } from 'actions/bounty';
import { phoneNumberRegex, linkedinRegex } from 'util/constants';
import { isValidEmail, formatWebsite } from 'util';
import { Step1Card, Step2Card, Step3Card } from './StepsCards';

export const stepsCards = {
  1: <Step1Card />,
  2: <Step2Card />,
  3: <Step3Card />,
};

export const getTitle = (step, requestedReferral) => {
  if (step === 1) return 'Candidate details';
  if (step === 2) {
    if (requestedReferral) return `How would you rank ${requestedReferral.referral.firstName}?`;
    else return 'Candidate ranking';
  }
  if (step === 3) return 'Your information';
  return '';
};

export const getDescription = (step) => {
  if (step === 1) {
    return 'Input the personal details of the candidate so that we can let them know they’ve been referred, and verify your connection.';
  }
  if (step === 2) {
    return 'Please only refer those you know will be a good fit for this role, it’s important to maintain a high quality bar.';
  }
  if (step === 3) {
    return 'You must signup to complete your referral. We use LinkedIn to verify your connections and will use your information to contact you when a referal has been hired.';
  }
  return '';
};

export const nextStepValidation = (step, { candidate }, hasCustomQuestion) => {
  if (step === 1) {
    return candidate.firstName
        && candidate.lastName
        && candidate.email
        && candidate.linkedinProfile;
  }
  if (step === 2) {
    const isRankingComplete = candidate.ranking?.ranking1
    && candidate.ranking?.ranking2
    && candidate.ranking?.ranking3;
    if (hasCustomQuestion) {
      return isRankingComplete && candidate.response;
    }
    return isRankingComplete;
  }
  if (step === 3) {
    return true;
  }
};

export const submitReferral = ({ companySlug, slug: bountySlug, candidateReferralForm }, requestedReferralId) => {
  const slug = bountySlug || localStorage.getItem('referralSlug');
  const bountyCompanySlug = companySlug || localStorage.getItem('companySlug');
  const requestedReferralUuid = requestedReferralId || sessionStorage.getItem('requestedReferralUuid');

  const data = JSON.parse(localStorage.getItem('referralInfo'))
    ? JSON.parse(localStorage.getItem('referralInfo'))
    : candidateReferralForm.candidate;

  if (!data?.firstName && !requestedReferralUuid) {
    window.location = `/c/${bountyCompanySlug}/j/${slug}?step=1`;
  }

  const { ranking1, ranking2, ranking3 } = data.ranking;

  let body = {
    ranking: [
      [1, parseInt(ranking1.id)],
      [2, parseInt(ranking2.id)],
      [3, parseInt(ranking3.id)],
    ],
  };

  if (requestedReferralUuid) {
    return (dispatch) => dispatch(createRequestedReferral({
      uuid: requestedReferralUuid,
      body,
    }));
  } else {
    body = {
      ...data,
      ranking: body.ranking,
      countryCodeId: data.phoneNumber ? data.countryCode.id : null,
      linkedinProfile: formatWebsite(data.linkedinProfile),
    };

    delete body.countryCode;
    return (dispatch) => dispatch(createReferral({
      slug,
      body,
    }));
  }
};

export const candidateReferralFormValidation = ({ email, phoneNumber, linkedinProfile }) => {
  const validEmail = isValidEmail(email);
  const validNumber = phoneNumber ? phoneNumber?.match(phoneNumberRegex) : true;
  const validLinkedin = linkedinProfile.match(linkedinRegex);
  return (
    { validEmail: !!validEmail, validNumber: !!validNumber, validLinkedin: !!validLinkedin }
  );
};

// Referrer card
export const candidateDescription = 'Please only refer those you know will be a good fit for this role, it’s important to maintain a high quality bar.';
export const referrerDescription = 'This will be used to contact you when a referal has been hired.';

export const getMetaData = (title, organization) => (
  <>
    <meta property="og:title" key="og:title" content={`${organization} is hiring`} />
    <meta property="og:url" key="og:url" content={window.location.pathname} />
  </>
);
