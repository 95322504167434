import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import { loadOrganization } from 'actions/organizations';
import { isEmpty, getOrgUuid } from 'util';
import { loadJobs } from 'actions/jobs';
import ProfileMenu from './components/ProfileMenu';
import EmployerProfileMain from './components/EmployerProfileMain';
import './EmployerProfile.scss';

function EmployerProfile() {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token, organizations } = useSelector((state) => state.user);
  const { data } = useSelector((state) => state.organizations);
  const currentOrganization = data[params.companySlug] || {};
  const isMyCompany = organizations.some((org) => org.slug === params.companySlug);
  const [CurrentCard, setCurrentCard] = useState(() => EmployerProfileMain);

  useEffect(() => {
    const isMyOrganization = getOrgUuid(params.companySlug, organizations);
    if (isEmpty(currentOrganization)) {
      dispatch(loadOrganization(params.companySlug));
    }
    if (isMyOrganization) {
      dispatch(loadJobs(isMyOrganization));
    }
  }, [params.companySlug]);

  useEffect(() => {
    switch (params.page) {
      case 'profile':
        setCurrentCard(() => EmployerProfileMain);
        break;
      case 'profile-jobs':
        navigate(`/c/${organizations[0]?.slug}/jobs`);
        break;
      default:
        setCurrentCard(() => EmployerProfileMain);
        break;
    }
  }, [params]);

  return (
    <>
      <Helmet title={`${currentOrganization?.name} | Refer`} />
      <div className="employer-profile-container">
        { isMyCompany && token && <ProfileMenu /> }
        <CurrentCard organization={currentOrganization} />
      </div>
    </>
  );
}

export default EmployerProfile;
