import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { clearForm } from 'store/reducers/createBountyForm';
import { createBounty, updateBounty } from 'actions/bounty';
import Card from 'components/Card';
import toast from 'components/Alerts';
import { editJob, loadJobs } from 'actions/jobs';
import { prettifyLocationsData, getOrgUuid } from 'util';
import { bountyConfirmationSubtitle } from './util';
import BountyConfirmation from './BountyConfirmation';

function EmployerBountyReview() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  const { jobId, companySlug } = params;
  const { organizations } = useSelector((state) => state.user);
  const { activeJobs } = useSelector((state) => state.jobs);
  const bountyForm = useSelector((state) => state.createBountyForm);
  const organizationUuid = getOrgUuid(companySlug, organizations);
  const currentJob = activeJobs.filter((job) => job.uuid === jobId)[0];
  const {
    referralAmount,
    inRole,
    customQuestion,
    isPublic,
  } = bountyForm || {};
  const canContinue = referralAmount?.toString().length > 0 && inRole;

  const onCreateBounty = async () => {
    const isExistingBounty = activeJobs.filter((job) => (job.id === jobId) && job.bounty)[0];
    const bounty = {
      note: '',
      question: customQuestion,
      referralAmount: parseInt(referralAmount),
      inRoleId: inRole.id,
    };

    // "public" prop needs to be patched at a job level, so we'll do that first (patchPublicJob())
    // On success we'll create/edit a bounty

    // If bounty exists, patch it
    if (isExistingBounty) {
      patchPublicJob().then(({ data }) => {
        if (data.data) {
          dispatch(updateBounty({ jobId, bounty }, organizationUuid))
            .then(({ data }) => navigate(`/c/${params.companySlug}/bounty-success?bountyId=${data.data.uuid}`));
        }
      });
    } else {
      // Otherwise, create it.
      patchPublicJob()
        .then(({ data }) => {
          if (data?.data) {
            dispatch(createBounty({ bounty, jobId }, organizationUuid))
              .then(({ data }) => {
                dispatch(loadJobs(organizationUuid));
                const cratedBountyId = data.data.uuid;
                navigate(`/c/${companySlug}/bounty-success?bountyId=${cratedBountyId}`);
                toast({ subtitle: 'Your bounty was succesfully created' });
                dispatch(clearForm());
              });
          }
        });
    }
  };

  // PATCH jobs requires title and locations props, so we need to send those too
  const patchPublicJob = () => dispatch(editJob({
    uuid: jobId,
    atsJobId: currentJob.ats?.job?.id,
    atsTypeId: currentJob.ats?.type?.id || 3,
    atsPostId: currentJob.ats?.post?.id,
    description: currentJob.description,
    title: currentJob.title,
    locations: prettifyLocationsData(currentJob.locations, true),
    employmentTypeId: currentJob.employment?.type?.id,
    roleId: currentJob.role?.id,
    workExperienceId: currentJob.work?.experience?.id,
    salaryMax: currentJob.salary?.max,
    salaryMin: currentJob.salary?.min,
    public: isPublic,
  }, organizationUuid)).then((res) => res);

  return (
    <>
      <Helmet title="Review and finalize | Refer" />
      <Card
        title="Review and finalize"
        subtitle={bountyConfirmationSubtitle}
        buttons={[
          {
            id: 'cancel',
            text: 'Back',
            variant: 'reverse-secondary',
            to: location.pathname.replace('/review', ''),
          },
          {
            id: 'next',
            text: 'Submit bounty',
            variant: !canContinue ? 'disabled' : 'default',
            onClick: onCreateBounty,
          },
        ]}
      >
        <BountyConfirmation data={{
          currentJob, referralAmount, inRole, customQuestion, isPublic,
        }}
        />
      </Card>
    </>
  );
}

export default EmployerBountyReview;
