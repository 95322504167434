import ListItem from 'components/ListItem';
import Icon from 'components/Icon';
import CopyText from 'components/CopyText';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { trackEvent } from 'util/tracking';

const BountiesList = ({
  jobs, isMyCompany, organization, referrals,
}) => {
  const { width } = useWindowDimensions();
  const isTablet = width < 720;

  const onClickBounty = (jobId, bountyId) => {
    if (isMyCompany) {
      return `/c/${organization.slug}/referrals/${jobId}`;
    } else {
      if (isTablet) return `/c/${organization.slug}/j/${bountyId}`;
      return `/c/${organization.slug}/bounties/${bountyId}`;
    }
  };

  const itemFooter = (bountySlug) => (
    <div className="list-item--footer flex-row">
      <Icon name="link" />
      <CopyText
        trackEvent={() => trackEvent('Copy bounty url')}
        className="copy-text"
        textDisplay={`getreferred.co/c/${organization.slug}/j/${bountySlug}`}
        content={`${process.env.REACT_APP_AUTHORIZATION_DOMAIN}c/${organization.slug}/j/${bountySlug}`}
      />
    </div>
  );

  return jobs.map((job) => {
    if (!job.bounty) return;
    const { referral, uuid, slug } = job.bounty;
    const thisBountyReferrals = referrals.filter((el) => el.job.uuid === job.uuid).length;
    const subtitle = `${referral.localized} ${isMyCompany ? `• ${thisBountyReferrals} referrals` : ''}`;

    return (
      <ListItem
        key={uuid}
        to={onClickBounty(job.id, slug)}
        variation="medium"
        greenSubtitle
        item={{
          title: job.title,
          subtitle,
          avatar: organization.links.logo?.medium,
        }}
        footer={() => itemFooter(slug)}
      />
    );
  },
  );
};

export default BountiesList;
