import Avatar from 'components/Avatar';
import Icon from 'components/Icon';

export default function ({ item, isLoading }) {
  if (isLoading) return <Icon name="table-candidate-loading-bar" />;
  const avatar = item.users?.referral?.links?.photo;
  return (
    <div className="flex-row">
      {/* {avatar && <img className="referral-avatar" src={avatar} alt="avatar" /> } */}
      <Avatar
        src={avatar}
        alt="user-avatar"
        className="pointer"
        size="medium"
        fallbackContent={item.users?.referral?.firstName?.charAt(0)}
      />
      <div className="ml-10">
        <p className="bold">{`${item.users?.referral?.firstName} ${item.users?.referral?.lastName}`}</p>
        <p className="grey-text">{item.profiles?.referral?.highlights?.job?.[0]?.title || '-'}</p>
      </div>
    </div>
  );
}
