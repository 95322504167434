import React, { useState, useRef, useEffect } from 'react';
import Portal from 'components/Portal';
import BalloonDropdown from './BalloonDropdown';
import './Balloon.scss';

const Balloon = React.forwardRef((props, btnRef) => {
  const {
    items, onClick, openDropdown, className,
  } = props;
  const wrapperRef = useRef(null);
  const [coords, setCoords] = useState({});

  useEffect(() => {
    const btnCoords = btnRef.current?.getBoundingClientRect();
    setCoords({
      left: btnCoords.x + btnCoords.width - 78,
      top: btnCoords.y + window.scrollY + 30,
    });
  }, []);

  function setEventListener(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          openDropdown(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  // Set event listener for outside click
  setEventListener(wrapperRef);

  const onClickDropdownItem = (e, item) => {
    e.stopPropagation();
    onClick(item);
    openDropdown(false);
  };

  return (
    <Portal>
      <BalloonDropdown
        className={className}
        items={items}
        coords={coords}
        onClickDropdownItem={onClickDropdownItem}
        wrapperRef={wrapperRef}
      />
    </Portal>
  );
});

export default Balloon;
