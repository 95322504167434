const columns = [
  {
    name: 'Candidate',
    key: 'candidate',
  },
  {
    name: 'Company',
    key: 'company',
  },
  {
    name: 'Referred by',
    key: 'referredBy',
  },
  {
    name: 'Date',
    key: 'dateReferred',
  },
  {
    name: 'Status',
    key: 'status',
  },
  {
    name: 'Email',
    key: 'action',
  },
];

export default columns;
