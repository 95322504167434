/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
const DropdownMenuGeo = ({ items = [], onClickitem }) => items.map(({ value, id, dataType }) => (
  <p
    id={id}
    className="dropdown-item"
    onClick={() => onClickitem({ value, id, dataType })}
  >
    {value}
  </p>
));

export default DropdownMenuGeo;
