import axios from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { clearError, setError } from 'store/reducers/alerts';

export const headers = {
  'X-Refer-API-Key': process.env.REACT_APP_API_KEY,
};

export const fetchData = ({
  endpoint,
  httpMethod = 'get',
  actions,
  body = null,
  forceAuth,
  isMedia,
}) => {
  if (!endpoint && actions) return;
  const basePath = process.env.REACT_APP_API_DOMAIN;

  return (dispatch, getState) => {
    const [request, success, failure] = actions;
    const { token } = getState().user;
    let fetchHeaders = headers;

    if (forceAuth) {
      fetchHeaders = {
        ...headers,
        'X-Refer-Force-Auth': true,
      };
    }

    const requestToken = token || localStorage.getItem('token');
    if (requestToken) {
      fetchHeaders = {
        ...fetchHeaders,
        Authorization: `Bearer:${requestToken}`,
      };
    }

    // Linkedin auth for dev environments
    if (process.env.REACT_APP_MODE === 'development') {
      fetchHeaders = {
        ...fetchHeaders,
        'X-Refer-LinkedIn-Return-URI': process.env.REACT_APP_AUTHORIZATION_DOMAIN,
      };
    }

    const postBody = isMedia ? body : decamelizeKeys(body);

    if (request) dispatch(request());
    return axiosRequest({
      fetchHeaders, basePath, endpoint, body: postBody, httpMethod,
    })
      .then((res) => {
        const camelizedData = camelizeKeys(res.data);
        if (success) dispatch(success(camelizedData));
        return res;
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 401) {
          return error;
        }
        const errorData = error.response?.data?.detail;
        if (failure) dispatch(failure({ error: errorData }));
        const errorMessage = typeof errorData === 'string' ? errorData : errorData?.message;

        // Set error in alerts reducer. This will show a toast notification.
        dispatch(setError(errorMessage || 'Something went wrong. Please contact us at support@getreferred.co'));
        // We remove error from reducer when toast dissapears.
        setTimeout(() => {
          dispatch(clearError());
        }, 5000);
        return error;
      });
  };
};

const axiosRequest = ({
  fetchHeaders, basePath, endpoint, body, httpMethod,
}) => {
  if (httpMethod === 'get') return axios.get(`${basePath}/${endpoint}`, { headers: fetchHeaders });
  if (httpMethod === 'post') return axios.post(`${basePath}/${endpoint}`, body, { headers: fetchHeaders });
  if (httpMethod === 'patch') return axios.patch(`${basePath}/${endpoint}`, body, { headers: fetchHeaders });
  return null;
};
