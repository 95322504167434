/* eslint-disable react/no-array-index-key */

import PropTypes from 'prop-types';
import classNames from 'classnames';
import './ProgressBar.scss';

export default function ProgressBar({ progressBar }) {
  const { progress, length = 3 } = progressBar;

  const bars = [...new Array(length)].map((_, i) => {
    const barStatus = (i + 1) <= progress ? 'full-bar' : '';
    return <div key={i} className={classNames('progress-bar__bar', barStatus)} />;
  });

  return (
    <div className="progress-bar">
      {bars}
    </div>
  );
}

ProgressBar.propTypes = {
  length: PropTypes.number,
  progress: PropTypes.number,
};
