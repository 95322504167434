import PropTypes from 'prop-types';
import Icon from 'components/Icon';
import './Checkbox.scss';

function Checkbox({
  isSelected, variant = 'circle', text, onClick, isGreyVariant,
}) {
  const isCircleVariant = variant === 'circle';
  const checkbox = isSelected
    ? <Icon name={isCircleVariant ? 'checkmark-circle' : isGreyVariant ? 'checkmark-grey' : 'checkmark-square'} />
    : <div className={isCircleVariant ? 'checkbox' : 'checkbox--square'} />;

  return (
    <div className="checkbox__container" onClick={onClick}>
      {checkbox}
      {text && <p>{text}</p>}
    </div>
  );
}

export default Checkbox;

Checkbox.propTypes = {
  isSelected: PropTypes.bool,
  variant: PropTypes.oneOf([
    'circle',
    'square',
  ]),
  text: PropTypes.string,
};
