function Pencil({ width = 18, height = 17, fill = '#5b5b65' }) {
  return (
    <svg width={width} height={height} viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="12.9187" y="2.0813" width="3.99984" height="14.1276" rx="0.3" transform="rotate(45 12.9187 2.0813)" fill={fill} />
      <path d="M3.9279 13.8922L5.10341 15.0677C5.18992 15.1542 5.1101 15.3207 4.97691 15.3315L2.41742 15.54C2.33485 15.5467 2.27337 15.4852 2.28009 15.4026L2.48856 12.8432C2.49941 12.71 2.66588 12.6301 2.75239 12.7167L3.9279 13.8922Z" fill={fill} />
      <rect x="15" width="3.99984" height="2.35459" rx="0.3" transform="rotate(45 15 0)" fill={fill} />
    </svg>
  );
}

export default Pencil;
