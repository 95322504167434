import { fetchData } from 'actions/fetchData';
import { generalFailure, generalRequest, generalSuccess } from 'store/reducers/jobs';
import {
  organizationRequest,
  organizationSuccess,
  organizationFailure,
  createOrganizationRequest,
  createOrganizationSuccess,
  createOrganizationFailure,
} from 'store/reducers/organizations';
import { formatWebsite } from 'util';

export const loadOrganization = (slug) => (dispatch, getState) => (
  fetchData({
    endpoint: `organization/${slug}`,
    actions: [organizationRequest, organizationSuccess, organizationFailure],
  })
)(dispatch, getState);

export const editOrganization = (uuid) => (dispatch, getState) => {
  const organization = getState().editOrganizationForm;
  return fetchData({
    endpoint: `organization/${uuid}`,
    actions: [generalRequest, generalSuccess, generalFailure],
    httpMethod: 'patch',
    body: {
      ...organization,
      fundingId: organization.funding.id,
      website: formatWebsite(organization.website),
    },
  })(dispatch, getState);
};

export const createOrganization = (body) => (dispatch, getState) => (
  fetchData({
    endpoint: 'organization',
    actions: [createOrganizationRequest, createOrganizationSuccess, createOrganizationFailure],
    httpMethod: 'post',
    body,
  })
)(dispatch, getState);

export const uploadOrganizationImages = (body, ouid) => (dispatch, getState) => (
  fetchData({
    endpoint: `organization/${ouid}/image/upload`,
    actions: [generalRequest, generalSuccess, generalFailure],
    httpMethod: 'post',
    isMedia: true,
    body,
  })
)(dispatch, getState);
