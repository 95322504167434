import { fetchData } from 'actions/fetchData';
import {
  createBountyRequest,
  createBountySuccess,
  createBountyFailure,
  createReferralRequest,
  createReferralSuccess,
  createReferralFailure,
} from 'store/reducers/bounties';
import { loadBountyRequest, loadBountySuccess, loadBountyFailure } from 'store/reducers/candidateReferralForm';

export const createBounty = ({ jobId, bounty }, orgUuid) => (dispatch, getState) => (
  fetchData({
    endpoint: `organization/${orgUuid}/job/${jobId}/bounty`,
    actions: [createBountyRequest, createBountySuccess, createBountyFailure],
    httpMethod: 'post',
    body: bounty,
  })
)(dispatch, getState);

export const updateBounty = ({ jobId, bounty }, orgUuid) => (dispatch, getState) => (
  fetchData({
    endpoint: `organization/${orgUuid}/job/${jobId}/bounty`,
    actions: [createBountyRequest, createBountySuccess, createBountyFailure],
    httpMethod: 'patch',
    body: bounty,
  })
)(dispatch, getState);

export const loadBounty = (slug) => (dispatch, getState) => (
  fetchData({
    endpoint: `bounty/${slug}`,
    actions: [loadBountyRequest, loadBountySuccess, loadBountyFailure],
  })
)(dispatch, getState);

export const createReferral = ({ slug, body }) => (dispatch, getState) => (
  fetchData({
    endpoint: `bounty/${slug}/referral`,
    actions: [createReferralRequest, createReferralSuccess, createReferralFailure],
    httpMethod: 'post',
    body,
  })
)(dispatch, getState);

export const createRequestedReferral = ({ uuid, body }) => (dispatch, getState) => (
  fetchData({
    endpoint: `referral/request/${uuid}/confirm `,
    actions: [createReferralRequest, createReferralSuccess, createReferralFailure],
    httpMethod: 'post',
    body,
  })
)(dispatch, getState);
