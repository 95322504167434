import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function PrivateRoute({ children }) {
  const { token } = useSelector((state) => state.user);

  if (!token) {
    return <Navigate to="/" />;
  }
  return children;
}

export default PrivateRoute;
