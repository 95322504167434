/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Card from 'components/Card';
import ListItem from 'components/ListItem';
import CopyText from 'components/CopyText';
import { clearNewBounty } from 'store/reducers/bounties';
import LinkedinShare from 'components/LinkedinShare';
import EmailShare from 'components/EmailShare';
import { loadUserOrganizations } from 'actions/user';
import { trackEvent } from 'util/tracking';

function EmployerBountyShare() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [job, setJob] = useState();
  const [currentOrganization, setCurrentOrganization] = useState();
  const { organization } = useSelector((state) => state.user);
  const newBounty = useSelector((state) => state.bounties.newBounty.data);
  const newBountySlug = newBounty?.slug;
  const shareLinkText = `getreferred.co/c/${params.companySlug}/j/${newBountySlug}`;
  const formattedSharedLink = `${process.env.REACT_APP_AUTHORIZATION_DOMAIN}c/${params.companySlug}/j/${newBountySlug}`;

  useEffect(() => {
    if (!newBountySlug) {
      navigate('/u/profile');
    }
    dispatch(loadUserOrganizations())
      .then(({ data }) => {
        const currentOrganization = data.data.filter((org) => org.slug === params.companySlug)[0];
        setCurrentOrganization(currentOrganization);
        if (data) {
          setJob(currentOrganization?.departments[0]?.jobs?.filter((job) => job?.bounty?.slug === newBounty?.slug)[0]);
        }
      });
    return () => dispatch(clearNewBounty());
  }, []);

  const onCreateNewBounty = () => {
    trackEvent('Employer: Create another bounty');
    navigate(`/c/${params.companySlug}/jobs`);
  };

  const onDone = () => {
    trackEvent('Employer: Done with bounty');
    navigate(`/c/${params.companySlug}`);
  };

  const copyText = () => <CopyText content={formattedSharedLink} trackEvent={() => trackEvent('Employer: Copy bounty URL')} />;

  return (
    <>
      <Helmet title="Share bounty | Refer" />
      <Card
        title="Congrats, your bounty is now active"
        subtitle="The link below is created just for you. Share it within your extended network or on LinkedIn to increase your reach."
        headerIcon="bounty-active"
        isLoading={!job}
        buttons={[
          {
            id: 'create-another',
            text: 'Create another',
            variant: 'reverse-secondary',
            onClick: onCreateNewBounty,
          },
          {
            id: 'done',
            text: 'Done',
            onClick: onDone,
          },
        ]}
      >
        <ListItem
          readOnly
          hoverEffect={false}
          item={{ text: shareLinkText }}
          highlight={false}
          showCustom={copyText}
        />
        <div className="flex-row pos-absolute right-0">
          <LinkedinShare
            bounty={newBounty}
            job={job}
            organization={currentOrganization}
            shareLinkText={formattedSharedLink}
          />
          <EmailShare bounty={newBounty} job={job} bountyLink={formattedSharedLink} organization={currentOrganization} />
        </div>
      </Card>
    </>
  );
}

export default EmployerBountyShare;
