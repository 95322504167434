import { Link } from 'react-router-dom';
import { trackEvent } from 'util/tracking';

export const bountiesCardSubtitle = ({
  referrals, uuid, name, isMyCompany, slug, organization = {},
}) => {
  const bountiesTotal = organization.departments?.[0]?.jobs?.filter((el) => el.bounty)?.length;
  if (!uuid) {
    return <p>This company has yet to create a role and a bounty. Please check back later.</p>;
  }
  return (
    <div className="flex-row justify-between">
      { isMyCompany
        ? (
          <p>
            You have
            {' '}
            <span className="bold">
              {referrals.length}
              {' '}
              referrals
            </span>
            {' '}
            across all of your roles
          </p>
        )
        : (
          <p>
            {name}
            {' '}
            is hiring for
            {' '}
            {bountiesTotal}
            {' '}
            {bountiesTotal === 1 ? 'role' : 'roles'}
          </p>
        )}
      <Link
        to={isMyCompany ? `/c/${slug}/referrals` : `/c/${slug}/bounties`}
        onClick={() => trackEvent('Employer: View dashboard')}
      >
        View all
      </Link>
    </div>
  );
};

export const companyCardSubtitle = ({
  uuid, funding, category, links,
}, description) => (
  <div className="company-card__subtitle">
    <p>{ description }</p>
    { uuid && (
      <>
        <p className="grey">{`${funding?.value} • ${category}`}</p>
        <a href={links?.website} target="_blank" rel="noreferrer noopener">{links?.website}</a>
      </>
    )}
  </div>
);
