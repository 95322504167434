function TableLoadingBar() {
  return (
    <svg width="96" height="12" viewBox="0 0 96 12" fill="none">
      <rect width="96" height="12" rx="2" fill="url(#paint0_linear_1200_2997)" />
      <defs>
        <linearGradient id="paint0_linear_1200_2997" x1="96" y1="6" x2="4.50527e-08" y2="6" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D8D8E4" />
          <stop offset="1" stopColor="#F4F4FA" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default TableLoadingBar;
