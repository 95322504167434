function KnobDown({ style }) {
  const { fill = '#1ABD00' } = style;
  return (
    <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.33329 1.66699L5.157 4.28009C5.13638 4.29707 5.11191 4.31054 5.08496 4.31974C5.05801 4.32893 5.02913 4.33366 4.99996 4.33366C4.97079 4.33366 4.9419 4.32893 4.91496 4.31974C4.88801 4.31054 4.86353 4.29707 4.84292 4.28009L1.66663 1.66699" stroke={fill} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );
}

export default KnobDown;
