import { createSlice } from '@reduxjs/toolkit';

const taxForm = createSlice({
  name: 'taxForm',
  initialState: {
    name: null,
    email: null,
    birthday: null,
    address: null,
    city: null,
    state: null,
    zip: null,
    securityNumber: null,
  },
  reducers: {
    initializeForm(state, { payload }) {
      return payload;
    },
    editForm(state, { payload }) {
      state[payload.key] = payload.value;
    },
    clearForm() {
      return {};
    },
  },
});

const { actions, reducer } = taxForm;

export const {
  initializeForm,
  editForm,
  clearForm,
} = actions;

export default reducer;
