import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { trackEvent } from 'util/tracking';
import { deactivateJob, loadJobs } from 'actions/jobs';
import Card from 'components/Card';
import List from 'components/List';
import { initializeForm } from 'store/reducers/editJobForm';
import { clearForm } from 'store/reducers/createBountyForm';
import Dropdown from 'components/Dropdown';
import { isAdmin, getOrgUuid } from 'util';
import './EmployerJobs.scss';
import ReactModal from 'react-modal';
import Alert from 'components/Alert';
import Button from 'components/Button';
import makeList from './util';

function EmployerJobs() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const [isModalOpen, openModal] = useState(false);
  const [jobToDeactivate, setJobToDeactivate] = useState();
  const [selectedJob, setJobs] = useState(null);
  const { activeJobs, isFetching } = useSelector((state) => state.jobs);
  const { organizations, email } = useSelector((state) => state.user);
  const currentOrganization = organizations.filter((org) => org.slug === params.companySlug)[0];
  const cardPrimaryButton = (selectedJob && selectedJob.bounty) ? 'Edit bounty' : 'Create bounty';

  useEffect(() => {
    dispatch(loadJobs(getOrgUuid(params.companySlug, organizations)));
  }, [params.companySlug]);

  const onSelectJob = () => {
    if (!selectedJob.uuid) {
      dispatch(initializeForm({ ...selectedJob, locations: [] }));
      navigate(`/c/${params.companySlug}/create?post-action=createBounty`);
    } else {
      dispatch(clearForm());
      navigate(`/c/${params.companySlug}/bounty-details/${selectedJob.id}`);
    }
    trackEvent('Employer: Start creating bounty');
  };

  const onCreateJob = () => {
    navigate(`/c/${params.companySlug}/create`);
    trackEvent('Employer: Create job');
  };

  const cardSubtitle = () => (
    <div className="flex-row jobs-subtitle">
      { isAdmin(email) && (
        <Dropdown
          placeholder="Organization"
          className="orgs-dropdown mr-10"
          items={organizations.map(
            (org) => ({ name: org.name, id: org.slug }))}
          onClick={({ id }) => navigate(`/c/${id}/jobs`)}
          value={currentOrganization?.name}
        />
      )}
      <Button
        size="small"
        variant="reverse-multi"
        text="Connect ATS"
        onClick={() => navigate(`/c/${currentOrganization?.slug}/greenhouse`)}
      />
    </div>

  );

  const onEditJob = (job) => {
    dispatch(initializeForm(job));
    trackEvent('Employer: Edit job');
    navigate(`/c/${params.companySlug}/jobs/edit`);
  };

  const onDeactivateJob = () => {
    dispatch(
      deactivateJob(jobToDeactivate?.uuid, getOrgUuid(params.companySlug, organizations)),
    ).then(
      () => {
        trackEvent('Employer: Delete job');
        dispatch(loadJobs(getOrgUuid(params.companySlug, organizations)));
      },
    );
  };

  return (
    <div className="employer-jobs-page">
      <Helmet title="Your jobs | Refer" />
      <Card
        title="Your jobs"
        subtitle={cardSubtitle}
        isLoading={isFetching}
        buttons={[
          {
            id: 'create-job',
            text: 'Create job',
            variant: 'reverse-multi',
            onClick: onCreateJob,
          },
          {
            id: 'create-bounty',
            text: cardPrimaryButton,
            variant: !selectedJob ? 'disabled' : 'default',
            onClick: onSelectJob,
          },
        ]}
      >
        {!activeJobs.length
          ? <p>You have no jobs. Please create a job below to get started</p>
          : (
            <List
              items={makeList(activeJobs)}
              hoverEditIconAction={onEditJob}
              hoverDeleteIconAction={(job) => {
                openModal(true);
                setJobToDeactivate(job);
              }}
              onSelectItem={setJobs}
              variation="medium"
              withLocationsSubtitles
            />
          )}
      </Card>
      <ReactModal
        ariaHideApp={false}
        isOpen={isModalOpen}
        onRequestClose={() => openModal(false)}
        shouldCloseOnOverlayClick
      >
        <Alert
          alert={{ title: 'Delete job?', subtitle: 'Are you sure you want to delete this job?' }}
          buttonPrimary="Yes, delete"
          buttonPrimaryAction={() => onDeactivateJob()}
          onClose={() => openModal(false)}
        />
      </ReactModal>
    </div>
  );
}

export default EmployerJobs;
