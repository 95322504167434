import Button from 'components/Button';
import { useState } from 'react';
import step1 from 'images/onboarding/step-1.png';
import step2 from 'images/onboarding/step-2.png';
import step3 from 'images/onboarding/step-3.png';
import './Onboarding.scss';

/* eslint-disable import/prefer-default-export */
export function Onboarding({ onSkip }) {
  const [step, setStep] = useState(1);

  const getTitle = () => {
    if (step === 1) return 'Review your referrals';
    if (step === 2) return 'Manage your pipeline';
    if (step === 3) return 'Contact candidates directly';
    return 'Review your referrals';
  };

  const getSubtitle = () => {
    if (step === 1) return 'The dashboard allows you to easily review your referrals as they come in across roles. Click on any candidate to see more details.';
    if (step === 2) return "React quickly as referrals come in — we'll push accepted candidates directly to your ATS or you can manage their status within the dashboard.";
    if (step === 3) return 'Email interested candidates directly from their profile to schedule an interview or request additional information.';
    return 'The dashboard allows you to easily review your referrals as they come in across roles. Click on any candidate to see more details.';
  };

  const getImage = () => {
    if (step === 1) return step1;
    if (step === 2) return step2;
    if (step === 3) return step3;
    return step1;
  };

  const onNextStep = () => {
    if (step === 3) {
      return onSkip();
    }
    setStep((previousStep) => previousStep + 1);
  };

  return (
    <div className="onboarding-card">
      <img alt="decorative" src={getImage()} />
      <h2 className="mt-25">{getTitle()}</h2>
      <p>{getSubtitle()}</p>
      <div className="flex-row justify-between mt-25">
        <div className="flex-row">
          <Button size="small" text={step === 3 ? 'Done' : 'Next'} onClick={onNextStep} />
          <p className="grey-text f16 pointer ml-25" onClick={onSkip}>Skip</p>
        </div>
        <p className="f16">{`${step} of 3`}</p>
      </div>
    </div>
  );
}
