import { useDispatch, useSelector } from 'react-redux';
import { editFormCandidate } from 'store/reducers/candidateReferralForm';
import Dropdown from 'components/Dropdown';
import Input from 'components/Input';
import { Helmet } from 'react-helmet-async';
import { trackEvent } from 'util/tracking';

function Step2Card() {
  const dispatch = useDispatch();
  const referralForm = useSelector((state) => state.candidateReferralForm);
  const customQuestion = referralForm.job?.bounty?.question;
  const candidateForm = referralForm.candidate;
  const { response } = candidateForm;
  const {
    ranking1, ranking2, ranking3,
  } = candidateForm.ranking || {};
  const {
    ranking1Options, ranking2Options, ranking3Options,
  } = useSelector((state) => state.main.referral.candidate);

  const onInput = (key, value, isRanking) => {
    if (!candidateForm[key]) {
      let eventName;
      if (key === 'ranking1') eventName = 'relationship';
      if (key === 'ranking2') eventName = 'comparison';
      if (key === 'ranking3') eventName = 'work with again';
      if (key === 'response') eventName = 'custom question';
      trackEvent(`Bounty: answer ${key}`);
    }
    dispatch(editFormCandidate({ key, value, isRanking }));
  };

  return (
    <>
      <Helmet prioritizeSeoTags title="Referrer: Candidate Ranking | Refer" />
      <Dropdown
        placeholder="How do you know the candidate?"
        items={ranking1Options}
        onClick={(value) => onInput('ranking1', value, true)}
        value={ranking1?.name}
      />
      <Dropdown
        placeholder="How does this candidate compare to others you’ve worked with?"
        items={ranking2Options}
        onClick={(value) => onInput('ranking2', value, true)}
        value={ranking2?.name}
      />
      <Dropdown
        placeholder="Would you work with them again?"
        items={ranking3Options}
        onClick={(value) => onInput('ranking3', value, true)}
        value={ranking3?.name}
      />
      { customQuestion && (
      <Input
        id="custom-question"
        inputType="textarea"
        placeholder={customQuestion}
        onChange={(value) => onInput('response', value)}
        value={response}
      />
      )}
    </>
  );
}

export default Step2Card;
