/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable max-len */
import { Link, useNavigate } from 'react-router-dom';
import Greenhouse1 from 'images/greenhouse/Greenhouse-1.webp';
import Greenhouse2 from 'images/greenhouse/Greenhouse-2.webp';
import Greenhouse3 from 'images/greenhouse/Greenhouse-3.webp';
import Greenhouse4 from 'images/greenhouse/Greenhouse-4.webp';
import Greenhouse5 from 'images/greenhouse/Greenhouse-5.webp';
import Greenhouse6 from 'images/greenhouse/Greenhouse-6.webp';
import Greenhouse7 from 'images/greenhouse/Greenhouse-7.webp';
import Greenhouse8 from 'images/greenhouse/Greenhouse-8.webp';
import Greenhouse9 from 'images/greenhouse/Greenhouse-9.webp';
import 'pages/EmployerSetup/EmployerSetup.scss';

function GreenhouseAtsSteps() {
  const navigate = useNavigate();
  return (
    <div className="setup-steps">
      <h3 className="mt-35">Access Settings</h3>
      <p>
        Navigate to the
        {' '}
        <span className="bold italic">API Credential Management</span>
        {' '}
        configure page (the ⚙️ icon in the upper right navigation).
      </p>
      <img className="mt-15 mb-10 setup-intructions-img" src={Greenhouse1} alt="greenhouse step 1" />
      <h3 className="mt-20">Create API Key</h3>
      <p>
        Select
        {' '}
        <span className="bold italic">Dev Center</span>
        {' '}
        from the right hand menu, and then choose
        {' '}
        <span className="bold italic">API Credentials</span>
        , and click
        {' '}
        <span className="bold italic">Create New API Key</span>
        .
      </p>
      <img className="mt-15 mb-10 setup-intructions-img" src={Greenhouse2} alt="greenhouse step 2" />
      <h3 className="mt-20">Create New Credential</h3>
      <p>In the Create New Credential dialog box, make the following selections:</p>
      <ul className="setup-ul">
        <li>
          <span className="bold">API Type:</span>
          {' '}
          Harvest
        </li>
        <li>
          <span className="bold">Partner:</span>
          {' '}
          Refer
        </li>
        <li>
          <span className="bold">Description:</span>
          {' '}
          Refer Harvest API Key
        </li>
      </ul>
      <img className="mt-15 mb-10 greenhouse-5" src={Greenhouse3} alt="greenhouse step 3" />
      <h3 className="mt-20">🚨 Copy your API Key</h3>
      <p>
        <span className="bold">An important note:</span>
        {' '}
        You cannot access the full API key again after clicking I have stored the API Key.
      </p>
      <p className="mt-20">
        Click
        {' '}
        <span className="bold italic">Copy</span>
        {' '}
        to save your API key to your clipboard. Store the API key in a secure location and paste it at the top of this page. Once you have entered the API Key on Refer, you can click
        {' '}
        <span className="bold italic">I have stored the API Key</span>
        .
      </p>
      <img className="mt-15 setup-intructions-img" src={Greenhouse4} alt="greenhouse step 4" />
      <h3 className="mt-35">Approve permissions</h3>
      <p>
        The necessary permissions for Refer will auto-populate; scroll to the bottom of the page and click
        {' '}
        <span className="bold italic">Save</span>
      </p>
      <img className="mt-15 mb-10 setup-intructions-img" src={Greenhouse5} alt="greenhouse step 5" />
      <h3 className="mt-35">Select or Generate Site Admin User ID</h3>
      <p>
        Select
        {' '}
        <span className="bold italic">Users</span>
        {' '}
        from the side panel. You can use an existing Site Admin User ID or create a dedicated one for Refer.
      </p>
      <img className="mt-15 mb-10 setup-intructions-img" src={Greenhouse6} alt="greenhouse step 6" />
      <h3 className="mt-35">Create new User</h3>
      <p>
        If creating a new user, choose
        {' '}
        <span className="bold">“New user”</span>
        {' '}
        , Set permissions to all, and hit
        {' '}
        <span className="bold italic">Save</span>
        {' '}
      </p>
      <img className="mt-15 mb-10 setup-intructions-img" src={Greenhouse7} alt="greenhouse step 7" />
      <img className="mt-15 mb-10 setup-intructions-img" src={Greenhouse8} alt="greenhouse step 8" />
      <h3 className="mt-35">Copy User ID</h3>
      <p>Whether using an existing User ID or a new one, navigate to the Users page and copy the 10-digit ID from the URL, and paste it at the top of this page.</p>
      <img className="mt-15 mb-10 setup-intructions-img" src={Greenhouse9} alt="greenhouse step 9" />
    </div>

  );
}

export default GreenhouseAtsSteps;
